import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAvailableCountries } from '@usher/pe-client-front-end-lib'
import { Image } from 'src/components/common/Image'

const getIcon = (name: string, region_name: string) => {
  const region = region_name.replace(/\s+/g, '_').toLowerCase()
  const country = name.replace(/\s+/g, '-').toLowerCase()
  return <img src={`/img/countries/${region}/${country}.svg`} alt={name} className="countries__ic"/>
}

export const CountriesSection = () => {
  const intl = useIntl()
  const { regions, countries, onRegionChange, isShowMore, onShowMore } = useAvailableCountries({ limit: 8 })
  const countriesNav = [{ id: 0, name: 'All countries' }, ...regions]

  return (
    <section className="available-countries shadows">
      <div className="container">
        <div className="row">
          <div className="col-xl-2">
            <ul className="nav nav-pills available-countries__pills" role="tablist">
              {countriesNav.map(({ id }) => (
                <li key={id} className="nav-item available-countries__nav-item">
                  <a
                    className={`nav-link available-countries__nav-link ${id === 0 ? 'active' : ''}`}
                    id={`pills-${id}-tab`}
                    data-toggle='pill'
                    href={`#pills-${id}`}
                    role='tab'
                    aria-controls={`pills-${id}`}
                    aria-selected={id === 0}
                    onClick={() => onRegionChange(id)}
                  >
                    {intl.formatMessage({ id: `count_${id}` })}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-xl-10">
            <div className="tab-content available-countries__tab-content">
              <div className="tab-pane fade show active" role="tabpanel">
                <div className={`table-responsive${isShowMore ? ' visibleTable' : ''}`}>
                  <table className="table__countries table-borderless table">
                    <tbody>
                    <tr>
                      <th><FormattedMessage id="count_7"/></th>
                      <th><FormattedMessage id="count_8"/></th>
                      <th><FormattedMessage id="count_9"/></th>
                      <th><FormattedMessage id="count_10"/></th>
                      <th><FormattedMessage id="count_11"/></th>
                    </tr>
                    {countries.map(({ code, is_enabled, name, region_name }) => (
                      <tr key={code}>
                        <td>
                          {getIcon(name, region_name)}
                          <span className="countries__name">{name}</span>
                        </td>
                        {is_enabled ? (
                          <>
                            <td><Image src={'icons-check/check.svg'} alt="Available"/></td>
                            <td><Image src={'icons-check/check.svg'} alt="Available"/></td>
                            <td><FormattedMessage id="count_12"/>, <FormattedMessage id="count_14"/></td>
                            <td><FormattedMessage id="count_13"/></td>
                          </>
                        ) : (
                          <>
                            <td><Image src={'icons-check/close.svg'} alt="Unavailable"/></td>
                            <td><Image src={'icons-check/close.svg'} alt="Unavailable"/></td>
                            <td/>
                            <td><Image src={'icons-check/close.svg'} alt="Unavailable"/></td>
                          </>
                        )}
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
                {!isShowMore && (
                  <button className="btn-reset btn-sq btn-blue available-countries__butt" type="button"
                          onClick={onShowMore}>
                    <FormattedMessage id="count_15"/>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

