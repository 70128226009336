import React from 'react'

interface AmountProps {
  value: string | number
  onChange: (value: string) => void
  className?: string
}

export const CtrlExchangeAmount = ({ value, onChange, className }: AmountProps) =>
  <input type="text" value={value} onChange={e => onChange(e.target.value)} className={className}/>
