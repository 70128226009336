import React from 'react'

export const ReviewsPage8 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Everything’s fine, transaction closed. Thanks.</p>
          <span className='reviews__row-autor'>Paul S.</span>
          <span className='reviews__row-data'>24 Jan, 2021 at 18:52 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Ireland</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good!</p>
          <span className='reviews__row-autor'>Boris G.</span>
          <span className='reviews__row-data'>26 Jan, 2021 at 14:58 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Poland</span>
          <img src='/img/countries/europe/poland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Govard B.</span>
          <span className='reviews__row-data'>26 Jan, 2021 at 18:25 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Romania</span>
          <img src='/img/countries/europe/romania.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Alice D.</span>
          <span className='reviews__row-data'>26 Jan, 2021 at 19:12 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Monaco</span>
          <img src='/img/countries/europe/monaco.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Martin F.</span>
          <span className='reviews__row-data'>26 Jan, 2021 at 20:58 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Turkey</span>
          <img src='/img/countries/europe/turkey.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>THX GUYS</p>
          <span className='reviews__row-autor'>Albert D.</span>
          <span className='reviews__row-data'>27 Jan, 2021 at 21:21 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Slovenia</span>
          <img src='/img/countries/europe/slovenia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good and reliable exchange!</p>
          <span className='reviews__row-autor'>Eric P.</span>
          <span className='reviews__row-data'>27 Jan, 2021 at 22:45 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Serbia</span>
          <img src='/img/countries/europe/serbia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
