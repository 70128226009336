import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Image } from 'src/components/common/Image'

const images = [
  'ethereum-big.svg',
  'tether-big.svg',
  'bitcoin-big.svg',
  'bitcoin_l-big.svg',
  'litecoin-big.svg',
]

export const HeaderContent = () => {
  const [imgSrc, setImgSrc] = useState(images[0])

  useEffect(() => {
    let idx = 0
    const interval = setInterval(function () {
      setImgSrc(images[idx])
      idx++
      if (idx >= images.length) idx = 0
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="header__content">
      <div className="container">
        <div className="row">
          <div className="col-xl-12 text-center">
            <div className="anim d-flex align-items-center text-left">
              <h1 className="h1 anim__title">
                <FormattedMessage id="title_home" values={{ text: <span>EX</span> }}/>
              </h1>
              <Image src="cross.svg" alt="cross" className="anim__cross"/>
              <Image src={imgSrc} alt="" className="anim__cur"/>
            </div>
            <Link to="/exchange" className="no-decorate btn-reset btn-nm btn-green btn-octagon header__butt">
              <FormattedMessage id="btn_title"/>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
