import React from 'react'
import { useHistory } from 'react-router-dom'
import { Rate, useRatesFiat } from '@usher/pe-client-front-end-lib'
import { ReviewsRatesItem } from './RatesItem'

export const ReviewsRates = () => {
  const { rates, currency, setCurrency } = useRatesFiat({ history: useHistory() })

  return (
    <div className="reviews__box reviews__box_bg-dark octagon octagon-blc">
      <h3 className="reviews__box-title">Currency Rates</h3>
      <ul className="nav nav-pills reviews-rates__curr-wrapper">
        {['USD', 'EUR'].map((item, key) =>
          <li key={key} className="nav-item">
            <button
              type="button"
              onClick={() => setCurrency(item)}
              className={`nav-link btn-reset reviews-rates__curr${currency === item ? ' active' : ''}`}>{item}
            </button>
          </li>
        )}
      </ul>
      {rates.map((rate: Rate, key: number) =>
        <ReviewsRatesItem key={key} symbol={rate.symbol} rate={rate.rate} currency={currency} change={rate.change}/>
      )}
    </div>
  )
}
