import { Component } from 'react'
import { AskPanel } from 'src/components/common/AskPanel'

export class WalletsFaqSection extends Component {
  render = () => (
    <section className='wallets-faq shadows'>
      <div className='wallets-faq__box'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h2 className='h2 wallets-faq__title'>
                Cryptocurrency
                <br /> <span>Wallets FAQ</span>
              </h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <AskPanel title={'Ok, guys, I got you, I want to have a prepaid bitcoin card. What should I do first?'} isOpened={true} i={'1'}>
                You want to get a card? It’s easy! Just log into your account and go to the ”Card” section. Follow the instructions and place an orde.
                In case you do not have a Wallbitex account, the system will ask you to register. We made the process easy because we value your time.
              </AskPanel>

              <AskPanel title={"I want to have a card but I don't think the identity verification is necessary. So what should I do?"} i={'2'}>
                To manage your order, go to apple.com/orderstatus. If your order has already been shipped, you can use your tracking link to redirect
                your shipment to a pickup point, hold it at a secure location, or fill out a signature waiver or shipment release. You can also
                generate a return label or have one emailed to you. Then contact the carrier to arrange a free, no-contact pickup. Or return your
                product to an Apple Store within 14 days of the store's reopening.
              </AskPanel>

              <AskPanel title={'I would like to know what currencies are supported.'} i={'3'}>
                Voting for the Best Answer
              </AskPanel>

              <AskPanel title={'Ok, it is clear with the currency. What about the countries?'} i={'4'}>
                Get Updates
              </AskPanel>

              <AskPanel title={'Could you please explain clearly what is the difference between a prepaid debit card and a credit card?'} i={'5'}>
                When Your Question or Answer Will be Posted
              </AskPanel>

              <AskPanel title={'I want to use your card around the world. Is it possible?\n'} i={'6'}>
                When Your Question or Answer Will be Posted
              </AskPanel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
