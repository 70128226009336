import React from 'react'

export const PurchasesSection = () => {
  return (
    <section className='purchases'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <img src='/img/purchases-cart1.png' alt='card' className='purchases__card-img' />
            <span className='purchases__card-text d-block'>Wallbitex is available in UK</span>
            <h2 className='h2 purchases__title'>
              Get A Prepaid Bitcoin Card
              <br />
              For Your Everyday
              <br />
              Purchases
            </h2>
            <p className='purchases__description'>
              You can have an absolute control of your Bitcoin expenses and incomes with
              <br />
              a reloadable cryptocurrency card offered by Wallbitex. <br />
              You can use this card everywhere.
            </p>
            <div className='purchases__button d-flex'>
              <button className='purchases__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green'>
                Notify Me
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
