import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

export type SectionItem = {
  date: string
  title: string
  intl_id?: string
  content: JSX.Element
}

export type SectionProps = {
  link: string
  title: string
  intl_id: string
  items: SectionItem[]
}

export const FaqSectionList = ({ section }: { section: SectionProps }) => {
  const intl = useIntl()

  const { link, intl_id, items } = section
  return (
    <>
      <h3 className="h3 faq-list__title">{intl.formatMessage({ id: intl_id })}</h3>
      <ul className="faq-list-list list">
        {items.map((item, key) =>
          <li key={key} className="faq-list__item">
            <Link to={`/faq/${link}/${(key + 1)}`}
                  className="faq-list__link">{intl.formatMessage({ id: item.intl_id })}</Link>
            <p className="faq-list__link-date">{item.date}</p>
          </li>
        )}
      </ul>
    </>
  )
}
