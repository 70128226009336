import { Component } from 'react'
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'

export class VacancyPage extends Component {
  render = () => (
    <BaseShadowPage>
      <section className='vacancy'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h2 vacancy__box-title text-center'>Vacancy</h2>
            </div>
          </div>
          <div className='row vacancy__row'>
            <div className='col-lg-5 col-md-4'>
              <div className='vacancy__ads-box octagon octagon-blc'>
                <ul className='vacancy__ads-list list'>
                  <li className='vacancy__ads-itme'>
                    <a href='/' className='vacancy__ads-link active'>
                      Sales Manager
                    </a>
                  </li>
                  <li className='vacancy__ads-itme'>
                    <a href='/' className='vacancy__ads-link'>
                      Content Manager / Copywriter
                    </a>
                  </li>
                  <li className='vacancy__ads-itme'>
                    <a href='/' className='vacancy__ads-link'>
                      Senior PHP Developer
                    </a>
                  </li>
                  <li className='vacancy__ads-itme'>
                    <a href='/' className='vacancy__ads-link'>
                      Android Senior Developer
                    </a>
                  </li>
                  <li className='vacancy__ads-itme'>
                    <a href='/' className='vacancy__ads-link'>
                      Senior iOS Developer
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='col-lg-7 col-md-8'>
              <div className='vacancy__descr octagon octagon-wht'>
                <h3 className='h3 vacancy__descr-title'>Sales Manager</h3>
                <p className='vacancy__descr-text'>We are looking for remote sales manager with 4+ years sales experience</p>
                <ul className='vacancy__descr-list'>
                  <li className='vacancy__descr-item'>Work exeprience: Negotiation, Market knowledge cryptocurrencies.</li>
                  <li className='vacancy__descr-item'>Fluent Engliesh</li>
                  <li className='vacancy__descr-item'>
                    Designing and implementing a strategic sales plan that expands company's customer base and ensure it's strong presence
                  </li>
                  <li className='vacancy__descr-item'>
                    Working time: 9 to 18 <span>GMT+3</span>
                  </li>
                </ul>
                <span className='vacancy__email'>
                  To apply, please send your application to
                  <a href='mailto:hr@wallbitex.com' className='vacancy__email-link'>
                    hr@wallbitex.com
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </BaseShadowPage>
  )
}
