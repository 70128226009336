import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Image } from 'src/components/common/Image'

export const ConfidenceSection = () => {
  return (
    <section className='confidence shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 confidence__title text-center'><FormattedMessage id="buy_with_confidence"/></h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='confidence__box text-center'>
              <div className='confidence__box-icon'>
                <Image src="security-icon.svg" className="confidence__image img-fluid"/>
              </div>
              <h3 className='h3 confidence__box-title'><FormattedMessage id="security_policy"/></h3>
              <p className='confidence__box-desr'><FormattedMessage id="data_protected"/></p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='confidence__box text-center'>
              <div className='confidence__box-icon'>
                <Image src="24_support-icon.svg" className="confidence__image img-fluid"/>
              </div>
              <h3 className='h3 confidence__box-title'><FormattedMessage id="24_hour_client_service"/></h3>
              <p className='confidence__box-desr'><FormattedMessage id="our_team_never_sleeps"/></p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='confidence__box text-center'>
              <div className='confidence__box-icon'>
                <Image src="no_commission-icon.svg" className="confidence__image img-fluid"/>
              </div>
              <h3 className='h3 confidence__box-title'><FormattedMessage id="zero_commission"/></h3>
              <p className='confidence__box-desr'><FormattedMessage id="enjoy_working_with_us"/></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
