import React from 'react'
import { useIntl } from 'react-intl'
import ReCAPTCHA from 'react-google-recaptcha'
import { CtrlInput } from 'src/components/ctrl/Input'

type Props = {
  onChange: (val: string) => void
  refObj?: React.MutableRefObject<any>
  required?: boolean
  errClassName?: string
}

export const CtrlCaptcha = ({ onChange, refObj, required = true, errClassName = null }: Props) => {
  const intl = useIntl()
  const hl = intl.locale.substring(0, 2)
  const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  const refProps = { required: intl.formatMessage({ id: 'pa_25' }) }

  return (
    <>
      <ReCAPTCHA ref={refObj} onChange={onChange} hl={hl} sitekey={siteKey}/>
      {required && <CtrlInput type="hidden" name="captcha" refProps={refProps} errClassName={errClassName}/>}
    </>
  )
}
