import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

export class LegalSection extends Component {
  render = () => (
    <section className='legal-security shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 legal-security__title text-center'><FormattedMessage id="at_31"/></h2>
            <p className='legal-security__description text-center'><FormattedMessage id="at_32"/></p>
            <p className='legal-security__description text-center'>
              <FormattedMessage
                id="at_33"
                values={{ text: <Link to="/faq" className="legal-security__description-text">our security page.</Link> }}
              />
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4'>
            <div className='legal-security__box text-center'>
              <div className='legal-security__box-icon'>
                <img src='/img/legal-security-icon1.svg' alt='icon' className='legal-security__image img-fluid'/>
              </div>
              <h3 className='h3 legal-security__box-title'><FormattedMessage
                id="at_34"
              /></h3>
              <p className='legal-security__box-desr'><FormattedMessage
                id="at_35"
              /></p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='legal-security__box text-center'>
              <div className='legal-security__box-icon'>
                <img src='/img/legal-security-icon2.svg' alt='icon' className='legal-security__image img-fluid'/>
              </div>
              <h3 className='h3 legal-security__box-title'><FormattedMessage
                id="at_36"
              /></h3>
              <p className='legal-security__box-desr'>
                <FormattedMessage
                  id="at_37"
                />
              </p>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='legal-security__box text-center'>
              <div className='legal-security__box-icon'>
                <img src='/img/legal-security-icon3.svg' alt='icon' className='legal-security__image img-fluid'/>
              </div>
              <h3 className='h3 legal-security__box-title'><FormattedMessage
                id="at_38"
              /></h3>
              <p className='legal-security__box-desr'>
                <FormattedMessage
                  id="at_39"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
