import React from "react"
import { CtrlSelect } from "src/components/ctrl/Select"

interface CurrencyProps {
  value: string
  options: string[]
  className?: string
  onChange: (value: string) => void
}

export const CtrlExchangeCurrency = ({
  value,
  options,
  className,
  onChange,
}: CurrencyProps) => (
  <CtrlSelect
    value={value}
    options={options.reduce<{ value: string; label: string }[]>((acc, curr) => {
      if (curr === "USDT") acc.push({ value: curr, label: "USDT-ERC20" })
      else if (curr !== "BCH") acc.push({ value: curr, label: curr })
      return acc
    }, [])}
    className={className}
    onChange={(e) => onChange(e.target.value)}
  />
)
