import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Controller, useFormContext } from 'react-hook-form'
import { ApiData, getValue4Select } from '@usher/pe-client-front-end-lib'
import { ReactSelectStyles } from 'src/constants/ReactSelectStyles'

interface SelectCountryProps {
  name?: string
  selected?: string
  required?: boolean
  className?: string
  rules?: {}
  customStyles?: {}
}

export const CtrlSelectCountry = ({
  name = 'country_code',
  selected,
  required = false,
  className = '',
  rules,
  customStyles,
  ...rest
}: SelectCountryProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const [options, setOptions] = useState([])
  const { control, errors } = useFormContext()

  useEffect(() => {
    const fetchOptions = async () => {
      const options = (await ApiData.getSelectorList('country'))
        .map(item => ({ value: item.value, label: item.key }))
      setOptions(options)
      setIsLoading(false)
    }
    fetchOptions().then()
  }, [])

  if (required) rules = { ...rules, required: 'This field is required.' }
  if (errors[name]) className += ' is-invalid'

  return (
    <>
      <Controller
        control={control}
        name={name}
        rules={rules}
        render={(
          { onChange, value, name },
          { invalid, isTouched, isDirty }
        ) => (
          <Select
            name={name}
            isLoading={isLoading}
            className={className}
            styles={{...ReactSelectStyles, ...customStyles}}
            options={options}
            value={getValue4Select(options, value)}
            onChange={({ value }) => onChange(value)}
            {...rest}
          />
        )}
      />
      {errors[name] && <div className="invalid-feedback">{errors[name].message}</div>}
    </>
  )
}
