import React, { Component } from 'react'
import { NavigationLinks } from 'src/components/common/NavigationLinks'

export class BaseShadowPage extends Component<{ children?: any }> {
  render = () => (
    <div className='page-main shadows'>
      <header className='header header-secondary'>
        <NavigationLinks/>
      </header>
      {this.props.children}
    </div>
  )
}
