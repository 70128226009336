import React from 'react'
import { useIntl } from 'react-intl'
import { components, useStoreExchange, selectFetchPrices } from '@usher/pe-client-front-end-lib'
import { HowCalculated } from 'src/components/common/HowCalculated'

export const ExchangeTimer = () => {
  const intl = useIntl()
  const fetchPrices = useStoreExchange(selectFetchPrices)

  return (
    <p className="exchange-form__time text-center">
      <components.Timer onUpdate={fetchPrices} wrapWithTag="" text={intl.formatMessage({ id: 'timer_calc' })}/>
      . <HowCalculated/>
    </p>
  )
}
