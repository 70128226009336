import React from 'react'
import { useIntl } from 'react-intl'
import shallow from 'zustand/shallow'
import { useFormContext } from 'react-hook-form'
import { EXCHANGE_TYPE_FIAT, ExchangeSelector, useStoreExchange } from '@usher/pe-client-front-end-lib'
import { CtrlExchangeAmount } from 'src/components/ctrl/ExchangeAmount'
import { CtrlExchangeCurrency } from 'src/components/ctrl/ExchangeCurrency'
import { ExchangeStepItem } from 'src/components/exchange/StepItem'

export const ExchangeStep1ExchangeRow = ({ type, selector }: { type: 'fiat' | 'crypto', selector: ExchangeSelector }) => {
  const intl = useIntl()
  const { errors } = useFormContext()
  const amountErr = errors[`${type}Amount`]
  const { amount, currency, setAmount, setCurrency, currencies } = useStoreExchange(selector, shallow)

  const label = (type === EXCHANGE_TYPE_FIAT)
    ? intl.formatMessage({ id: 'exchange.amount2spend' })
    : intl.formatMessage({ id: 'exchange.coins2receive' })

  return (
    <>
      <div className={`row flex-nowrap${amountErr ? ' is-invalid' : ''}`}>
        <div className="col-lg-6">
          <ExchangeStepItem label={label}>
            <CtrlExchangeAmount value={amount} onChange={setAmount} className="input"/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <ExchangeStepItem label="&nbsp;" className="w-100">
            <CtrlExchangeCurrency value={currency} onChange={setCurrency} options={currencies} className="input"/>
          </ExchangeStepItem>
        </div>
      </div>
      {amountErr && <div className="invalid-feedback">{amountErr.message}</div>}
    </>
  )
}
