import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { useExchangeFormMini } from '@usher/pe-client-front-end-lib'
import { ExchangeFormItem } from './ExchangeFormItem'

export const ExchangeForm = () => {
  const history = useHistory()
  const [selectFiat, selectCrypto] = useExchangeFormMini()

  return (
    <div className='row'>
      <div className='col-xl-4 col-sm-6 w-sm-50'>
        <div className='exchange-form__item'>
          <label className='exchange-form__des'>
            <FormattedMessage id="credit_label.amount"/> <span><FormattedMessage id="credit_label.2spend"/></span>
          </label>
          <ExchangeFormItem selector={selectFiat}/>
        </div>
      </div>
      <div className='col-xl-5 col-sm-6 w-sm-50'>
        <div className='exchange-form__item'>
          <label className='exchange-form__des'>
            <FormattedMessage id="credit_label.coin"/> <span><FormattedMessage id="credit_label.2receive"/></span>
          </label>
          <ExchangeFormItem selector={selectCrypto}/>
        </div>
      </div>
      <div className='col-xl-3 col-lg-12 text-xl-right text-center'>
        <button type="button" onClick={() => history.push('exchange')}
                className='btn-reset btn-nm btn-green btn-octagon exchange-form__butt'>
          <FormattedMessage id="credit_btn"/>
        </button>
      </div>
    </div>
  )
}
