import React from "react"
import { useIntl } from "react-intl"
import { useHistory } from "react-router-dom"
import { Rate, useRatesFiat } from "@usher/pe-client-front-end-lib"
import { SearchIcon } from "src/constants/SvgConstants"
import { RateItem } from "./Item"

export const Rates = () => {
  const intl = useIntl()
  const { rates, currency, setCurrency, onBuyNow } = useRatesFiat({
    history: useHistory(),
  })
  return (
    <div className="rates">
      <div className="rates__top d-flex">
        <div className="rates__buttons">
          {["USD", "EUR"].map((item, key) => (
            <button
              key={key}
              type="button"
              onClick={() => setCurrency(item)}
              className={`rates__button${currency === item ? " active" : ""}`}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="search__wrapper">
          <input
            type="search"
            className="rates__input"
            placeholder={intl.formatMessage({ id: "search_title" })}
          />
          <SearchIcon />
        </div>
      </div>
      <ul className="rates__list list">
        {rates
          .reduce<Rate[]>((acc, rate) => {
            if (rate.symbol === "USDT")
              acc.push({ ...rate, symbol: "USDT-ERC20" })
            else if (rate.symbol !== "BCH") acc.push(rate)
            console.log(acc)
            return acc
          }, [])
          .map((rate: Rate, key: number) => (
            <RateItem
              key={key}
              symbol={rate.symbol}
              rate={rate.rate}
              currency={currency}
              change={rate.change}
              onBuyNow={onBuyNow}
            />
          ))}
      </ul>
    </div>
  )
}
