import React from 'react'
import { FormattedMessage } from "react-intl"

export const Copyright = () => {
  return <><FormattedMessage
  id="footer_copy1"
/> &copy; {new Date().getFullYear()} Wallbitex. <span>
  <FormattedMessage
    id="footer_copy2"
  /></span></>
}
