import English from 'src/languages/en.json'
import French from 'src/languages/fr.json'
import Italian from 'src/languages/it.json'
import Spanish from 'src/languages/es.json'
import Portuguese from 'src/languages/pt.json'

export const LANGUAGES = [
  { code: 'en-US', label: 'English', messages: English },
  { code: 'fr-FR', label: 'French', messages: French },
  { code: 'it-IT', label: 'Italian', messages: Italian },
  { code: 'es-ES', label: 'Spanish', messages: Spanish },
  { code: 'pt-PT', label: 'Portuguese', messages: Portuguese },
]
