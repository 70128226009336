import React from 'react'
import { FormattedMessage } from "react-intl"

export const RatesTrading = () => {
  return (
    <section className="quick-trading">
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h3 className='h3 quick-trading__title text-center'><FormattedMessage
                id="quick_trading_title"
              /></h3>
            <p className='quick-trading__description text-center'><FormattedMessage
                id="quick_trading_slogan"
              /></p>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-3 col-lg-6'>
            <div className='quick-trading__box'>
              <h4 className='h4 quick-trading__cart-title'><FormattedMessage
                id="quick_trading_cart_title_1"
              /></h4>
              <p className='quick-trading__cart-description'><FormattedMessage
                id="quick_trading_cart_description_1"
              /></p>
            </div>
          </div>
          <div className='col-xl-3 col-lg-6'>
            <div className='quick-trading__box'>
              <h4 className='h4 quick-trading__cart-title'><FormattedMessage
                id="quick_trading_cart_title_2"
              /></h4>
              <p className='quick-trading__cart-description'>
              <FormattedMessage
                id="quick_trading_cart_description_2"
              />
              </p>
            </div>
          </div>
          <div className='col-xl-3 col-lg-6'>
            <div className='quick-trading__box'>
              <h4 className='h4 quick-trading__cart-title'><FormattedMessage
                id="quick_trading_cart_title_3"
              /></h4>
              <p className='quick-trading__cart-description'><FormattedMessage
                id="quick_trading_cart_description_3"
              /></p>
            </div>
          </div>
          <div className='col-xl-3 col-lg-6'>
            <div className='quick-trading__box'>
              <h4 className='h4 quick-trading__cart-title'><FormattedMessage
                id="quick_trading_cart_title_4"
              /></h4>
              <p className='quick-trading__cart-description'>
              <FormattedMessage
                id="quick_trading_cart_description_4"
              />
              </p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='quick-trading-button d-flex'>
              <a href='sign_up' className='quick-trading-btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green'>
              <FormattedMessage
                id="quick_btn"
              />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
