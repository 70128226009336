import React from 'react'
import { useHistory } from 'react-router-dom'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { useExchangeStep4 } from '@usher/pe-client-front-end-lib'

export const ExchangeStep4 = () => {
  const { token, form, formEl, iframeSrc } = useExchangeStep4({ history: useHistory() })

  return (
    <>
      {!token && (
        <>
          <ExchangeBodyCaption title="Loading"/>
          <p className="buy-steps__caption-des">Please wait..</p>
        </>
      )}
      {token && (
        <div className="iframe__form">
          <iframe title="payment" width="100%" height="700px" frameBorder="0" scrolling="NO" src={iframeSrc}/>
        </div>
      )}
      <form action={form.url} method="POST" ref={formEl}>
        {form.params && form.params.map((d: any) => <input type="hidden" name={d.key} value={d.value}/>)}
      </form>
    </>
  )
}
