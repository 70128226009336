import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

export const FaqMainTop = () => {
  const intl = useIntl()

  return (
    <div className="row">
      <div className="col-md-8">
        <div className="faq__buttons">
          <Link to="/contacts" className="faq__btn no-decorate btn-reset btn-sq btn-light-blue">
            <FormattedMessage id="faq_btn_1"/>
          </Link>
          <Link to="/contacts" className="faq__btn no-decorate btn-reset btn-sq btn-green">
            <FormattedMessage id="faq_btn_2"/>
          </Link>
        </div>
      </div>
      <div className="col-md-4 d-flex">
        <div className="fg__search-wrap">
          <input type="search" className="faq__search input input-brdr"
                 placeholder={intl.formatMessage({ id: 'search_title' })}/>
        </div>
      </div>
    </div>
  )
}
