import { Component } from 'react'
import { CryptoPlatformSection } from 'src/components/affiliate/CryptoPlatformSection'
import { ReferalSection } from 'src/components/affiliate/ReferalSection'
import { QuestionsSection } from 'src/components/affiliate/QuestionsSection'
import { AffiliateForm } from 'src/components/affiliate/AffiliateForm'
import { BaseShadowOutPage } from 'src/pages/base/BaseShadowOutPage'
import { AffiliateHeaderBlock } from 'src/components/affiliate/AffiliateHeaderBlock'

export class AffiliatePage extends Component {
  render = () => (
    <BaseShadowOutPage headerChild={<AffiliateHeaderBlock />}>
      <CryptoPlatformSection />
      <ReferalSection />
      <QuestionsSection />
      <AffiliateForm />
    </BaseShadowOutPage>
  )
}
