import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { LoginModel } from '@usher/pe-client-front-end-lib'
import { VerifiedBlock } from 'src/components/login/VerifiedBlock'
import { LoginForm } from 'src/components/login/LoginForm'

export class LoginContent extends LoginModel {
  render = () => (
    <section className='user-form user-login'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='user-form__header user-form__header-login'>
              <div className='row align-items-baseline'>
                <div className='col-md-6'>
                  <h2 className='h2 user-form__header-title'><FormattedMessage id="lf_6"/></h2>
                </div>
                <div className='col-md-6 text-right'>
                  <span className='user-form__info-title'>
                   <FormattedMessage id="lf_3"/>
                    <Link to="/sign_up" className='user-form__info-link link-green'>
                      <FormattedMessage id="lf_4"/>
                    </Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <VerifiedBlock visible={this.state.showEmailVerifyInfo} text='Your Email has been verified!'/>
        <VerifiedBlock visible={this.state.showIPVerifyInfo} text='Your IP has been verified!'/>

        <div className='row'>
          <div className='col-lg-12'>
            <LoginForm/>
          </div>
        </div>
      </div>
    </section>
  )
}
