import React from 'react'
import { FormattedMessage } from "react-intl";
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { ReviewsInfo } from 'src/components/reviews/Info'
import { ReviewsPager } from 'src/components/reviews/Pager'
import { ReviewsRates } from 'src/components/reviews/Rates'
import { Reviews3rdParty } from 'src/components/reviews/3rdParty'
import { ReviewsContent } from 'src/components/reviews'

export const ReviewsPage = () => {
  return (
    <BaseShadowPage>
      <section className="reviews">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h2 className="h2 reviews__title text-center">  <FormattedMessage
                      id="review_page_title"
                    /></h2>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-7">
              <div className="reviews__main">
                <ReviewsInfo/>
                <ReviewsContent/>
                <ReviewsPager/>
              </div>
            </div>
            <div className="col-xl-5">
              <Reviews3rdParty/>
              <ReviewsRates/>
            </div>
          </div>
        </div>
      </section>
    </BaseShadowPage>
  )
}
