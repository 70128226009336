import React, { Component } from 'react'
import { BasePage } from 'src/pages/base/BasePage'
import { ConfidenceSection } from 'src/components/common/ConfidenceSection'
import { LoginContent } from 'src/components/login/LoginContent'
import { toast } from 'react-toastify'

export class LoginPage extends Component {
  onInfo = (msg: string) => {
    toast.info(msg)
  }

  render = () => (
    <BasePage headerType={'login'} headerChild={<LoginContent onInfo={this.onInfo} />}>
      <main>
        <ConfidenceSection />
      </main>
    </BasePage>
  )
}
