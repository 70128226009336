import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class BenefitsSection extends Component {
  render = () => (
    <section className='benefits shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 benefits__title text-center'><FormattedMessage
                id="at_18"
              /></h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 text-center'>
            <div className='benefits__card-wrap'>
              <div className='benefits__icon-wrap'>
                <img src='/img/benefits-icon1.svg' alt='icon' className='benefits__card-icon' />
              </div>
              <h4 className='h4 benefits__card-title'><FormattedMessage
                id="at_19"
              /></h4>
              <p className='benefits__card-description'>
              <FormattedMessage
                id="at_20"
              />
              </p>
            </div>
          </div>
          <div className='col-lg-6 text-center'>
            <div className='benefits__card-wrap'>
              <div className='benefits__icon-wrap'>
                <img src='/img/benefits-icon2.svg' alt='icon' className='benefits__card-icon' />
              </div>
              <h4 className='h4 benefits__card-title'><FormattedMessage
                id="at_21"
              /></h4>
              <p className='benefits__card-description'>
              <FormattedMessage
                id="at_22"
              />
              </p>
            </div>
          </div>
          <div className='col-lg-4 text-center'>
            <div className='benefits__card-wrap'>
              <div className='benefits__icon-wrap'>
                <img src='/img/benefits-icon3.svg' alt='icon' className='benefits__card-icon' />
              </div>
              <h4 className='h4 benefits__card-title'><FormattedMessage
                id="at_23"
              /></h4>
              <p className='benefits__card-description'><FormattedMessage
                id="at_24"
              /></p>
            </div>
          </div>
          <div className='col-lg-4 text-center'>
            <div className='benefits__card-wrap'>
              <div className='benefits__icon-wrap'>
                <img src='/img/benefits-icon4.svg' alt='icon' className='benefits__card-icon' />
              </div>
              <h4 className='h4 benefits__card-title'><FormattedMessage
                id="at_25"
              /></h4>
              <p className='benefits__card-description'><FormattedMessage
                id="at_26"
              /></p>
            </div>
          </div>
          <div className='col-lg-4 text-center'>
            <div className='benefits__card-wrap'>
              <div className='benefits__icon-wrap'>
                <img src='/img/benefits-icon6.svg' alt='icon' className='benefits__card-icon' />
              </div>
              <h4 className='h4 benefits__card-title'><FormattedMessage
                id="at_29"
              /></h4>
              <p className='benefits__card-description'><FormattedMessage
                id="at_30"
              /></p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='benefits__button d-flex'>
              <a href='sign_up' className='benefits__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green'>
              <FormattedMessage
                id="quick_btn"
              />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
