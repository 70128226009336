import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { ExchangeStepItem } from 'src/components/exchange/StepItem'
import { CtrlInput } from 'src/components/ctrl/Input'
import { CtrlSelectCountry } from 'src/components/ctrl/SelectCountry'

export const ExchangeStep2BillingAddress = () => {
  const intl = useIntl()

  return (
    <div className="buy-steps__billing">
      <ExchangeBodyCaption title={intl.formatMessage({ id: 'pa_58' })}/>
      <p className="buy-steps__sub-caption"><FormattedMessage id="pa_59"/></p>
      <div className="row">
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_60' })} className="w-100">
            <CtrlSelectCountry required/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_61' })}>
            <CtrlInput type="text" name="state" className="input" required/>
          </ExchangeStepItem>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_62' })}>
            <CtrlInput type="text" name="city" className="input" required/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_63' })}>
            <CtrlInput type="text" name="zip" className="input" required/>
          </ExchangeStepItem>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_64' })}>
            <CtrlInput type="text" name="address" className="input" required/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_65' })}>
            <CtrlInput type="text" name="address2" className="input" required/>
          </ExchangeStepItem>
        </div>
      </div>
    </div>
  )
}
