import React from 'react'

export const ReviewsPage3 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Anna S.</span>
          <span className='reviews__row-data'>10 Jan, 2021 at 07:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Barbados</span>
          <img src='/img/countries/north_america/barbados.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Более чем приятный сервис. Одобряю.</p>
          <span className='reviews__row-autor'>Brenda F.</span>
          <span className='reviews__row-data'>10 Jan, 2021 at 09:22 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Haiti</span>
          <img src='/img/countries/north_america/haiti.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>TOP-1 in the list of services I use Love it.</p>
          <span className='reviews__row-autor'>Linda K.</span>
          <span className='reviews__row-data'>11 Jan, 2021 at 15:54 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Puerto-Rico</span>
          <img src='/img/countries/north_america/puerto-rico.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            The best exchange platform, I think, Fast, easy to work with, reliable and secure. What else? Support is
            fine too.
          </p>
          <span className='reviews__row-autor'>Pit H.</span>
          <span className='reviews__row-data'>11 Jan, 2021 at 16:54 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Switzerland</span>
          <img src='/img/countries/europe/switzerland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Recommend. Good rates, proficient support, everything is clear and
            transparent.</p>
          <span className='reviews__row-autor'>Marc F.</span>
          <span className='reviews__row-data'>11 Jan, 2021 at 20:22 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Germany</span>
          <img src='/img/countries/europe/germany.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Просто топчик. Все четенько сделали.</p>
          <span className='reviews__row-autor'>Victor F.</span>
          <span className='reviews__row-data'>11 Jan, 2021 at 12:11 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Belarus</span>
          <img src='/img/countries/europe/Belarus.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good job, fast and reliable!!</p>
          <span className='reviews__row-autor'>Beatris D.</span>
          <span className='reviews__row-data'>12 Jan, 2021 at 20:54 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Denmark</span>
          <img src='/img/countries/europe/denmark.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
