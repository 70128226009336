import { OptionTypeBase, StylesConfig } from 'react-select'

type IsMulti = false

export const SignupFormSelectStyles: StylesConfig<OptionTypeBase, IsMulti> = {
  control: (base, state) => ({
    ...base,
    boxShadow: null,
    borderColor: '#c3ced7',
    borderRadius: 0,
    '&:hover': {
      borderColor: '#000'
    },
    '&:active': {
      backgroundColor: '#ffffff'
    },
    minHeight: 40
  }),
  dropdownIndicator: base => ({
    ...base,
    color: '#777e8a',
    '&:hover': {
      color: '#4ea84d'
    },
    padding: '6px 8px'
  }),
  clearIndicator: base => ({
    ...base,
    padding: '6px 8px'
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuList: base => ({
    ...base,
    color: '#000',
    fontSize: 14,
    fontFamily: 'ChakraPetchSemiBold',
  }),
  singleValue: base => ({
    ...base,
    color: '#000',
    fontSize: 14,
    fontFamily: 'ChakraPetchSemiBold',
    padding: '0 11px',
  }),
  input: base => ({
    ...base,
    padding: '0 10px',
  }),
  option: base => ({
    ...base,
    padding: '5px 20px',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  placeholder: base => ({
    ...base,
    padding: '0 10px',
    fontSize: 14,
  }),
}
