import { OptionTypeBase, StylesConfig } from 'react-select'

type IsMulti = false

export const AffiliateFormSelectStyles: StylesConfig<OptionTypeBase, IsMulti> = {
  control: (base, state) => ({
    ...base,
    boxShadow: null,
    borderColor: '#78ff73',
    borderRadius: 12,
    borderWidth: 2,
    backgroundColor: 'transparent',
    '&:focus': {
      borderColor: '#eeeeee',
      backgroundColor: '#ffffff',
    },
    '&:hover': {
      borderColor: '#78ff73',
      backgroundColor: 'transparent',
    },
    '&:active': {
      borderColor: '#000',
      backgroundColor: '#ffffff',
    },
    minHeight: 50,
    marginBottom: 30,
  }),
  menu: base => ({
    ...base,
    marginTop: 0,
    borderRadius: 0,
    zIndex: 2,
  }),
  menuList: base => ({
    ...base,
    color: '#000',
    fontSize: 14,
    fontFamily: 'ChakraPetchSemiBold',
  }),
  singleValue: base => ({
    ...base,
    color: '#fff',
    fontSize: 22,
    transform: 'translate(-50%,-50%)',
    left: '50%',
    fontFamily: 'ChakraPetchRegular',
    padding: '0 11px',
  }),
  input: base => ({
    ...base,
    padding: '0',
    color: '#fff',
    fontSize: 22,
  }),
  option: base => ({
    ...base,
    padding: '5px 20px',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  placeholder: base => ({
    ...base,
    padding: '0 10px',
    fontSize: 14,
  }),
  valueContainer: base => ({
    ...base,
    padding: '0 10px',
    fontSize: 14,
  }),
}
