import { Component } from 'react'
import { NavigationLinks } from 'src/components/common/NavigationLinks'

interface IHeaderProps {
  classType: string
  children?: any
}

export class Header extends Component<IHeaderProps, {}> {
  render = () => (
    <div className={'page-main page-main__user-' + this.props.classType + ' shadows'}>
      <header className='header header-secondary'>
        <NavigationLinks />
      </header>

      {this.props.children}
    </div>
  )
}
