import React, { FunctionComponent } from 'react'
import { BaseShadowOutPage } from 'src/pages/base/BaseShadowOutPage'
import { AboutSection } from 'src/components/about/AboutSection'
import { TodaySection } from 'src/components/about/TodaySection'
import { AssetsSection } from 'src/components/about/AssetsSection'
// import { StatisticsSection } from 'src/components/about/StatisticsSection'
import { BenefitsSection } from 'src/components/about/BenefitsSection'
import { LegalSection } from 'src/components/about/LegalSection'

export const AboutPage: FunctionComponent = () => (
  <BaseShadowOutPage headerChild={<AboutSection />}>
    <TodaySection />
    <AssetsSection />
    {/*<StatisticsSection />*/}
    <BenefitsSection />
    <LegalSection />
  </BaseShadowOutPage>
)
