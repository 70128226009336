import React from "react"
import shallow from "zustand/shallow"
import {
  ExchangeSelector,
  useStoreExchange,
} from "@usher/pe-client-front-end-lib"
import { CtrlExchangeAmount } from "src/components/ctrl/ExchangeAmount"
import { CtrlExchangeCurrency } from "src/components/ctrl/ExchangeCurrency"

export const ExchangeFormItem = ({
  selector,
}: {
  selector: ExchangeSelector
}) => {
  const { amount, currency, setAmount, setCurrency, currencies } =
    useStoreExchange(selector, shallow)
  return (
    <div className="exchange-form__value">
      <CtrlExchangeAmount
        value={amount}
        onChange={setAmount}
        className="exchange-form__input"
      />
      <CtrlExchangeCurrency
        value={currency}
        onChange={setCurrency}
        options={currencies}
        className="exchange-form__select"
      />
    </div>
  )
}
