import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class RiskContent extends Component {
  render = () => (
    <article className='legal-article mCustomScrollbar' data-mcs-theme='light'>
      <h3 className='h3 faq-list__title faq-article'>
      <FormattedMessage
        id="risk_page_title"
        />
      </h3>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_1"
        />
      </p>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_2"
        />
      </p>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_3"
        />
      </p>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_4"
        />
      </p>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_5"
        />
      </p>

      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="rd_6"
        />
      </p>
    </article>
  )
}
