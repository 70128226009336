import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { SectionItem } from './SectionList'
import { FaqDislike, FaqLike } from 'src/constants/SvgConstants'

export const FaqSectionItem = ({ item }: { item: SectionItem }) => {
  const intl = useIntl()

  const { date, intl_id, content } = item
  return (
    <>
      <h3 className="h3 faq-list__title faq-article">{intl.formatMessage({ id: intl_id })}</h3>
      {content}
      <span className="faq-arctivle-date">{date}</span>
      <div className="faq-arcticle__bottom">
        <span className="faq-arcticle__bottom-link"><FormattedMessage id="faq_arcticle_bottom_link"/></span>
        <div className="faq-arcticle__bottom-actions">
          <span className="no-decorate faq-arcticle__bottom-link faq-arcticle__bottom-like"><FaqLike/><FormattedMessage
            id="yes"/></span>
          <span
            className="no-decorate faq-arcticle__bottom-link faq-arcticle__bottom-dislike"><FaqDislike/><FormattedMessage
            id="no"
          /></span>
        </div>
      </div>
    </>
  )
}
