import React from 'react'
import { FormattedMessage } from "react-intl";
import { Image } from 'src/components/common/Image'

const ROWS = [
  { img: 'userpic.svg', name: 'Marc Adamus' },
  { img: 'userpic-1.svg', name: 'Martin Podt' },
  { img: 'userpic-2.svg', name: 'Jeremy Peresse' },
]

export const Reviews3rdParty = () => {
  return (
    <div className="reviews__box reviews__box_bg-dark reviews-patry octagon octagon-blc">
      <h3 className="reviews__box-title"><FormattedMessage
      id="rp_1"
      />
      </h3>
      {ROWS.map((item, key) => (
        <div className="reviews-patry__row" key={key}>
          <Image src={item.img} alt={item.name} className="reviews-patry__ic"/>
          <span className="reviews-patry__name">{item.name}</span>
        </div>
      ))}
    </div>
  )
}
