import React from 'react'
import { FormProvider } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useContactFormV2, VALID_NAME, VALID_PHONE } from '@usher/pe-client-front-end-lib'
import { CtrlCaptcha } from 'src/components/ctrl/Captcha'
import { CtrlInputWrap } from 'src/components/ctrl/InputWrap'
import { CtrlTextAreaWrap } from 'src/components/ctrl/TextAreaWrap'

export const ContactsForm = () => {
  const { formMethods, onSubmit, captchaRef, onCaptchaChange } = useContactFormV2()

  return (
    <section className='user-form contact-us'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='user-form__header contact-us__header'>
              <div className='row'>
                <div className='col-lg-12 text-center'>
                  <h2 className='h2 user-form__header-title'>
                    <FormattedMessage id="contact_page_title"/>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <div className='user-form__form contact-us__form octagon octagon-wht'>
              <div className='row'>
                <div className='col-lg-12'>
                  <h3 className='h3 contact-us__form-title text-center'>
                    <FormattedMessage id="cf_title"/>
                  </h3>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <CtrlInputWrap name="name" label_id="cf_1" required refProps={VALID_NAME}/>
                </div>
                <div className='col-lg-6'>
                  <CtrlInputWrap name="email" label_id="cf_2" required type="email"/>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-6'>
                  <CtrlInputWrap name="phone" label_id="cf_3" required refProps={VALID_PHONE}/>
                </div>
                <div className='col-lg-6'>
                  <CtrlInputWrap name="subject" label_id="cf_4" required/>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-12'>
                  <CtrlTextAreaWrap name="message" label_id="cf_5" required/>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <CtrlCaptcha onChange={onCaptchaChange} refObj={captchaRef} errClassName="error"/>
                </div>
                <div className='col-md-6'>
                  <div className='contectus__btn buy-steps__btns d-flex justify-content-end'>
                    <button className='btn-reset btn-sq btn-blue buy-steps__butt'>
                      <FormattedMessage id="cf_6"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}
