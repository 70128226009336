import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { BasePage } from 'src/pages/base/BasePage'

export class NotFoundPage extends Component {
  Section = (
    <section className='user-form user-login'>
      <div className='container'>
        <div className='not-found octagon octagon-wht'>
          <div className='text-center'>
            <h1 className='not-found__title'>404 - page not found!</h1>
            <p className='not-found__go'>
              Back to <Link to="/" className="link-blue">Main page</Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  )

  render = () => <BasePage headerType={'error'} headerChild={this.Section}/>
}
