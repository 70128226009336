import React from 'react'
import { FormattedMessage } from "react-intl"
import { Redirect, Link, useParams } from 'react-router-dom'
import { ChevronLeft } from 'src/constants/SvgConstants'
import { FaqSectionNav } from './SectionNav'
import { FaqSectionList } from './SectionList'
import { FaqSectionItem } from './SectionItem'
import { FAQ_DATA } from './data'

export const FaqSection = () => {
  const { section, item } = useParams<{ section: string, item: string }>()
  const sectionData = FAQ_DATA.find(item => item.link === section)

  if (!sectionData)
    return <Redirect to="/faq"/>

  const itemId = parseInt(item) || 0
  const itemData = sectionData.items[itemId - 1]

  return (
    <>
      <Link to={`/faq${itemData ? `/${section}` : ''}`} className="faq-list__back no-decorate">
        <ChevronLeft/>
        <span><FormattedMessage
                id="faq_list_back"
              /></span>
      </Link>
      <div className="row">
        <div className="col-lg-3 order-lg-1 order-0">
          <FaqSectionNav/>
        </div>
        <div className="col-lg-9 order-lg-0 order-1">
          {!itemData
            ? <FaqSectionList section={sectionData}/>
            : <FaqSectionItem item={itemData}/>
          }
        </div>
      </div>
    </>
  )
}
