import { FunctionComponent, useEffect, ReactNode, ChangeEvent } from 'react'
import { useIntl } from 'react-intl'
import { ExchangeRateOptions } from 'src/components/about/ExchangeRateOptions'
import { ExchangeRate } from 'src/components/about/ExchangeRate'
import { useStoreExchangeRates } from '@usher/pe-client-front-end-lib'

type props = {
  additionalRowComponent?: ReactNode
}

export const ExchangeRatesCrypto: FunctionComponent<props> = ({ additionalRowComponent }) => {
  const intl = useIntl()

  const initialSymbol = 'USD'
  const OptionsApiDataType = 'fiat'
  const title = intl.formatMessage({ id: 'at_15' })
  const toCyrImgIsShow = false

  const dataList = useStoreExchangeRates((state) => state.cryptoDataList)
  const selectedSymbol = useStoreExchangeRates((state) => state.cryptoSelectedSymbol)
  const loadExchangeRatesCrypto = useStoreExchangeRates((state) => state.loadExchangeRatesCrypto)

  const onChangeCurrency = (event: ChangeEvent<HTMLSelectElement>) => {
    loadExchangeRatesCrypto(event.target.selectedOptions[0].text)
  }

  useEffect(() => {
    loadExchangeRatesCrypto(initialSymbol)
  }, [loadExchangeRatesCrypto])

  return (
    <>
      <div className='suported-assets__top-box d-flex justify-content-between'>
        <h3 className='h3 suported-assets__top-title'>{title}</h3>
        <select className='suported-assets__curency input' onChange={onChangeCurrency} value={selectedSymbol}>
          <ExchangeRateOptions type={OptionsApiDataType} key_equals_value={true} />
        </select>
      </div>
      <ul className='suported-assets__list list'>
        <div className='assets__crypto box__crypto box light'>
          {dataList.length && (
            <div className='box__wrapper box__scroll mCustomScrollbar' data-mcs-theme='light' key={selectedSymbol}>
              {dataList.map((e: any, index: number) => (
                <ExchangeRate
                  key={index}
                  from_cur={e.symbol ?? ''}
                  from_cur_title={e.title}
                  to_cur={selectedSymbol}
                  rate={e.rate}
                  toCyrImgIsShow={toCyrImgIsShow}
                />
              ))}
              {additionalRowComponent}
            </div>
          )}
        </div>
      </ul>
    </>
  )
}
