import { Component } from 'react'
import { FormattedMessage } from "react-intl";

export class CryptoPlatformSection extends Component {
  render = () => (
    <section className='crypto-platform'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 crypto-platform__title text-center'>
              <FormattedMessage
                id="af_8"
                />
            </h2>
            <p className='crypto-platform__description text-center'>
            <FormattedMessage
                id="af_9"
                />
            </p>
          </div>
        </div>
        <div className='row flex-wrap'>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/trading.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_10"
                /></h4>
              <p className='crypto-platform__card-description'><FormattedMessage
                id="af_11"
                /></p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/trans_fees.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_12"
                /></h4>
              <p className='crypto-platform__card-description'><FormattedMessage
                id="af_13"
                /></p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/e_based.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_14"
                /></h4>
              <p className='crypto-platform__card-description'><FormattedMessage
                id="af_15"
                /></p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/24_support.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_16"
                /></h4>
              <p className='crypto-platform__card-description'><FormattedMessage
                id="af_17"
                /></p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/storage.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_18"
                /></h4>
              <p className='crypto-platform__card-description'>
              <FormattedMessage
                id="af_19"
                />
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='crypto-platform__card-wrap'>
              <div className='crypto-platform__icon-wrap'>
                <img src='/img/trade_pm.svg' alt='icon' className='crypto-platform__card-icon' />
              </div>
              <h4 className='h4 crypto-platform__card-title'><FormattedMessage
                id="af_20"
                /></h4>
              <p className='crypto-platform__card-description'><FormattedMessage
                id="af_21"
                /></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
