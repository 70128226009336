import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { EXCHANGE_STEPS } from 'src/constants/ExchangeConstants'
import { ExchangeStep0 } from './Step0'
import { ExchangeStep1 } from './Step1'
import { ExchangeStep2 } from './Step2'
import { ExchangeStep3 } from './Step3'
import { ExchangeStep4 } from './Step4'
import { ExchangeStep5 } from './Step5'

export const Exchange = () => {
  const path = useRouteMatch().path
  const { STEP1, STEP2, STEP3, STEP4, STEP5 } = EXCHANGE_STEPS

  return (
    <div className="buy-steps__payment">
      <Switch>
        <Route exact path={path} component={ExchangeStep0}/>
        <Route path={`${path}/${STEP1.route}`} component={ExchangeStep1}/>
        <Route path={`${path}/${STEP2.route}`} component={ExchangeStep2}/>
        <Route path={`${path}/${STEP3.route}`} component={ExchangeStep3}/>
        <Route path={`${path}/${STEP4.route}`} component={ExchangeStep4}/>
        <Route path={`${path}/${STEP5.route}`} component={ExchangeStep5}/>
        <Redirect to="/404"/>
      </Switch>
    </div>
  )
}
