import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Image } from './Image'
import { Copyright } from './Copyright'
import { COMPANY_PHONE_EE, COMPANY_PHONE_SE, COMPANY_PHONE_UK } from 'src/constants/CommonConstants'

export class Footer extends Component {
  render = () => (
    <footer className='footer'>
      <div className='container'>
        <div className='row footer-row'>
          <div className='col-lg-3 col-sm-6'>
            <div className='footer__item'>
              <h3 className='footer__title'><FormattedMessage id="footer_title_1"/></h3>
              <ul className='list footer__links'>
                <li>
                  <Link to="/exchange" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_1"/>
                  </Link>
                </li>
                <li>
                  <Link to="/rates" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_2"/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <div className='footer__item'>
              <h3 className='footer__title'><FormattedMessage id="footer_title_2"/></h3>
              <ul className='list footer__links'>
                <li>
                  <Link to="/about" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_3"/>
                  </Link>
                </li>
                <li>
                  <Link to="/reviews" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_4"/>
                  </Link>
                </li>
                <li>
                  <Link to="/contacts" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_5"/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <div className='footer__item'>
              <h3 className='footer__title'><FormattedMessage id="footer_title_3"/></h3>
              <ul className='list footer__links'>
                <li>
                  <Link to="/faq" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_6"/>
                  </Link>
                </li>
                {/*<li>
                  <Link to="/fees" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_7"/>
                  </Link>
                </li>*/}
                <li>
                  <Link to="/available" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_8"/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className='col-lg-3 col-sm-6'>
            <div className='footer__item'>
              <h3 className='footer__title'><FormattedMessage id="footer_title_4"/></h3>
              <ul className='list footer__links'>
                <li>
                  <Link to="/terms_of_use" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_9"/>
                  </Link>
                </li>
                <li>
                  <Link to="/privacy_policy" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_10"/>
                  </Link>
                </li>
                <li>
                  <Link to="/aml_policy" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_11"/>
                  </Link>
                </li>
                <li>
                  <Link to="/refund_policy" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_12"/>
                  </Link>
                </li>
                <li>
                  <Link to="/risk_disclosure" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_13"/>
                  </Link>
                </li>
                <li>
                  <Link to="/avoid_fraud" className="footer__link no-decorate">
                    <FormattedMessage id="footer_link_14"/>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-3 col-md-6 footer__cell_mob'>
            <div className='footer-info'>
              <img src='/img/footer-country_1.svg' alt='country' className='img-fluid footer-info__img'/>
              <a href={`tel:${COMPANY_PHONE_EE}`} className='footer-info__tel'>{COMPANY_PHONE_EE}</a>
              <p className='footer-info__date'><FormattedMessage id="footer_info_date"/>: GMT 8AM – 8PM</p>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 footer__cell_mob'>
            <div className='footer-info'>
              <img src='/img/footer-country_2.svg' alt='country' className='img-fluid footer-info__img'/>
              <a href={`tel:${COMPANY_PHONE_UK}`} className='footer-info__tel'>{COMPANY_PHONE_UK}</a>
              <p className='footer-info__date'><FormattedMessage id="footer_info_date"/>: GMT 8AM – 8PM</p>
            </div>
          </div>
          <div className='col-lg-3 col-md-6 footer__cell_mob'>
            <div className='footer-info'>
              <img src='/img/footer-country_3.svg' alt='country' className='img-fluid footer-info__img'/>
              <a href={`tel:${COMPANY_PHONE_SE}`} className='footer-info__tel'>{COMPANY_PHONE_SE}</a>
              <p className='footer-info__date'><FormattedMessage id="footer_info_date"/>: GMT 8AM – 8PM</p>
            </div>
          </div>
          <div className='col-lg-3 col-md-6'>
            <div className='footer-info footer-info__license'>
              <p className='footer-info__tel'>
                BigDrop OÜ, Estonia, Tallinn, Kristiine linnaosa, Tulika tn 19, 10613
              </p>
              <p className='footer__license'>
                <FormattedMessage id="footer_license"/> <span>FVT000078</span>
              </p>
              {/* <p className='footer-info__date'>BigDrop OÜ, Kalda tn 7c-429 Nõmme linnaosa, Tallinn Harju maakond 11625</p>
              <p className='footer__license'>Finacial services, providing a virtual currency service licence nr FVT000078</p> */}
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-4">
            <div className="footer__payments">
              <span className="footer__payments-title"><FormattedMessage id="footer_payments_title"/></span>
              <Image src="mastercard_w.svg" alt="MasterCard" className="img-fluid footer__payments-img"/>
              <Image src="visa.png" alt="Visa" className="img-fluid footer__payments-img"/>
              <Image src="3ds.png" alt="3ds" className="img-fluid footer__payments-img"/>
              <Image src="pci.svg" alt="pci" className="img-fluid footer__payments-img"/>
            </div>
          </div>
          <div className="col-lg-4 text-center">
            <Link to="/" className="footer__logo no-decorate"><Image src="logo-footer.svg" alt="Wallbitex"/></Link>
          </div>
          <div className="col-lg-4 text-lg-right text-center">
            <p className="footer__copy"><Copyright/></p>
          </div>
        </div>
      </div>
    </footer>
  )
}
