import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class AmlContent extends Component {
  render = () => (
    <article className='legal-article mCustomScrollbar' data-mcs-theme='light'>
      <p className='faq-arcticle-text'>
        <strong>
          <FormattedMessage
          id="aml_page_title"
          /></strong>
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_1"
          /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_2"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_3"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_4"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_5"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_6"
          />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_7"
          /></p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
          id="aml_8"
          /></li>
        <li>
        <FormattedMessage
          id="aml_9"
          />
        </li>
      </ul>

      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_10"
          /></strong>
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_11"
          /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_12"
          />
      </p>
      <p className='faq-arcticle-text'>2<FormattedMessage
          id="aml_13"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_14"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_15"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_16"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_17"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_18"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_19"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_20"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_21"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_22"
          /></p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_23"
          /></p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
          id="aml_24"
          /></li>
        <li><FormattedMessage
          id="aml_25"
          /></li>
        <li><FormattedMessage
          id="aml_26"
          /></li>
        <li><FormattedMessage
          id="aml_27"
          /></li>
        <li><FormattedMessage
          id="aml_28"
          /></li>
        <li><FormattedMessage
          id="aml_29"
          /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_30"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_31"
          />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_32"
          /></p>
      <ul className='faq-arcticle__list'>
        <li>
        <FormattedMessage
          id="aml_33"
          />
        </li>
        <li>
        <FormattedMessage
          id="aml_34"
          />
        </li>
        <li>
        <FormattedMessage
          id="aml_35"
          />
        </li>
        <li>
        <FormattedMessage
          id="aml_36"
          />
        </li>
        <li>
        <FormattedMessage
          id="aml_37"
          />
        </li>
        <li><FormattedMessage
          id="aml_38"
          /></li>
        <li><FormattedMessage
          id="aml_39"
          /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_40"
          />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_41"
          /></p>

      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_42"
          /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_43"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_44"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_45"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_46"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_47"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_48"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_49"
          />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_50"
          /></p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
          id="aml_51"
          /></li>
        <li><FormattedMessage
          id="aml_52"
          /></li>
        <li><FormattedMessage
          id="aml_53"
          /></li>
        <li><FormattedMessage
          id="aml_54"
          /></li>
      </ul>

      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_55"
          /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_56"
          />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_57"
          />
      </p>

      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_58"
          /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
          id="aml_59"
          />
      </p>

      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
          id="aml_60"
          /></strong>
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
          id="aml_61"
          /></p>
    </article>
  )
}
