import React from 'react'
import { useIntl } from 'react-intl'
import { NavLink } from 'react-router-dom'
import { FAQ_DATA } from './data'

export const FaqSectionNav = () => {
  const intl = useIntl()

  return (
    <ul className="faq-list__links list">
      {FAQ_DATA.map((item, key) =>
        <li key={key} className="faq-list__links-item">
          <NavLink to={`/faq/${item.link}`} className="faq-list__links-link">
            {intl.formatMessage({ id: item.intl_id })} <span>{item.items.length}</span>
          </NavLink>
        </li>
      )}
    </ul>
  )
}
