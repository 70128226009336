import React from 'react'
import { Image } from 'src/components/common/Image'
import { PaymentType } from '@usher/pe-client-front-end-lib'
import { CtrlInput } from 'src/components/ctrl/Input'
import { useIntl } from 'react-intl'

type Props = {
  paymentType: PaymentType
  paymentTypes: PaymentType[]
  paymentSelected: string
  onSelect: (paymentType: PaymentType) => void
}

export const ExchangeStep1PaymentType = ({ paymentType, paymentTypes, paymentSelected, onSelect }: Props) => {
  const intl = useIntl()

  return (
    <>
      <div className="buy-steps__payment-type">
        {paymentTypes.map((pt) => {
          const isActive = paymentSelected !== '' &&
            ((paymentType.name === '' && pt.id === paymentType.id) || pt.name === paymentType.name)

          const className = `btn-reset buy-steps__payment-wrap${isActive ? ' active' : ''}`
          return (
            <div key={pt.name} onClick={() => onSelect(pt)} className={className}>
              <Image src={`exchange/${pt.name}.svg`} alt={pt.title}/>
            </div>
          )
        })}
      </div>
      <div style={{ margin: '-20px 0 10px 0' }}>
        <CtrlInput type="hidden" name="payment_type" value={paymentSelected}
                   refProps={{ required: intl.formatMessage({ id: 'exchange.select_payment_method' }) }}/>
      </div>
    </>
  )
}
