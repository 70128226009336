import React from 'react'
import { Image } from 'src/components/common/Image'

type Props = {
  rate: number
  change: number
  symbol: string
  currency: string
}

export const ReviewsRatesItem = ({ rate, change, symbol, currency }: Props) => {
  return (
    <div className="reviews-rates__row">
      <span className="reviews-rates__one-value">1.00</span>
      <Image src={`currency/${symbol.toLowerCase()}.svg`} className="reviews-rates__one-ic"/>
      <span className="reviews-rates__val"> = <span>{rate}</span></span>
      <Image src={`currency/${currency.toLowerCase()}.svg`} className="reviews-rates__val-ic"/>
      <span className={`reviews-rates__total ${change < 0 ? 'down' : 'up'}`}>{change.toFixed(2)}%</span>
    </div>
  )
}
