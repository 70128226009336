import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class PrivacyContent extends Component {
  render = () => (
    <article className='legal-article mCustomScrollbar' data-mcs-theme='light'>
      <h3 className='h3 faq-list__title faq-article'>
      <FormattedMessage
        id="privacy_page_title"
        />
      </h3>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_1"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_2"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_3"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_4"
        /></li>
        <li><FormattedMessage
        id="pp_5"
        /></li>
        <li><FormattedMessage
        id="pp_6"
        /></li>
        <li><FormattedMessage
        id="pp_7"
        /></li>
        <li>
        <FormattedMessage
        id="pp_8"
        />
        </li>
        <li><FormattedMessage
        id="pp_9"
        /></li>
        <li><FormattedMessage
        id="pp_10"
        /></li>
        <li><FormattedMessage
        id="pp_11"
        /></li>
      </ul>
      <p className='faq-arcticle-text'><FormattedMessage
        id="pp_12"
        /></p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_13"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_14"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_15"
        /></li>
        <li><FormattedMessage
        id="pp_16"
        /></li>
        <li><FormattedMessage
        id="pp_17"
        /></li>
        <li><FormattedMessage
        id="pp_18"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_19"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_20"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_21"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_22"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_23"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_24"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_25"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_26"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_27"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_28"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_29"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_30"
        /></li>
        <li><FormattedMessage
        id="pp_31"
        /></li>
        <li><FormattedMessage
        id="pp_32"
        /></li>
        <li><FormattedMessage
        id="pp_33"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_34"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_35"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_36"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_37"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_38"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_39"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_40"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="pp_41"
        /></p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_42"
        /></li>
        <li><FormattedMessage
        id="pp_43"
        /></li>
        <li><FormattedMessage
        id="pp_44"
        /></li>
        <li><FormattedMessage
        id="pp_45"
        /></li>
        <li><FormattedMessage
        id="pp_46"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_47"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="pp_48"
        /></p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="pp_49"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_50"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_51"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_52"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_53"
        /></li>
        <li><FormattedMessage
        id="pp_54"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_55"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_56"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_57"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_58"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_59"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_60"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_61"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_62"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_63"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_64"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_65"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_66"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_67"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="pp_68"
        /></li>
        <li><FormattedMessage
        id="pp_69"
        /></li>
        <li><FormattedMessage
        id="pp_70"
        /></li>
        <li><FormattedMessage
        id="pp_71"
        /></li>
        <li><FormattedMessage
        id="pp_72"
        /></li>
        <li><FormattedMessage
        id="pp_73"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_74"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_75"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_76"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_77"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_78"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="pp_79"
        /></p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="pp_80"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="pp_81"
        />
      </p>
    </article>
  )
}
