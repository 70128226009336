import React from 'react'

export const ReviewsPage5 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            I have worked with several exchangers, tried this and that, and the company managed to surprise me.
          </p>
          <span className='reviews__row-autor'>Lucia S.</span>
          <span className='reviews__row-data'>14 Jan, 2021 at 12:45 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Greece</span>
          <img src='/img/countries/europe/greece.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/advcash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good!</p>
          <span className='reviews__row-autor'>Andre G.</span>
          <span className='reviews__row-data'>14 Jan, 2021 at 13:52 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>UK</span>
          <img src='/img/countries/europe/united-kingdom.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/advcash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Prot F.</span>
          <span className='reviews__row-data'>14 Jan, 2021 at 18:41 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Spain</span>
          <img src='/img/countries/europe/spain.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Lena D.</span>
          <span className='reviews__row-data'>15 Jan, 2021 at 21:32 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Finland</span>
          <img src='/img/countries/europe/finland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Vecta D.</span>
          <span className='reviews__row-data'>15 Jan, 2021 at 22:22 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Ireland</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>THX GUYS</p>
          <span className='reviews__row-autor'>Mimi V.</span>
          <span className='reviews__row-data'>16 Jan, 2021 at 17:56 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Greece</span>
          <img src='/img/countries/europe/greece.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good and reliable exchange!</p>
          <span className='reviews__row-autor'>Sergey B.</span>
          <span className='reviews__row-data'>16 Jan, 2021 at 19:18 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>France</span>
          <img src='/img/countries/europe/france.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
