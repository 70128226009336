import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { FaqMainTop } from './MainTop'
import { FaqMainBlock } from './MainBlock'
import { FAQ_DATA } from './data'

export const FaqMain = () => {
  const intl = useIntl()

  return (
    <>
      <FaqMainTop/>
      <ul className="faq__links list">
        {FAQ_DATA.map((item, key) =>
          <li className="faq__links-item" key={key}>
            <Link to={`/faq/${item.link}`} className="faq__links-link no-decorate">
              <span>{intl.formatMessage({ id: item.intl_id })}</span>
              <span>{item.items.length}</span>
            </Link>
          </li>
        )}
      </ul>
      <div className="row">
        <div className="col-lg-6">
          <FaqMainBlock section={FAQ_DATA[0]} count={5}/>
        </div>
        <div className="col-lg-6">
          <FaqMainBlock section={FAQ_DATA[2]} count={5}/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FaqMainBlock section={FAQ_DATA[3]} count={4}/>
        </div>
        <div className="col-lg-6">
          <FaqMainBlock section={FAQ_DATA[1]} count={3}/>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <FaqMainBlock section={FAQ_DATA[4]} count={4}/>
        </div>
      </div>
    </>
  )
}
