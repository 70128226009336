import React from 'react'
import { Link } from 'react-router-dom'
import { Image } from 'src/components/common/Image'

type LogoProps = {
  imgClassName?: string
  linkClassName?: string
}

export const Logo = ({ imgClassName, linkClassName }: LogoProps) =>
  <Link to="/" className={linkClassName}>
    <Image src="logo-w.svg" alt="Wallbitex" className={imgClassName}/>
  </Link>
