import React from 'react'
import { FormattedMessage } from "react-intl"
import { OverlayTrigger, Popover } from 'react-bootstrap'

const popover = (
  <Popover id="how-calculated">
    <Popover.Title as="h3">
    <FormattedMessage
          id="note_title"
        />
    </Popover.Title>
    <Popover.Content>
      <FormattedMessage
          id="note_text"
        />
    </Popover.Content>
  </Popover>
)

type HowCalculatedProps = {
  className?: string
}

export function HowCalculated({ className }: HowCalculatedProps) {
  return (
    <OverlayTrigger trigger="focus" placement="right" overlay={popover}>
      <span role="button" tabIndex={0} className={className}>
        <FormattedMessage
          id="question_calc"
        /></span>
    </OverlayTrigger>
  )
}
