import React from 'react'
import { Image } from 'src/components/common/Image'

export const ExchangeStep3Message = () => {
  return (
    <div className="notification-box">
      <div className="notification__box text-center">
        <Image src="success.svg" className="notification__icon"/>
        <h3 className="notification__title">Success</h3>
        <p className="notification__text">Your documents have been successfully uploaded.
          Manager will contact you soon for account verification purposes.</p>
      </div>
    </div>
  )
}
