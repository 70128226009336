import React from 'react'
import { FormattedMessage } from "react-intl"

export const FAQ_DATA = [
  {
    link: 'account-verification',
    title: 'Account Verification',
    intl_id: 'faq_links_item_1',
    items: [
      {
        date: 'Sunday, October 18, 2020 at 08:37 PM',
        title: 'Are there any special requirements for taking a picture with my ID?',
        intl_id: 'faq_item_1',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_11"
            /></p>
          {/* <ul className="faq-arcticle__list list-numer">
            <li><FormattedMessage
                id="faq_12"
              />
              </li>
            <li><FormattedMessage
                id="faq_13"
              /></li>
            <li><FormattedMessage
                id="faq_14"
              /></li>
          </ul> */}
        </>
      },
      /*{
        date: 'Tuesday, September 8, 2020 at 06:21 PM',
        title: 'The message with verification code was not delivered, I did not receive SMS',
        content: <>
          <p className="faq-arcticle-text">
            Check the phone number you have entered in the corresponding field. Also check the storage space on your
            device.</p>
          <p className="faq-arcticle-text">We kindly ask you to keep in mind that it can take some time, approximately 5
            minutes or so for the message to be delivered and the final time entirely depends on the provider. In case
            you haven’t received the message on time, please contact us and we will do our best to resolve the
            issue.</p>
          <p className="faq-arcticle-text">If you use VOIP system, the verification code can be lost. It’s impossible to
            assure its delivery.</p>
        </>
      },*/
      {
        date: 'Thursday, October 1, 2020 at 05:18 AM',
        title: 'What to do if I did not receive a verification email?',
        intl_id: 'faq_item_2',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_21"
            /></p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_22"
            /></p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_23"
            /></p>
        </>
      },
      {
        date: 'Friday, December 18, 2020 at 11:43 PM',
        title: 'The system asks to verify my account; how should I proceed?',
        intl_id: 'faq_item_3',
        content: <>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_31"
            /></li>
            <li><FormattedMessage
              id="faq_32"
            /></li>
            <li><FormattedMessage
              id="faq_33"
            /></li>
            <li><FormattedMessage
              id="faq_34"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_35"
          /></p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_36"
              values={{ text: <strong>Security Level 1: </strong> }}
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_37"
              values={{ text: <strong>Security Level 2: </strong> }}
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_38"
              values={{ text: <strong>Security Level 3: </strong> }}
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_39"
              values={{ text: <strong>Security Level 4: </strong> }}
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_310"
              values={{ text: <strong>Security Level 5: </strong> }}
            />
          </p>
          <p className="faq-arcticle-text"><strong><FormattedMessage
            id="faq_311"
          />:</strong></p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_312"
            /></li>
            <li><FormattedMessage
              id="faq_313"
            /></li>
            <li><FormattedMessage
              id="faq_314"
            /></li>
            <li><FormattedMessage
              id="faq_315"
            /></li>
            <li><FormattedMessage
              id="faq_316"
            /></li>
            <li><FormattedMessage
              id="faq_317"
            /></li>
            <li><FormattedMessage
              id="faq_318"
            /></li>
            <li><FormattedMessage
              id="faq_319"
            />
              <p><strong><FormattedMessage
                id="faq_320"
              /></strong></p>
            </li>
          </ul>
        </>
      },
      {
        date: 'Monday, October 5, 2020 at 18:45 PM',
        title: 'I don’t want to use my real data to open a Wallbitex account. What if I use somebody else’s data to register?',
        intl_id: 'faq_item_4',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_41"
          /></p>
        </>
      },
      {
        date: 'Wednesday, November 18, 2020 at 15:22 PM',
        title: 'What do I need to open a Wallbitex account?',
        intl_id: 'faq_item_5',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_51"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_52"
          /></p>
        </>
      },
      {
        date: 'Thursday, November 26, 2020 at 18:15 PM',
        title: 'Why the documents I have provided were rejected? I did everything according to the instructions.',
        intl_id: 'faq_6',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_61"
          /></p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_62"
            /></li>
            <li><FormattedMessage
              id="faq_63"
            /></li>
            <li><FormattedMessage
              id="faq_64"
            /></li>
            <li><FormattedMessage
              id="faq_65"
            /></li>
            <li><FormattedMessage
              id="faq_66"
            /></li>
            <li><FormattedMessage
              id="faq_67"
            /></li>
            <li><FormattedMessage
              id="faq_68"
            /></li>
            <li><FormattedMessage
              id="faq_69"
            /></li>
            <li><FormattedMessage
              id="faq_610"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_611"
          /></p>
        </>
      },
      {
        date: 'Thursday, September 24, 2020 at 12:11 PM',
        title: 'I have got a message that my documents will be reviewed. What does it mean?',
        intl_id: 'faq_7',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_71"
          /></p>
        </>
      },
      {
        date: 'Monday, September 28, 2020 at 08:07 AM',
        title: 'I want to upload the scans of my papers. What should I do?',
        intl_id: 'faq_8',
        content: <>
          <ul className="faq-arcticle__list list-numer">
            <li><FormattedMessage
              id="faq_81"
            /></li>
            <li><FormattedMessage
              id="faq_82"
            /></li>
            <li><FormattedMessage
              id="faq_83"
            /></li>
            <li><FormattedMessage
              id="faq_84"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><strong><FormattedMessage
            id="faq_85"
          /></strong></p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_86"
            /></li>
            <li><FormattedMessage
              id="faq_87"
            /></li>
            <li><FormattedMessage
              id="faq_88"
            /></li>
            <li><FormattedMessage
              id="faq_89"
            /></li>
            <li><FormattedMessage
              id="faq_810"
            /></li>
            <li><FormattedMessage
              id="faq_811"
            /></li>
            <li><FormattedMessage
              id="faq_812"
            /></li>
            <li><FormattedMessage
              id="faq_813"
            /></li>
            <li><FormattedMessage
              id="faq_814"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_815"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_816"
          /></p>
        </>
      },
      {
        date: 'Tuesday, September 22, 2020 at 15:06 PM',
        title: 'Why do I need verification?',
        intl_id: 'faq_9',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_91"
            />
          </p>
        </>
      },
      {
        date: 'Wednesday, October 7, 2020 at 11:22 AM',
        title: 'How long  will it take to verify the account?',
        intl_id: 'faq_10',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_101"
            />
          </p>
        </>
      },
    ]
  }, {
    link: 'security',
    title: 'Security',
    intl_id: 'faq_links_item_2',
    items: [
      {
        date: 'Saturday, September 19, 2020 at 09:22 AM',
        title: 'How to reset the password on my own if I don’t want to get in touch with your Support team?',
        intl_id: 'faq_item_15',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_111"
            />
          </p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_112"
            /></li>
            <li><FormattedMessage
              id="faq_113"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_114"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_115"
          /></p>
        </>
      },
      {
        date: 'Sunday, September 27, 2020 at 08:15 AM',
        title: 'Can Login Guard be disabled? Is it possible to do without contacting Support?',
        intl_id: 'faq_item_16',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_121"
          /></p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_122"
            /></li>
            <li><FormattedMessage
              id="faq_123"
            /></li>
            <li><FormattedMessage
              id="faq_124"
            />
            </li>
            <li><FormattedMessage
              id="faq_125"
            /></li>
          </ul>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_126"
          /></p>
        </>
      },
      {
        date: 'Saturday, October 24, 2020 at 16:18 AM',
        title: 'My Payment Password needs resetting. What should I do?',
        intl_id: 'faq_item_17',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_131"
            />
          </p>
          <ul className="faq-arcticle__list">
            <li>  <FormattedMessage
              id="faq_132"
            /></li>
            <li>  <FormattedMessage
              id="faq_133"
            /></li>
            <li>  <FormattedMessage
              id="faq_134"
            /></li>
            <li>  <FormattedMessage
              id="faq_135"
            /></li>
          </ul>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_136"
            />
          </p>
        </>
      },
      {
        date: 'Tuesday, November 10, 2020 at 19:28 PM',
        title: 'I want to know more about Login Guard.',
        intl_id: 'faq_item_12',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_141"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_142"
            />
          </p>
        </>
      },
      {
        date: 'Thursday, December 10, 2020 at 17:51 PM',
        title: 'Why do you use Two-Factor Authentication?',
        intl_id: 'faq_15',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_151"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_152"
            />
          </p>
        </>
      },
    ]
  }, {
    link: 'my-account',
    title: 'My account',
    intl_id: 'faq_links_item_3',
    items: [
      {
        date: 'Wednesday, September 9, 2020 at 07:18 AM',
        title: 'What are the requirements for the password?',
        intl_id: 'faq_16',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_161"
            />
          </p>
          <ul className="faq-arcticle__list">
            <li><FormattedMessage
              id="faq_162"
            /></li>
            <li><FormattedMessage
              id="faq_163"
            /></li>
            <li><FormattedMessage
              id="faq_164"
            /></li>
            <li><FormattedMessage
              id="faq_165"
            /></li>
          </ul>
        </>
      },
      {
        date: 'Saturday, September 12, 2020 at 09:22 AM',
        title: 'How can I change my email? Is it technically possible?',
        intl_id: 'faq_17',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_171"
            />
          </p>
        </>
      },
      {
        date: 'Wednesday, September 23, 2020 at 10:59 AM',
        title: 'I can’t log into the associated email. Can you help me?',
        intl_id: 'faq_18',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_181"
            />
          </p>
        </>
      },
      {
        date: 'Tuesday, October 13, 2020 at 15:31 PM',
        title: 'I want to change the phone number which I used to register and verify the account. How can I do it?',
        intl_id: 'faq_19',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_191"
            />
          </p>
        </>
      },
      {
        date: 'Friday, November 6, 2020 at 21:05 PM',
        title: 'How can I create a Wallbitex account?',
        intl_id: 'faq_20',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_201"
            />
          </p>
        </>
      },
      {
        date: 'Saturday, December 5, 2020 at 07:23 PM',
        title: 'Can I submit the changes in the name or the address I used to create an account?',
        intl_id: 'faq_021',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0211"
            />
          </p>
        </>
      },
      {
        date: 'Friday, December 18, 2020 at 15:38 PM',
        title: 'How can I delete my account?',
        intl_id: 'faq_022',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0221"
            />
          </p>
        </>
      },
    ]
  }, {
    link: 'sending-and-receiving-cryptocurrencies',
    title: 'Sending & Receiving Cryptocurrencies',
    intl_id: 'faq_links_item_4',
    items: [
      {
        date: 'Friday, September 18, 2020 at 09:21 AM',
        title: 'I want a chargeback.',
        intl_id: 'faq_023',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0231"
            />
          </p>
        </>
      },
      {
        date: 'Thursday, October 15, 2020 at 11:17 AM',
        title: 'I want to know more about transaction limits',
        intl_id: 'faq_024',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0241"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0242"
            />
          </p>
        </>
      },
      {
        date: 'Saturday, October 31, 2020 at 10:47 AM',
        title: 'I want to reverse transaction because sent the money to the incorrect address.',
        intl_id: 'faq_025',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0251"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0252"
            />
          </p>
        </>
      },
      {
        date: 'Sunday, November 22, 2020 at 18:31 PM',
        title: 'How to check transaction history?',
        intl_id: 'faq_026',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0261"
            />
          </p>
        </>
      },
      {
        date: 'Sunday, November 22, 2020 at 20:17 PM',
        title: 'How to transfer crypto to another person?',
        intl_id: 'faq_027',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0271"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0272"
            /> <a
              href="https://walbitex.com/avoid-fraud">https://walbitex.com/avoid-fraud</a>
          </p>
        </>
      },
      /*{
        date: 'Wednesday, November 25, 2020 at 08:18 AM',
        title: 'How to get crypto from another person, owning the wallet?',
        content: <>
          <p className="faq-arcticle-text">
            Log in to your account and find “Receive button” on the dashboard. There will be link with the data of your
            wallet. Transfer this data to another wallet owner.
          </p>
          <p className="faq-arcticle-text">
            <strong>Important:</strong>
          </p>
          <p className="faq-arcticle-text">
            The system allows to possess a wallet for one currency only, not the multi-currency wallet. So you can get
            BTC on your BTC wallet only. The same works with any other cryptocurrency. Also it is impossible to reverse
            or stop transaction as soon as you confirm it. It is advised to check a couple of times all the figures and
            other data as well as any additional details carefully to avoid any issues and money loss.
          </p>
        </>
      },*/
      {
        date: 'Monday, December 7, 2020 at 16:22 PM',
        title: 'Cryptocurrency have not arrived to my account',
        intl_id: 'faq_028',
        content: <>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0281"
            />
          </p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0282"
            />
          </p>
        </>
      },
    ]
  }, {
    link: 'verification-level',
    title: 'Verification level',
    intl_id: 'faq_links_item_5',
    items: [
      {
        date: 'Wednesday, October 7, 2020 at 07:48 AM',
        title: 'Level 1: Verification Process Outline',
        intl_id: 'faq_029',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0291"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0292"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0293"
          /></p>
        </>
      },
      {
        date: 'Monday, October 19, 2020 at 10:33 AM',
        title: 'Level 2: Verification Process Outline',
        intl_id: 'faq_030',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0301"
          /></p>
          <p className="faq-arcticle-text">
            <FormattedMessage
              id="faq_0302"
              values={{ text: <strong>Important note:</strong> }}
            /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0303"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0304"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0305"
          /></p>
        </>
      },
      {
        date: 'Wednesday, October 21, 2020 at 15:49 AM',
        title: 'Level 3: Verification Process Outline',
        intl_id: 'faq_031',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0311"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0312"
          /></p>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0313"
          /></p>
        </>
      },
      {
        date: 'Wednesday, October 21, 2020 at 17:22 PM',
        title: 'How to make a valid selfie with ID and a signature?',
        intl_id: 'faq_032',
        content: <>
          <p className="faq-arcticle-text"><FormattedMessage
            id="faq_0321"
          /></p>
        </>
      },
    ]
  },
]
