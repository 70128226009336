import React from 'react'

export const ReviewsPage2 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Everything’s fine, transaction closed. Thanks.</p>
          <span className='reviews__row-autor'>Bongo F.</span>
          <span className='reviews__row-data'>08 Jan, 2021 at 10:52 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Aruba</span>
          <img src='/img/countries/africa/aruba.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>It didn’t even take a single minute. Instant means instant, I see
            Incredible.</p>
          <span className='reviews__row-autor'>Nick B.</span>
          <span className='reviews__row-data'>08 Jan, 2021 at 15:32 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Ethiopia</span>
          <img src='/img/countries/africa/ethiopia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Nice service, love it.</p>
          <span className='reviews__row-autor'>Anna T.</span>
          <span className='reviews__row-data'>08 Jan, 2021 at 17:44 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Egypt</span>
          <img src='/img/countries/africa/egypt.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Rates are amazing, transactions are fast, what else is needed?</p>
          <span className='reviews__row-autor'>Eric N.</span>
          <span className='reviews__row-data'>08 Jan, 2021 at 20:02 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Austria</span>
          <img src='/img/countries/europe/austria.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>It went OK. I got the money, thanks.</p>
          <span className='reviews__row-autor'>Marcin P.</span>
          <span className='reviews__row-data'>09 Jan, 2021 at 07:33 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/advcash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Ed C.</span>
          <span className='reviews__row-data'>09 Jan, 2021 at 11:48 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>France</span>
          <img src='/img/countries/europe/france.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Так быстро! Молодцы, ребята, спасибо за крутой сервис!)))))</p>
          <span className='reviews__row-autor'>Allan M.</span>
          <span className='reviews__row-data'>09 Jan, 2021 at 18:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Estonia</span>
          <img src='/img/countries/europe/estonia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
