import React, { FunctionComponent } from 'react'
import { FormattedMessage } from "react-intl"
import { Image } from 'src/components/common/Image'
import { ExchangeForm } from './ExchangeForm'
import { ExchangeTimer } from './ExchangeTimer'

export const ExchangeSection: FunctionComponent = () => {
  return (
    <section className="credit shadows">
      <div className="container">
        <div className="text-center">
          <h2 className="h2 credit__h2">
            <FormattedMessage
              id="credit_title"/></h2>
          <p className="slogan credit__slogan">
            <FormattedMessage
              id="credit_slogan"/>
          </p>
        </div>
        <div className="exchange-form">
          <ExchangeForm/>
          <ExchangeTimer/>
          <div className="exchange-form__payment d-flex w-100 align-items-center justify-content-center">
            <Image src="mastercard_w.svg" alt="MasterCard" className="credit-payment img-fluid"/>
            <Image src="visa.svg" alt="Visa" className="credit-payment img-fluid"/>
          </div>
        </div>
      </div>
    </section>
  )
}
