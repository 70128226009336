import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Logo } from './Logo'
import { HeaderAuth } from './HeaderAuth'
import { Copyright } from './Copyright'
import { Image } from 'src/components/common/Image'
import { COMPANY_PHONE_EE, COMPANY_PHONE_UK } from 'src/constants/CommonConstants'

export const NavigationLinks = () => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleMenu = () => setIsOpen(!isOpen)

  return (
    <div className='navigation'>
      <div className='container'>
        <div className='row align-items-center flex-nowrap'>
          <div className='col-md-5 sidebar-wrapper'>
            <nav className={`sidebar${isOpen ? ' visible' : ''}`} id='sidebar'>
              <div className='sidebar-box'>
                <div className='sidebar__header d-md-none d-block'>
                  <button className='btn-reset sidebar__close' id='navbar-toggler-close' onClick={toggleMenu}>
                    <Image src="m_close.svg"/>
                  </button>
                  <Logo linkClassName="sidebar__logo-link"/>
                </div>
                <ul className="nav">
                  <li className="nav__item">
                    <Link to="/exchange" className="header__link no-decorate"><FormattedMessage id="navlink1"/></Link>
                  </li>
                  <li className="nav__item">
                    <Link to="/rates" className="header__link no-decorate"><FormattedMessage id="navlink2"/></Link>
                  </li>
                  <li className='nav__item'>
                    <Link to="/faq" className="header__link no-decorate"><FormattedMessage id="navlink3"/></Link>
                  </li>
                </ul>
                <div className='sidebar-contact d-md-none d-block'>
                  <div className='sidebar-contact__item'>
                    <Image src="footer-country_1.svg" className="sidebar-contact__icon"/>
                    <a href={`tel:${COMPANY_PHONE_EE}`} className='sidebar-contact__tel'>{COMPANY_PHONE_EE}</a>
                    <p className='sidebar-contact__des'><FormattedMessage id="footer_info_date"/>: GMT 8AM – 8PM</p>
                  </div>
                  <div className='sidebar-contact__item'>
                    <Image src="footer-country_2.svg" className="sidebar-contact__icon"/>
                    <a href={`tel:${COMPANY_PHONE_UK}`} className='sidebar-contact__tel'>{COMPANY_PHONE_UK}</a>
                    <p className='sidebar-contact__des'><FormattedMessage id="footer_info_date"/>: GMT 8AM – 8PM</p>
                  </div>
                </div>
                <div className='sidebar-footer d-md-none d-block'>
                  <span className='sidebar__pay'><FormattedMessage id="footer_payments_title"/></span>
                  <div className='sidebar-footer__payment d-flex w-100 align-items-center'>
                    <Image src="mastercard_w.svg" alt="mastercard" className="sidebar-footer__payment img-fluid"/>
                    <Image src="visa.svg" alt="visa" className="sidebar-footer__payment img-fluid"/>
                  </div>
                  <p className='sidebar-footer__copy'><Copyright/></p>
                </div>
              </div>
            </nav>
            <div className='sidebar-backdrop' id='sidebar-backdrop'/>
          </div>
          <div className='col-md-2'>
            <div className='header__logo'>
              <button className='navbar-toggler d-md-none d-block' type='button' id='navbar-toggler'
                      onClick={toggleMenu}>
                <svg width={20} height={17} viewBox='0 0 20 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
                  <path fillRule='evenodd' clipRule='evenodd' d='M0 0H20V1.5H0V0ZM0 15H20V16.5H0V15ZM20 9V7.5H0V9H20Z'
                        fill='white'/>
                </svg>
              </button>
              <Logo linkClassName="header__logo-link"/>
            </div>
          </div>
          <div className='col-md-5' style={{ paddingLeft: 0 }}>
            <HeaderAuth/>
          </div>
        </div>
      </div>
    </div>
  )
}
