import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { AskPanel } from 'src/components/common/AskPanel'
import { NavigationLinks } from 'src/components/common/NavigationLinks'
import { ExchangeSection } from 'src/components/index/ExchangeSection'
import { HeaderContent } from 'src/components/index/HeaderContent'
import { Image } from 'src/components/common/Image'

export const IndexPage = () => {
  return (
    <>
      <header className="header header-main shadows">
        <NavigationLinks/>
        <HeaderContent/>
      </header>

      <main>
        <ExchangeSection />
        {/* SECTION 2 */}
        <section className="steps">
          <div className="steps__wrapper shadow">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <img
                    src="/img/logo_sign.svg"
                    alt=""
                    className="img-fluid steps__logo"
                  />
                  <h2 className="h2 steps__h2">
                    <FormattedMessage id="steps_title.easy_steps" tagName="span"/>
                    <FormattedMessage id="steps_title.few_seconds"/>
                  </h2>
                </div>
              </div>
              <div className="row steps__text-wrapper">
                <div className="col-lg-12">
                  <div className="row flex-nowrap">
                    <div className="col-lg-6 steps__numeric steps__numeric_01">
                      <p className="steps__text">
                      <FormattedMessage
                      id="title_steps_1" />
                      </p>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end">
                      <p className="steps__text d-flex justify-content-end">
                      <FormattedMessage
                      id="text_steps_1" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row flex-nowrap">
                    <div className="col-lg-6 steps__numeric steps__numeric_02">
                      <p className="steps__text">
                      <FormattedMessage
                      id="title_steps_2" />
                      </p>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end">
                      <p className="steps__text">
                      <FormattedMessage
                      id="text_steps_2" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="row flex-nowrap">
                    <div className="col-lg-6 steps__numeric steps__numeric_03">
                      <p className="steps__text"><FormattedMessage
                      id="title_steps_3" /></p>
                    </div>
                    <div className="col-lg-6 d-flex justify-content-end">
                      <p className="steps__text">
                      <FormattedMessage
                      id="text_steps_3" />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="steps__actions text-center">
                    <Link to="/exchange" className="no-decorate btn-reset btn-nm btn-green btn-octagon steps__butt">
                      <FormattedMessage id="steps_btn"/>
                    </Link>
                    <p className="steps__go">
                      <FormattedMessage id="question_steps"/>
                      {' '}
                      <Link to="/faq"><FormattedMessage id="link_steps"/></Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION 2 */}
        {/* SECTION 3 */}
        <section className="trading shadows">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="h2 trading__h2">
                  <FormattedMessage
                    id="trading_title" />
                </h2>
                <p className="slogan trading__slogan">
                  <FormattedMessage
                    id="trading_slogan" />{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="trading-accept">
                  <div className="trading-accept__content">
                    <img
                      src="/img/logo_sign_left.svg"
                      alt=""
                      className="trading-accept__logo trading-accept__logo_left"
                    />
                    <img
                      src="/img/logo_sign_right.svg"
                      alt=""
                      className="trading-accept__logo trading-accept__logo_right"
                    />
                    <h3 className="trading-accept__title"><FormattedMessage
                    id="trading_accept_title" /></h3>
                    <div className="trading-accept__wrapper d-flex justify-content-center align-items-center flex-wrap">
                      <img
                        src="/img/logos-1.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/visa.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-3.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-4.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      {/*<img src='/img/logos-5.svg' alt='' className='trading-accept__img' />*/}
                      <img
                        src="/img/logos-6.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-7.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-8.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-9.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                      <img
                        src="/img/logos-10.svg"
                        alt=""
                        className="trading-accept__img"
                      />
                    </div>
                  </div>
                </div>
                <img
                  src="/img/phone.png"
                  alt=""
                  className="trading__picture img-fluid"
                />
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION 3 */}
        {/* SECTION 4 */}
        <section className="shadows why">
          <div className="container text-center">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="h2 why__h2"><FormattedMessage
                    id="why_title" /></h2>
                <p className="slogan why__slogan">
                <FormattedMessage
                    id="why_slogan" />{" "}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="why__item">
                  <span className="why__item-num">01</span>
                  <h3 className="h3 why__item-title">
                  <FormattedMessage
                    id="why_title_1" />
                  </h3>
                  <p className="why__item-text">
                  <FormattedMessage
                    id="why_text_1" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="why__item">
                  <span className="why__item-num">02</span>
                  <h3 className="h3 why__item-title"><FormattedMessage
                    id="why_title_2" /></h3>
                  <p className="why__item-text">
                  <FormattedMessage
                    id="why_text_2" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="why__item">
                  <span className="why__item-num">03</span>
                  <h3 className="h3 why__item-title"><FormattedMessage
                    id="why_title_3" /></h3>
                  <p className="why__item-text">
                  <FormattedMessage
                    id="why_text_3" />
                  </p>
                </div>
              </div>
            </div>
            <Link to="/sign_up" className="no-decorate btn-reset btn-nm btn-green btn-octagon why__butt">
              <FormattedMessage id="why_btn"/>
            </Link>
          </div>
        </section>
        {/* END SECTION 4 */}
        {/* SECTION 5 */}
        <section className="map shadows">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="h2 map__h2"><FormattedMessage id="map_title" /></h2>
                <Image src="world-map.png?1" alt="Map" className="img-fluid map__picture"/>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION 5 */}
        {/* SECTION 6 */}
        <section className="accessible shadows">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="h2 accessible__title">
                  <FormattedMessage
                    id="accessible_title" /></h2>
                <p className="slogan accessible__slogan">
                  <FormattedMessage
                    id="accessible_slogan" />
                </p>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_1.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption">
                  <FormattedMessage
                    id="accessible_caption_1" />
                  </h3>
                  <p className="accessible__text">
                  <FormattedMessage
                    id="accessible_text_1" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_2.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption"><FormattedMessage
                    id="accessible_caption_2" /></h3>
                  <p className="accessible__text"><FormattedMessage
                    id="accessible_text_2" /></p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_3.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption"><FormattedMessage
                    id="accessible_caption_3" /></h3>
                  <p className="accessible__text">
                  <FormattedMessage
                    id="accessible_text_3" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_4.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption">
                  <FormattedMessage
                    id="accessible_caption_4" />
                  </h3>
                  <p className="accessible__text">
                  <FormattedMessage
                    id="accessible_text_4" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_5.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption"><FormattedMessage
                    id="accessible_caption_5" /></h3>
                  <p className="accessible__text">
                  <FormattedMessage
                    id="accessible_text_5" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="accessible__item">
                  <div className="accessible__icon-wrap d-flex align-items-center justify-content-center">
                    <img
                      src="/img/accessible_ic_6.svg"
                      alt=""
                      className="accessible__icon"
                    />
                  </div>
                  <h3 className="h3 accessible__caption"><FormattedMessage
                    id="accessible_caption_6" /></h3>
                  {/*<p className='accessible__text'>Your funds are safe as 90% of them are stored offline, in a secure wallet.</p>*/}
                  <p className="accessible__text">
                  <FormattedMessage
                    id="accessible_text_6" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION 6 */}
        {/* SECTION 7 */}
        <section className="available shadows">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="h2 available__title">
                <FormattedMessage
                    id="available_title" />
                </h2>
                <p className="slogan available__slogan">
                <FormattedMessage
                    id="available_slogan" />
                </p>
              </div>
            </div>
            <div className="row text-center">
              <div className="offset-lg-2 col-lg-4">
                <div className="available__item">
                  <div className="available__icon">
                    <img
                      src="/img/wisamaster.svg"
                      alt=""
                      className="available__icon-pic"
                    />
                  </div>
                  <h3 className="h3 available__caption">
                  <FormattedMessage
                    id="available_caption_1" />
                  </h3>
                  <p className="available__text">
                  <FormattedMessage
                    id="available_text_1" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="available__item">
                  <div className="available__icon">
                    <img
                      src="/img/sepa.svg"
                      alt=""
                      className="available__icon-pic"
                    />
                  </div>
                  <h3 className="h3 available__caption"><FormattedMessage
                    id="available_caption_2" /></h3>
                  <p className="available__text">
                  <FormattedMessage
                    id="available_text_2" />
                  </p>
                </div>
              </div>
              <div className="offset-lg-2 col-lg-4">
                <div className="available__item">
                  <div className="available__icon">
                    <img
                      src="/img/bitcoinelem.svg"
                      alt=""
                      className="available__icon-pic"
                    />
                  </div>
                  <h3 className="h3 available__caption"><FormattedMessage
                    id="available_caption_3" /></h3>
                  <p className="available__text">
                  <FormattedMessage
                    id="available_text_3" />
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="available__item">
                  <div className="available__icon">
                    <img
                      src="/img/pma.svg"
                      alt=""
                      className="available__icon-pic"
                    />
                  </div>
                  <h3 className="h3 available__caption"><FormattedMessage
                    id="available_caption_4" /></h3>
                  <p className="available__text">
                  <FormattedMessage
                    id="available_text_4" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* END SECTION 7 */}
        {/* SECTION 8 */}
        <div className="ask shadows">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2 className="h2 ask__h2">
                  <FormattedMessage id="ask_title.frequently"/>
                  <FormattedMessage id="ask_title.questions" tagName="span"/>
                </h2>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12">
                <AskPanel title_id="ask_title_1" i="1" isOpened={true}>
                  <p className="ask__text"><FormattedMessage id="ask_text_1_1"/></p>
                  <p className="ask__text"><FormattedMessage id="ask_text_1_2"/></p>
                </AskPanel>
                <AskPanel title_id="ask_title_2" i="2">
                  <p className="ask__text"><FormattedMessage id="ask_text_2"/></p>
                </AskPanel>
                <AskPanel title_id="ask_title_3" i="3">
                  <p className="ask__text"><FormattedMessage id="ask_text_3_1"/></p>
                  <p className="ask__text"><FormattedMessage id="ask_text_3_2"/></p>
                  <p className="ask__text"><FormattedMessage id="ask_text_3_3"/></p>
                  <p className="ask__text"><FormattedMessage id="ask_text_3_4"/></p>
                </AskPanel>
              </div>
            </div>
          </div>
        </div>
        {/* END SECTION 8 */}
        <ExchangeSection />
      </main>
    </>
  );
}
