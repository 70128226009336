import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ReviewsPage1 } from './pages/1'
import { ReviewsPage2 } from './pages/2'
import { ReviewsPage3 } from './pages/3'
import { ReviewsPage4 } from './pages/4'
import { ReviewsPage5 } from './pages/5'
import { ReviewsPage6 } from './pages/6'
import { ReviewsPage7 } from './pages/7'
import { ReviewsPage8 } from './pages/8'
import { ReviewsPage9 } from './pages/9'

const path = '/reviews'

export const ReviewsContent = () => {
  return (
    <div className="reviews__box reviews__box_bg-light octagon octagon-wht">
      <Switch>
        <Route exact path={path} component={ReviewsPage1}/>
        <Route path={`${path}/page/2`} component={ReviewsPage2}/>
        <Route path={`${path}/page/3`} component={ReviewsPage3}/>
        <Route path={`${path}/page/4`} component={ReviewsPage4}/>
        <Route path={`${path}/page/5`} component={ReviewsPage5}/>
        <Route path={`${path}/page/6`} component={ReviewsPage6}/>
        <Route path={`${path}/page/7`} component={ReviewsPage7}/>
        <Route path={`${path}/page/8`} component={ReviewsPage8}/>
        <Route path={`${path}/page/9`} component={ReviewsPage9}/>
        <Redirect to="/404"/>
      </Switch>
    </div>
  )
}
