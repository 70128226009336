import { IntlProvider } from "react-intl";
import { ToastContainer } from "react-toastify";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import {
  setConfigExchange,
  setConfigUrls,
  useApp,
  useClientApp,
  useLang,
} from "@usher/pe-client-front-end-lib";
import { IndexPage } from "src/pages/IndexPage";
import { LoginPage } from "src/pages/LoginPage";
import { NotFoundPage } from "src/pages/NotFoundPage";
import { SignupPage } from "src/pages/SignupPage";
import { ExchangePage } from "src/pages/ExchangePage";
import { RecoveryPage } from "src/pages/RecoveryPage";
import { PasswordSetPage } from "src/pages/PasswordSetPage";
import { ContactsPage } from "src/pages/ContactsPage";
import { LegalPage } from "src/pages/LegalPage";
import { AvailablePage } from "src/pages/AvailablePage";
import { AvoidFraudPage } from "src/pages/AvoidFraudPage";
import { VacancyPage } from "src/pages/VacancyPage";
import { RatesPage } from "src/pages/RatesPage";
import { AffiliatePage } from "src/pages/AffiliatePage";
import { ReviewsPage } from "src/pages/ReviewsPage";
import { FaqPage } from "src/pages/FaqPage";
import { AboutPage } from "src/pages/AboutPage";
import { CardsPage } from "src/pages/CardsPage";
import { ApiPage } from "src/pages/ApiPage";
import { InfoPage } from "src/pages/InfoPage";
import { Footer } from "src/components/common/Footer";
import { ScrollToTop } from "src/components/ScrollToTop";
import { EXCHANGE_STEPS } from "src/constants/ExchangeConstants";
import { LANGUAGES } from "src/constants/LangConstants";
import "react-toastify/dist/ReactToastify.css";
import { TopUpCard } from "./pages/top-up card";
import { Provider } from "urql";

setConfigUrls({
  HOME: process.env.REACT_APP_PATH_HOME,
  CLIENT: process.env.REACT_APP_PATH_CLIENT,
  PAY: process.env.REACT_APP_PATH_PAY,
  SECURE: process.env.REACT_APP_PATH_SECURE,
});

setConfigExchange({
  STEPS: EXCHANGE_STEPS,
});

export const App = () => {
  useApp();
  const { locale, basename, messages } = useLang({ languages: LANGUAGES });
  if (locale === "") return <h1>Loading...</h1>;
  return (
    <IntlProvider locale={locale} messages={messages}>
      <ToastContainer autoClose={4000} />
      <BrowserRouter basename={basename}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={IndexPage} />
          <Route path="/login" component={LoginPage} />
          <Route path="/sign_up" component={SignupPage} />
          <Route path="/exchange" component={ExchangePage} />
          <Route path="/top-up_card" component={TopUpCard} />
          <Route path="/recovery" component={RecoveryPage} />
          <Route path="/new_password" component={PasswordSetPage} />
          <Route path="/contacts" component={ContactsPage} />
          <Route path="/reviews/(page)?/:page?" component={ReviewsPage} />
          <Route path="/faq" component={FaqPage} />
          <Route path="/about" component={AboutPage} />
          <Route path="/available" component={AvailablePage} />
          {/*<Route path='/fees' component={FeesPage} />*/}
          <Route path="/avoid_fraud" component={AvoidFraudPage} />
          <Route path="/vacancy" component={VacancyPage} />
          <Route path="/rates" component={RatesPage} />
          <Route path="/affiliate" component={AffiliatePage} />
          <Route path="/cards" component={CardsPage} />
          <Route path="/api" component={ApiPage} />
          <Route path="/terms_of_use" children={<LegalPage selectedId={0} />} />
          <Route
            path="/privacy_policy"
            children={<LegalPage selectedId={1} />}
          />
          <Route path="/aml_policy" children={<LegalPage selectedId={2} />} />
          <Route
            path="/refund_policy"
            children={<LegalPage selectedId={3} />}
          />
          <Route
            path="/risk_disclosure"
            children={<LegalPage selectedId={4} />}
          />
          <Route path="/info" component={InfoPage} />
          <Route path="/404" component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
        <Footer />
      </BrowserRouter>
    </IntlProvider>
  );
};
