import React, { useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { TermsContent } from 'src/components/agreements/TermsContent'
import { PrivacyContent } from 'src/components/agreements/PrivacyContent'
import { AmlContent } from 'src/components/agreements/AmlContent'
import { RefundContent } from 'src/components/agreements/RefundContent'
import { RiskContent } from 'src/components/agreements/RiskContent'
import $ from 'jquery'
import 'jquery-mousewheel'
import 'malihu-custom-scrollbar-plugin'

const loadScrollBar = () => {
  let article = $('.legal-article')

  if (article[0]) {
    // @ts-ignore
    article.mCustomScrollbar({ mouseWheel: { preventDefault: true } })
  }
}

const LINKS = [
  'terms_of_use',
  'privacy_policy',
  'aml_policy',
  'refund_policy',
  'risk_disclosure'
]

export const LegalPage = ({ selectedId }: { selectedId: number }) => {
  useEffect(() => {
    loadScrollBar()
  }, [selectedId])

  return (
    <BaseShadowPage>
      <section className='faq-list'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='faq-list-box octagon octagon-wht'>
                <div className='row'>
                  <div className='col-lg-3 order-lg-1 order-0'>
                    <ul className='faq-list__links list'>
                      {LINKS.map((link, idx) => (
                        <li key={link} className="faq-list__links-item">
                          <NavLink to={`/${link}`} className="faq-list__links-link">
                            <FormattedMessage id={`link-left_${idx + 1}`}/>
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className='col-lg-9 order-lg-0 order-1'>
                    {(() => {
                      switch (selectedId) {
                        case 0:
                          return <TermsContent/>
                        case 1:
                          return <PrivacyContent/>
                        case 2:
                          return <AmlContent/>
                        case 3:
                          return <RefundContent/>
                        case 4:
                          return <RiskContent/>
                        default:
                          return <TermsContent/>
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <p className="footer__copy subtetxt_copyright" style={{ color: '#fff' }}>
                BigDrop OÜ, Estonia, Tallinn, Kristiine linnaosa, Tulika tn 19, 10613 , <FormattedMessage
                id="tu_69"
              /> FVT000078
              </p>
            </div>
          </div>
        </div>
      </section>
    </BaseShadowPage>
  )
}
