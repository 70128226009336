import { Component } from 'react'

export class WhyUsSection extends Component {
  render = () => (
    <section className='why-us shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 why-us__title text-center'>Why Us?</h2>
          </div>
        </div>
        <div className='row flex-wrap'>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon1.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>Use everywhere</h4>
              <p className='why-us__card-description'>
                Debit cards are accepted? Use your
                <br />
                card there! There’s an ATM around
                <br />
                the corner? Withdraw cash in two clicks.
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon2.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>Low rates and easy payments</h4>
              <p className='why-us__card-description'>
                Buy BTC and LTC at the lowest rates
                <br />
                and enjoy a simple, secure access
                <br />
                anywhere in the world.
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon3.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>Security and speed</h4>
              <p className='why-us__card-description'>
                We use the highest cryptography
                <br />
                standards to keep your money safe.
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon4.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>Absolute coverage</h4>
              <p className='why-us__card-description'>
                Our services are offered in more
                <br />
                than 100 countries and we keep expanding!
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon5.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>Fast and easy verification</h4>
              <p className='why-us__card-description'>
                No-delay transactions.
                <br />
                Easy verification.
              </p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='why-us__card-wrap'>
              <div className='why-us__icon-wrap'>
                <img src='/img/why-us-icon6.svg' alt='icon' className='why-us__card-icon' />
              </div>
              <h4 className='h4 why-us__card-title'>The highest standards of cs service</h4>
              <p className='why-us__card-description'>
                Our CS team is always at hand.
                <br />
                Have questions? Just contact us!
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
