import React, { FC } from 'react'

interface StepItemProps {
  label?: string
  className?: string
  children?: any
}

export const ExchangeStepItem: FC<StepItemProps> = ({
  label,
  className,
  children,
}) => {
  return (
    <div className={`buy-steps__item ${className ? className : ''}`}>
      {label && <label className="label">{label}</label>}
      {children}
    </div>
  )
}
