import { Component } from 'react'
import { CountriesSection } from 'src/components/available/CountriesSection'
import { BaseShadowOutPage } from 'src/pages/base/BaseShadowOutPage'
import { RegisterSection } from 'src/components/available/RegisterSection'

export class AvailablePage extends Component {
  render = () => (
    <BaseShadowOutPage headerChild={<RegisterSection />}>
      <CountriesSection />
    </BaseShadowOutPage>
  )
}
