import React from 'react'
import { useExchangeStep5Cart } from '@usher/pe-client-front-end-lib'
import { CartExchange } from 'src/components/common/CartExchange'
import { CartTotals } from 'src/components/common/CartTotals'

export const ExchangeStep5Cart = () => {
  const { info } = useExchangeStep5Cart()

  return (
    <>
      <CartExchange
        fiatAmount={info.fiat_amount}
        fiatCurrency={info.fiat_currency}
        cryptoAmount={info.crypto_amount}
        cryptoCurrency={info.crypto_currency}
      />
      <CartTotals
        fiatAmount={info.fiat_amount}
        fiatCurrency={info.fiat_currency}
        cryptoAmount={info.crypto_amount}
        cryptoCurrency={info.crypto_currency}
        feePercentage={info.transaction_fee_pct}
        rateAmount={info.rate}
        transactionId={info.ex_transaction_id}
        wallet={localStorage.getItem('wallet')}
      />
    </>
  )
}
