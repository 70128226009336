import { Component } from 'react'

export class DebitCardSection extends Component {
  render = () => (
    <section className='debit-card shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <img src='/img/purchases-cart2.png' alt='card' className='debit-card__card-img' />
            <h3 className='h3 debit-card__title'>LTC Debit Card for everyday purchases</h3>
            <p className='debit-card__description'>
              A reloadable cryptocurrency card from Wallbitex allows you to enjoy control over money and
              <br />
              expenses. Everyday purchases, online shopping withdrawals – everything is possible with a<br />
              prepaid card.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='debit-card__button d-flex'>
              <button className='debit-card__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green text-center'>
                Notify Me
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
