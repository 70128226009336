import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

const infoListId = ['pa_76', 'pa_77', 'pa_78', 'pa_79']

export const ExchangeStep3TransactionDetails = () => {
  const intl = useIntl()

  return (
    <>
      <p className="buy-steps__sub-caption"><FormattedMessage id="pa_75"/></p>
      <ul className="buy-steps__upload-info list">
        {infoListId.map(id => <li key={id} className="buy-steps__upload-item">{intl.formatMessage({ id })}</li>)}
      </ul>
    </>
  )
}
