import { Component } from 'react'
import { FormattedMessage } from "react-intl";

export class InviteSection extends Component {
  render = () => (
    <section className='invite'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 ivite__title text-center'>
            <FormattedMessage
              id="af_1"
              /><br />
              <span className='invite__ttitle-text'><FormattedMessage
              id="af_2"
              /></span>
            </h2>
            <p className='invite__title-description text-center'><FormattedMessage
              id="af_3"
              /></p>
          </div>
        </div>
      </div>
    </section>
  )
}
