import React from 'react'
import { Link } from 'react-router-dom'
import { useInfoPage } from '@usher/pe-client-front-end-lib'
import { Header } from 'src/components/common/Header'

export const InfoPage = () => {
  const { code, message } = useInfoPage()

  return (
    <Header classType="error">
      <section className="user-form user-login">
        <div className="container">
          <div className="not-found octagon octagon-wht text-center">
            <h1 className="not-found__title">
              <strong>Error code: {code}</strong>
            </h1>
            <p>{message}</p>
            <p className="not-found__go">
              Back to <Link to="/" className="link-blue">Main page</Link>
            </p>
          </div>
        </div>
      </section>
    </Header>
  )
}
