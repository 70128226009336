import { Component } from 'react'

export class CardsContent extends Component {
  render = () => (
    <>
      {/*HOW-WORKS*/}
      <section className='how-works shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h2 how-works__title text-center'>How it works</h2>
              <p className='how-works__description text-center'>It is easy and fast to start using the card, even if it doesn’t seem so.</p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4'>
              <span className='how-works__number d-block'>01</span>
              <h4 className='h4 how-works__number-title'>Get Wallbitex card</h4>
              <p className='how-works__number-description'>
                The card can be mailed to you if you want. The only thing you should do is to verify your account and fill in the application.
              </p>
            </div>
            <div className='col-lg-4'>
              <span className='how-works__number d-block'>02</span>
              <h4 className='h4 how-works__number-title'>Loading goes next</h4>
              <p className='how-works__number-description'>
                Convert the money you have onto your wallet and load it to the card. The system will convert it to Euros so you can easily spend them
                at your convinience.
              </p>
            </div>
            <div className='col-lg-4'>
              <span className='how-works__number note-line d-block'>03</span>
              <h4 className='h4 how-works__number-title'>
                Now use what you
                <br />
                have got!
              </h4>
              <p className='how-works__number-description'>
                Use your card as a regular ATM card for everyday needs, shopping and in the ATMs worldwide. No borders, no limits.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='how-works__button d-flex'>
                <button className='purchases__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green'>
                  Notify Me
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*END HOW-WORKS*/}
      {/*BITCOIN-CARD*/}
      <section className='bitcoin-card shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h2 bitcoin-card__title text-center'>
                Shop everywhere with
                <br />
                Wallbitex Bitcoin card
              </h2>
            </div>
          </div>
          <div className='row text-lg-left text-center'>
            <div className='col-lg-6'>
              <p className='bitcoin-card__description text-uppercase'>
                <span className='bitcoin-card__description-text'>Use Wallbitex</span> prepaid BTC card for
                <br />
                online or offline shopping or to
                <br />
                withdraw cash from the ATM.
              </p>
            </div>
            <div className='col-lg-6'>
              <ul className='bitcoin-card__list list'>
                <li className='bitcoin-card__lis-item'>
                  Shop in millions of stores worldwide (wherever credit
                  <br /> cards are accepted)
                </li>
                <li className='bitcoin-card__lis-item'>Make cash withdrawals from any ATM in the world</li>
                <li className='bitcoin-card__lis-item'>Shop and pay your bills online with one simple click</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*END BITCOIN-CARD*/}
      {/*TRUSTWORTHY*/}
      <section className='trustworthy shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h2 trustworthy__title text-center'>
                Access Your Assets simple
                <br />
                secure, fast and
                <br />
                trustworthy
              </h2>
            </div>
          </div>
          <div className='row flex-wrap'>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>1</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Chip &amp; pin access</h4>
                <p className='trustworthy__card-description'>
                  EMV chip, PIN code and all the specifications provide fast payments, as well as security up to the highest world standards.
                </p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>2</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Forget your interest charges</h4>
                <p className='trustworthy__card-description'>
                  Payment interest? Forget these words! You load your card from your wallet directly so the money is converted with no interest
                  charge.
                </p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>3</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Low commission rate</h4>
                <p className='trustworthy__card-description'>
                  Use your card as a regular ATM card for everyday needs, shopping and in the ATMs worldwide. No borders, no limits.
                </p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>4</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Worldwide deliveries with no delays</h4>
                <p className='trustworthy__card-description'>
                  We mail cards directly to you even to most remote locations. We value your time and provide the service with minimum delays and
                  lowest rates.
                </p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>5</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Have absolute control</h4>
                <p className='trustworthy__card-description'>
                  You can do all the operations with your card from your card portal, even block it if it required. It is easy , secure and safe.
                </p>
              </div>
            </div>
            <div className='col-xl-4 col-lg-6'>
              <div className='trustworthy__card-wrap'>
                <div className='trustworthy__number-wrap d-flex align-items-center'>
                  <span className='trustworthy__number'>6</span>
                </div>
                <h4 className='h4 trustworthy__card-title'>Notifications to improve our service</h4>
                <p className='trustworthy__card-description'>
                  We value your time and trust so we are about to improve our service and provide you with the opportunity to get the notifications
                  when your card is charged.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*END TRUSTWORTHY*/}
      {/*END TRUSTWORTHY*/}
      <section className='transparent shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <h2 className='h2 transparent__title text-center'>Transparent Low Fees</h2>
              <p className='transparent__title-description text-center'>
                Use Wallbitex prepaid BTC card for online or offline shopping
                <br />
                or withdraw cash in ATM.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='transparent__button d-flex'>
                <button className='transparent__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green'>
                  See card fees &amp; costs list
                </button>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 d-flex'>
              <div className='transparent__table-wrapper octagon octagon-wht w-100'>
                <div className='table-responsive'>
                  <table className='transparent__table table-borderless table'>
                    <thead>
                      <tr>
                        <th className='transparent__table-title'>Action</th>
                        <th className='transparent__table-title'>Fees</th>
                        <th className='transparent__table-title'>Daily limit</th>
                        <th className='transparent__table-title'>Weekly limit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className='tranparent__table-action'>OS Domestic</th>
                        <td>€0.60</td>
                        <td className='table_orange'>€2,500</td>
                        <td className='table_blue'>€10,000</td>
                      </tr>
                      <tr>
                        <th className='tranparent__table-action'>POS International</th>
                        <td>€1.50</td>
                        <td>—</td>
                        <td>—</td>
                      </tr>
                      <tr>
                        <th className='tranparent__table-action'>ATM Withdrawals Domestic</th>
                        <td>€1.50</td>
                        <td className='table_orange'>€820</td>
                        <td className='table_blue'>€6,000</td>
                      </tr>
                      <tr>
                        <th className='tranparent__table-action'>ATM Withdrawals International</th>
                        <td>€2.50</td>
                        <td>—</td>
                        <td>—</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='col-lg-6 d-flex'>
              <div className='transparent__table-wrapper octagon octagon-wht w-100'>
                <div className='table-responsive'>
                  <table className='transparent__table table-borderless table'>
                    <thead>
                      <tr>
                        <th className='transparent__table-title'>Action</th>
                        <th className='transparent__table-title'>Fees</th>
                        <th className='transparent__table-title'>Daily limit</th>
                        <th className='transparent__table-title'>Weekly limit</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th className='tranparent__table-action'>ATM Balance Enquiry</th>
                        <td>€2.50</td>
                        <td>—</td>
                        <td>—</td>
                      </tr>
                      <tr>
                        <th className='tranparent__table-action'>ATM / POS Decline</th>
                        <td>€0.50</td>
                        <td>—</td>
                        <td>—</td>
                      </tr>
                      <tr>
                        <th className='tranparent__table-action'>Currency Conversion International</th>
                        <td>€0.50</td>
                        <td>—</td>
                        <td>—</td>
                      </tr>
                      <tr>
                        <th>Card Load</th>
                        <td>1.00%</td>
                        <td className='table_orange'>€2,500</td>
                        <td>—</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
