import React from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { FormProvider } from 'react-hook-form'
import { useExchangeStep3 } from '@usher/pe-client-front-end-lib'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { AgreeTerms, ConfirmAge, ExchangeStepCheckboxes } from 'src/components/exchange/Checkboxes'
import { ExchangeStep3Upload } from './Upload'
import { ExchangeStep3TransactionDetails } from './TransactionDetails'
import { ExchangeStep3Skip } from './Skip'
import { ExchangeStep3Message } from './Message'
import { PopoverPassport, PopoverPhoto } from './Popovers'
import { ExchangeStepSubmit } from 'src/components/exchange/StepSubmit'

export const ExchangeStep3 = () => {
  const intl = useIntl()

  const {
    formMethods, onSubmit, onSkipConfirm, passport, photo,
    setStep3, showSkip, setShowSkip, isDefaultShop, isDocsUploaded
  } = useExchangeStep3({ history: useHistory() })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ExchangeBodyCaption title={intl.formatMessage({ id: 'pa_74' })}/>
        {(isDefaultShop && isDocsUploaded) ? <ExchangeStep3Message/> : <>
          <ExchangeStep3TransactionDetails/>
          <div className="row">
            <div className="col-lg-6 buy-steps__upload-space">
              <ExchangeStep3Upload
                type="passport" popover={PopoverPassport} file={passport}
                setFile={(file) => setStep3({ passport: file })}
              />
            </div>
            <div className="col-lg-6 buy-steps__upload-space">
              <ExchangeStep3Upload
                type="photo" popover={PopoverPhoto} file={photo}
                setFile={(file) => setStep3({ photo: file })}
              />
            </div>
          </div>
          {!isDefaultShop
          && <ExchangeStep3Skip showSkip={showSkip} setShowSkip={setShowSkip} onConfirm={onSkipConfirm}/>}
          <ExchangeStepCheckboxes>
            <AgreeTerms/>
            <ConfirmAge/>
          </ExchangeStepCheckboxes>
          <ExchangeStepSubmit/>
        </>}
      </form>
    </FormProvider>
  )
}
