import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useRecoveryNewPassword, VALID_PASSWORD } from '@usher/pe-client-front-end-lib'
import { CtrlInputWrap } from 'src/components/ctrl/InputWrap'

export const RecoveryNewPassword = () => {
  const { formMethods, onSubmit } = useRecoveryNewPassword({ history: useHistory() })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-lg-6">
            <CtrlInputWrap name="password" label_id="new_password" required type="password" refProps={VALID_PASSWORD}/>
          </div>
          <div className="col-lg-6">
            <CtrlInputWrap name="password2" label_id="confirm_new_password" required type="password" refProps={{
              validate: (value: string) => (value === formMethods.watch('password')) || `Passwords didn't match`
            }}/>
          </div>
        </div>
        <div className="row">
          <div className="offset-lg-6 col-lg-6 d-flex align-items-center justify-content-end">
            <div className="buy-steps__btns d-flex justify-content-end user-login__form-btn">
              <button type="submit" className="btn-reset btn-sq btn-blue buy-steps__butt">
                <FormattedMessage id="submit"/>
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
