import React from 'react'

export const ReviewsPage9 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            I have worked with several exchangers, tried this and that, and the company managed to surprise me. Good
            customer service,
            excellent rates and fast workflow, transparent commission. Highest scores, and thumbs up, guys
          </p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>20 Nov, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/austria.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>It is my second transaction here, and I want to say that you are awesome,
            guys. Super fast!</p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>25 Nov, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/asia/bhutan.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            The best and the fastest service from my experience. Commission is transparent and adequate! I’ve seen a lot
            and I highly
            recommend this one
          </p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>26 Nov, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/bulgaria.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good! Congrats</p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>03 Dec, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/georgia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Excelente super rápido , transacción confiable. Lo recomiendo.</p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>15 Dec, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>20 Dec, 2020 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/north_america/united-states.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Excellent, that’s what I can say. Recommend, in case it matters.</p>
          <span className='reviews__row-autor'>Marcin Piontek</span>
          <span className='reviews__row-data'>05 Jan, 2021 at 11:43 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>USA</span>
          <img src='/img/countries/europe/montenegro.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
