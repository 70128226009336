import { Component } from 'react'
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { AvoidFraudContent } from 'src/components/AvoidFraudContent'

export class AvoidFraudPage extends Component {
  render = () => (
    <BaseShadowPage>
      <AvoidFraudContent />
    </BaseShadowPage>
  )
}
