import React from 'react'
import { FormattedMessage } from "react-intl"
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { FaqContent } from 'src/components/faq'

export const FaqPage = () => {
  return (
    <BaseShadowPage>
      <section className="faq">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <h2 className="h2 faq-title text-center"><FormattedMessage
                id="faq_title"
              /></h2>
            </div>
          </div>
          <div className="faq__box octagon octagon-wht">
            <FaqContent/>
          </div>
        </div>
      </section>
    </BaseShadowPage>
  )
}
