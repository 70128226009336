import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useExchangeStep0 } from '@usher/pe-client-front-end-lib'

export const ExchangeStep0 = () => {
  const { search, pathname } = useExchangeStep0(useLocation())
  if (pathname === '' || pathname.slice(pathname.length - 1) === '/') return null

  return <Redirect to={{ pathname: pathname, search }}/>
}
