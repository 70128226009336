import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { FormProvider } from 'react-hook-form'
import { useRecoveryForm } from '@usher/pe-client-front-end-lib'
import { CtrlInputWrap } from 'src/components/ctrl/InputWrap'

export const RecoveryForm = () => {
  const { formMethods, onSubmit, email, setEmail } = useRecoveryForm()

  return (
    <FormProvider {...formMethods}>
      {email && (
        <Alert variant="success" onClose={() => setEmail(null)} dismissible>
          <p className="mb-0">
            <FormattedMessage id="ep_26" values={{ email }}/><br/>
            <FormattedMessage id="ep_26_1"/>
          </p>
        </Alert>
      )}
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <CtrlInputWrap name="email" label_id="lf_9" required type="email" autoFocus/>
        <div className="row">
          <div className="offset-lg-6 col-lg-6 d-flex align-items-center justify-content-end">
            <div className="user-login__form-actions">
              <Link to="/login" className="user-login__form-link link-blue">
                <FormattedMessage id="lf_10"/>
              </Link>
            </div>
            <div className="buy-steps__btns d-flex justify-content-end user-login__form-btn">
              <button type="submit" className="btn-reset btn-sq btn-blue buy-steps__butt">
                <FormattedMessage id="lf_11"/>
              </button>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
