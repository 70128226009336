import React from 'react'

export const ReviewsPage6 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            I have worked with several exchangers, tried this and that, and the company managed to surprise me.
          </p>
          <span className='reviews__row-autor'>Ondo D.</span>
          <span className='reviews__row-data'>21 Jan, 2021 at 08:18 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>France</span>
          <img src='/img/countries/europe/france.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good!</p>
          <span className='reviews__row-autor'>Peris X.</span>
          <span className='reviews__row-data'>21 Jan, 2021 at 08:19 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Ireland</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Karl N.</span>
          <span className='reviews__row-data'>23 Jan, 2021 at 15:41 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Latvia</span>
          <img src='/img/countries/europe/latvia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Amanda E.</span>
          <span className='reviews__row-data'>23 Jan, 2021 at 16:22 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Italy</span>
          <img src='/img/countries/europe/italy.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Dmitro G.</span>
          <span className='reviews__row-data'>23 Jan, 2021 at 18:12PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Norway</span>
          <img src='/img/countries/europe/norway.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>THX GUYS</p>
          <span className='reviews__row-autor'>Karmen S.</span>
          <span className='reviews__row-data'>23 Jan, 2021 at 18:55 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Poland</span>
          <img src='/img/countries/europe/poland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/advcash.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good and reliable exchange!</p>
          <span className='reviews__row-autor'>Ben H.</span>
          <span className='reviews__row-data'>23 Jan, 2021 at 20:38 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Romania</span>
          <img src='/img/countries/europe/romania.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
