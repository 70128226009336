import React from 'react'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'
import { FaqMain } from './Main'
import { FaqSection } from './Section'

export const FaqContent = () => {
  const path = useRouteMatch().path
  return (
    <Switch>
      <Route exact path={path} component={FaqMain}/>
      <Route path={`${path}/:section/:item?`} component={FaqSection}/>
      <Redirect to="/404"/>
    </Switch>
  )
}
