import React from 'react'
import { Popover } from 'react-bootstrap'

export const PopoverPhoto = (
  <Popover id="note-photo">
    <Popover.Title as="h3">Note</Popover.Title>
    <Popover.Content>
      Please upload a copy of the payment card used revealing the first six and the last four digits.
    </Popover.Content>
  </Popover>
)

export const PopoverPassport = (
  <Popover id="note-passport">
    <Popover.Title as="h3">Note</Popover.Title>
    <Popover.Content>
      <p>The passport or ID card provided for verification must contain the following information:</p>
      <ul>
        <li>Country that issued the document</li>
        <li>The serial number of the document proving the identity</li>
        <li>Date of issue</li>
        <li>Gender</li>
        <li>Surname</li>
        <li>Name</li>
        <li>Date of birth</li>
        <li>Place of birth</li>
        <li>Date of expiry</li>
      </ul>
    </Popover.Content>
  </Popover>
)
