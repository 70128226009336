import React from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { components, useExchangeStep5, types } from '@usher/pe-client-front-end-lib'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { Image } from 'src/components/common/Image'

export const ExchangeStep5 = () => {
  const intl = useIntl()
  const { result, fetchStatus, onBuyAgain, onTryAgain } = useExchangeStep5({ history: useHistory() })
  const { status, status_id, info } = result
  const { STATUS_COMPLETED, STATUS_ERROR, STATUS_PENDING } = types.ExTransactionStatus

  return (
    <>
      <ExchangeBodyCaption title={intl.formatMessage({ id: 'pa_30' })}/>
      <div className="notification-box">
        <div className="notification__box text-center">
          <Image src={`exchange/status/${status_id}.svg`} className="notification__icon"/>
          <h3 className="notification__title">
            {status}
            {info && <br/>}{info}
          </h3>
          {status_id === STATUS_PENDING
          && <components.Timer
            count_down={6}
            onUpdate={fetchStatus}
            className="notification__text"
            text={intl.formatMessage({ id: 'pa_31' })}
          />}
        </div>
      </div>
      <div className="buy-steps__btns d-flex justify-content-end">
        {status_id === STATUS_ERROR
        && <button type="button" className="btn-reset btn-sq btn-green buy-steps__butt"
                   onClick={onTryAgain}><FormattedMessage id="pa_32"/></button>
        }
        {status_id === STATUS_COMPLETED
        && <button type="button" className="btn-reset btn-sq btn-green buy-steps__butt"
                   onClick={onBuyAgain}><FormattedMessage id="pa_33"/></button>}
      </div>
    </>
  )
}
