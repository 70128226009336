import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormProvider } from 'react-hook-form'
import { useRegisterForm, VALID_NAME, VALID_PASSWORD, VALID_PHONE } from '@usher/pe-client-front-end-lib'
import { EmailVerifyModal } from 'src/components/modals/EmailVerifyModal'
import { CtrlInputWrap } from 'src/components/ctrl/InputWrap'
import { CtrlCaptcha } from 'src/components/ctrl/Captcha'
import { AcceptRefund, AcceptRisk, AgreeTerms, ConfirmAge } from 'src/components/exchange/Checkboxes'
import { CtrlSelectCountry } from 'src/components/ctrl/SelectCountry'
import { SignupFormSelectStyles } from 'src/components/signup/SignupFormSelectStyles'
import { CtrlRadio } from 'src/components/ctrl/Radio'

export default function SignUpForm() {
  const intl = useIntl()
  const { formMethods, onSubmit, captchaRef, onCaptchaChange, modalProps, modalOnClose } = useRegisterForm({
    msgRegistered: intl.formatMessage({ id: 'pa_72' })
  })

  return (
    <>
      <EmailVerifyModal {...modalProps} handleClose={modalOnClose}/>
      <section className='user-form user-signup'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='user-form__header'>
                <div className='row align-items-baseline'>
                  <div className='col-lg-6'>
                    <h2 className='h2 user-form__header-title'><FormattedMessage id="lf_4"/></h2>
                  </div>
                  <div className='col-lg-6 text-right'>
                    <span className='user-form__info-title'>
                      <FormattedMessage id="already_registered"/>
                      <Link to="/login" className="user-form__info-link link-green">
                        <FormattedMessage id="lf_6"/>
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <div className="user-form__form user-signup__form octagon octagon-wht">
                <div className="row">
                  <div className="col-lg-6">
                    <CtrlInputWrap name="first_name" label_id="af_38" required refProps={VALID_NAME}/>
                    <CtrlInputWrap name="last_name" label_id="af_39" required refProps={VALID_NAME}/>
                    <CtrlInputWrap name="email" label_id="lf_1" required type="email"/>
                    <CtrlInputWrap name="phone" label_id="af_42" required refProps={VALID_PHONE}/>
                    <CtrlInputWrap name="password" label_id="lf_2" required type="password" refProps={VALID_PASSWORD}/>
                    <CtrlInputWrap name="password2" label_id="repeat_password" required type="password" refProps={{
                      validate: (value: string) => (value === formMethods.watch('password')) || `Passwords didn't match`
                    }}/>
                  </div>
                  <div className="col-lg-6">
                    <div className="user-form__item">
                      <label className="label"><FormattedMessage id="pa_60"/></label>
                      <CtrlSelectCountry customStyles={SignupFormSelectStyles} required/>
                    </div>

                    <CtrlInputWrap name="city" label_id="pa_62" required/>
                    <CtrlInputWrap name="state" label_id="pa_61" required/>
                    <CtrlInputWrap name="address" label_id="pa_64" required/>
                    <CtrlInputWrap name="zip" label_id="pa_63" required/>

                    <div className="user-form__item">
                      <span className="label"><FormattedMessage id="gender"/></span>
                      <div className="user-form__rdbtns d-flex">
                        <CtrlRadio name="sex" value="1" className="user-form__item-label" required>
                          <FormattedMessage id="pa_56"/>
                        </CtrlRadio>
                        <CtrlRadio name="sex" value="0" className="user-form__item-label" required>
                          <FormattedMessage id="pa_57"/>
                        </CtrlRadio>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="buy-steps__btns">
                  <CtrlCaptcha onChange={onCaptchaChange} refObj={captchaRef} errClassName="error"/>
                </div>

                <div className="user-signup__form-actions">
                  <AgreeTerms name="terms_accept" className="user-signup__form-label" errClassName="error"/>
                  <AcceptRisk name="risk_accept" className="user-signup__form-label" errClassName="error"/>
                  <AcceptRefund name="refund_accept" className="user-signup__form-label" errClassName="error"/>
                  <ConfirmAge name="age_accept" className="user-signup__form-label" errClassName="error"/>
                </div>

                <div className="buy-steps__btns d-flex justify-content-end">
                  <button type="submit" className="btn-reset btn-sq btn-blue buy-steps__butt">
                    <FormattedMessage id="lf_4"/>
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </div>
      </section>
    </>
  )
}
