import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormProvider } from 'react-hook-form'
import { EXCHANGE_TYPE_CRYPTO, EXCHANGE_TYPE_FIAT, useExchangeStep1 } from '@usher/pe-client-front-end-lib'
import { CtrlInput } from 'src/components/ctrl/Input'
import { ExchangeStepItem } from 'src/components/exchange/StepItem'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { ExchangeStep1PaymentType } from './PaymentType'
import { ExchangeStep1Wallet } from './Wallet'
import { ExchangeStep1VerifyModal } from './VerifyModal'
import { AcceptRefund, AgreeTerms, ConfirmAge, ExchangeStepCheckboxes } from 'src/components/exchange/Checkboxes'
import { ExchangeStep1ExchangeRow } from 'src/components/exchange/Step1/ExchangeRow'

export const ExchangeStep1 = () => {
  const intl = useIntl()
  const {
    formMethods, isAuthorized, isShowVerifyEmail, paymentTypes,
    onSubmit, onEmailVerified, onEmailVerifyHide, captchaRef, onCaptchaChange, step1Values,
    selectFiat, selectCrypto, hasWallet, onSelectPayment, paymentSelected
  } = useExchangeStep1({
    history: useHistory(),
    msgFieldRequired: intl.formatMessage({ id: 'field_required' }),
    msgInvalidWallet: intl.formatMessage({ id: 'invalid_wallet' }),
    msgEnterValidWallet: intl.formatMessage({ id: 'enter_valid_wallet' })
  })

  const { paymentType } = step1Values

  return (
    <FormProvider {...formMethods}>
      <ExchangeBodyCaption title={intl.formatMessage({ id: 'exchange.payment_method' })}/>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ExchangeStep1PaymentType
          paymentType={paymentType}
          paymentTypes={paymentTypes}
          onSelect={onSelectPayment}
          paymentSelected={paymentSelected}
        />
        <ExchangeStep1ExchangeRow type={EXCHANGE_TYPE_FIAT} selector={selectFiat}/>
        <ExchangeStep1ExchangeRow type={EXCHANGE_TYPE_CRYPTO} selector={selectCrypto}/>
        {!isAuthorized && (
          <ExchangeStepItem label={intl.formatMessage({ id: 'exchange.email' })}>
            <CtrlInput type="email" name="email" className="input" required/>
          </ExchangeStepItem>
        )}
        <ExchangeStep1Wallet hasWallet={hasWallet}/>
        <ExchangeStepCheckboxes>
          <AgreeTerms/>
          <AcceptRefund/>
          <ConfirmAge/>
        </ExchangeStepCheckboxes>
        <div className="d-lg-flex">
          {!isAuthorized && (
            <div className="mr-auto mb-3 mb-sm-0">
              <ReCAPTCHA ref={captchaRef} onChange={onCaptchaChange}
                         sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
                         hl={intl.locale.substring(0, 2)}
              />
              <CtrlInput type="hidden" name="captcha" refProps={{ required: intl.formatMessage({ id: 'pa_25' }) }}/>
            </div>
          )}
          <div className="ml-auto justify-content-end align-self-end text-right">
            <button type="submit" style={{ marginBottom: 2 }} className="btn-reset btn-sq btn-green buy-steps__butt">
              <FormattedMessage id="exchange.continue"/>
            </button>
          </div>
        </div>
      </form>
      {isShowVerifyEmail && <ExchangeStep1VerifyModal onHide={onEmailVerifyHide} onVerified={onEmailVerified}/>}
    </FormProvider>
  )
}
