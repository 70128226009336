import React from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { useExchangeStep2 } from '@usher/pe-client-front-end-lib'
import {
  AcceptRefund,
  AcceptRisk,
  AgreeTerms,
  ConfirmAge,
  ExchangeStepCheckboxes
} from 'src/components/exchange/Checkboxes'
import { ExchangeStep2PersonalInfo } from './PersonalInfo'
import { ExchangeStep2BillingAddress } from './BillingAddress'
import { ExchangeStepSubmit } from 'src/components/exchange/StepSubmit'

export const ExchangeStep2 = () => {
  const intl = useIntl()
  const { formMethods, onSubmit } = useExchangeStep2({
    history: useHistory(),
    msgRegistered: intl.formatMessage({ id: 'pa_72' })
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <ExchangeStep2PersonalInfo/>
        <ExchangeStep2BillingAddress/>
        <ExchangeStepCheckboxes>
          <AgreeTerms/>
          <AcceptRisk/>
          <AcceptRefund/>
          <ConfirmAge/>
        </ExchangeStepCheckboxes>
        <ExchangeStepSubmit/>
      </form>
    </FormProvider>
  )
}
