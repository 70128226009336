import React from 'react'
import { FormattedMessage } from 'react-intl'

type Props = {
  isOpened?: boolean
  title?: string
  title_id?: string
  i: string
  children?: any
}

export const AskPanel = ({ isOpened, title, title_id, i, children }: Props) => {
  return (
    <div className={`ask__panel ${isOpened ? 'ask__collapse_show' : ''}`}>
      <div className="ask__heading">
        <h4 className="ask__panel_title">
          <a data-toggle="collapse" href={`#collapse_${i}`} className="ask__link" aria-expanded={isOpened}>
            {title}
            {title_id && <FormattedMessage id={title_id}/>}
          </a>
        </h4>
      </div>
      <div id={`collapse_${i}`} className={`ask__collapse collapse ${isOpened ? 'show' : ''}`}>
        <div className='ask__body'>
          {children}
        </div>
      </div>
    </div>
  )
}
