import React from 'react'
import { OverlayTrigger } from 'react-bootstrap'
import { Image } from 'src/components/common/Image'
import { CtrlInput } from 'src/components/ctrl/Input'
import { useExchangeStep3Upload } from '@usher/pe-client-front-end-lib'
import { FormattedMessage } from 'react-intl'

type UploadProps = {
  type: 'photo' | 'passport'
  popover: JSX.Element
  file?: File
  setFile: (file: File) => void
}

type UploadedProps = {
  type: UploadProps['type']
  file: File
}

const baseStyle = {}
const activeStyle = { background: '#2196f3' }
const acceptStyle = { background: '#78ff73' }
const rejectStyle = { background: '#ff1744' }

const ImageUploaded = ({ type, file }: UploadedProps): JSX.Element => {
  if (file) return <img src={URL.createObjectURL(file)} className="dropzone__image" alt=""/>
  return (
    <>
      <Image src={`upload_${type}.svg`} className="dropzone__icon"/>
      <span className="dropzone__title">
        {type === 'photo' && <FormattedMessage id="upload_photo"/>}
        {type === 'passport' && <FormattedMessage id="pa_80"/>}
      </span>
    </>
  )
}

export const ExchangeStep3Upload = ({ type, popover, file, setFile }: UploadProps) => {
  const { getRootProps, getInputProps, style } = useExchangeStep3Upload({
    file,
    setFile,
    baseStyle,
    activeStyle,
    acceptStyle,
    rejectStyle
  })

  return (
    <div className="buy-steps__upload">
      <OverlayTrigger trigger="focus" placement="bottom" overlay={popover}>
        <button type="button" className="btn-reset buy-steps__popover">
          <Image src="info_d.svg" className="buy-steps__popover-icon"/>
        </button>
      </OverlayTrigger>
      <div className="buy-steps__label text-center">
        { /* @ts-ignore */}
        <div {...getRootProps({ className: 'dropzone', style })}>
          <ImageUploaded type={type} file={file}/>
          <p className="dropzone__des">
            <FormattedMessage id="pa_81"/>{' '}
            <FormattedMessage id="pa_81_1" tagName="span"/>{' '}
            <FormattedMessage id="pa_81_2"/>
          </p>
          { /* @ts-ignore */}
          <input {...getInputProps({ name: type })} />
          <CtrlInput type="hidden" name={`${type}_file`} value={file ? '1' : ''} required/>
        </div>
      </div>
    </div>
  )
}
