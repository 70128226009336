import React from 'react'
import { useIntl } from 'react-intl'
import { components, useStoreExchange, selectFetchPrices } from '@usher/pe-client-front-end-lib'

export const ExchangeCartTimer = () => {
  const intl = useIntl()
  const fetchPrices = useStoreExchange(selectFetchPrices)

  return <components.Timer
    count_down={15}
    onUpdate={fetchPrices}
    className="buy-steps__info-recalc"
    text={intl.formatMessage({ id: 'exchange.price_recalculated' })}
  />
}
