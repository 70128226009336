import React, { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class TermsContent extends Component {
  render = () => (
    <article className='legal-article mCustomScrollbar' data-mcs-theme='light'>
      <h3 className='h3 faq-list__title faq-article'>
        <FormattedMessage
        id="term_page_title"
        /></h3>
      <p className='faq-arcticle-text'>
        <strong>
        <FormattedMessage
        id="tu_1"
        />
        </strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_2"
        values={{ text: <a href="https://www.wallbitex.com">www.wallbitex.com</a> }}
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong> <FormattedMessage
        id="tu_3"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_4"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_5"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_6"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_7"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_8"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong> <FormattedMessage
        id="tu_9"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_10"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_11"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong> <FormattedMessage
        id="tu_12"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_13"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_14"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_15"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_16"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_17"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_18"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong> <FormattedMessage
        id="tu_19"
        /></strong>
      </p>
      {/* <p className="faq-arcticle-text">
                In most jurisdictions taxation of digital assets has no regulations or, on the contrary, is strictly limited and controlled by the governments. When you decide to process transactions, note that you can become subject to certain implications, related to the tax policy.
            </p> */}
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_20"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_21"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_22"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_23"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_24"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_25"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_26"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_27"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="tu_28"
        /></p>
      <p className='faq-arcticle-text'>
        <strong>- <FormattedMessage
        id="tu_29"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_30"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong>- <FormattedMessage
        id="tu_31"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_32"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_33"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_34"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_35"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_36"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_37"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_38"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_39"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_40"
        /></strong>
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="tu_41"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_42"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_43"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_44"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_45"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_46"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_47"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_48"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_49"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_50"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_51"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_52"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_53"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_54"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_55"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_56"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_57"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_58"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_59"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_60"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_61"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_62"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_63"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_64"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="tu_65"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_66"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_67"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="tu_68"
        />
      </p>
    </article>
  )
}
