import { Component } from 'react'
import { ApiData } from '@usher/pe-client-front-end-lib'

export interface ISelectorApiDataState {
  data_list: any[]
}

export interface SelectorApiDataProps {
  selectedText?: string
  key_equals_value: boolean
  type: 'fiat' | 'crypto'
  param?: string
  onLoaded?: () => void
  onReloaded?: (firstValue: string) => void
}

export class ExchangeRateOptions extends Component<SelectorApiDataProps, ISelectorApiDataState> {
  state = {
    data_list: [{}],
  }

  public componentDidMount(): void {
    this.updateList(true)
  }

  updateList = async (isFirst: boolean) => {
    const data = await ApiData.getSelectorList(this.props.type)
    const curSelected = this.getCurrentSelected()

    this.setState(
      {
        data_list: data,
      },
      isFirst
        ? this.props.onLoaded
        : () => {
            if (this.props.onReloaded && this.state.data_list.length > 0) {
              let newSelected = this.getCurrentSelected()
              if (curSelected !== newSelected) this.props.onReloaded(newSelected)
            }
          },
    )
  }

  getCurrentSelected = () => {
    return this.state.data_list.length > 0 ? this.state.data_list[0]['value'] : null
  }

  public async componentDidUpdate(prevProps: Readonly<SelectorApiDataProps>): Promise<void> {
    if (prevProps.param !== this.props.param) {
      await this.updateList(false)
    }
  }

  public render = () => {
    return (
      <>
        {this.state.data_list.map((k: any, index: number) => (
          <option className='suported-assets__curency-item' key={index} value={this.props.key_equals_value ? k.key : k.value}>
            {k.key}
          </option>
        ))}
      </>
    )
  }
}
