import { Component } from 'react'

export class ApiContent extends Component {
  render = () => (
    <>
      {/*BITCOIN-API*/}
      <section className='bitcoin-api'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h3 className='h3 bitcoin-api__title'>Bitcoin API for Banks and PSPs</h3>
              <p className='bitcoin-api__description'>
                We stand for efficient and powerful solutions in FinTech sector. So together with Confirmo.net and MyTrezor.com we have created an
                API, focused on working with banks and PSPs. The product allows to integrate blockchain related services to the currently existing
                Bank and Financial Institutions environment. The services include trading, money transmitting, payments and many more. Sounds good?
                Get in touch with us now!
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='bitcoin-api__button d-flex'>
                <a href='/contacts' className='bitcoin-api__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green text-center'>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*END BITCOIN-API*/}
      {/*END BITCOIN-API*/}
      <section className='api-atms shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'>
              <div className='api-atms__wrapper d-flex flex-column align-items-center octagon octagon-green text-center'>
                <h3 className='h3 api-atms__title'>Bitcoin for merchants</h3>
                <p className='api-atms__title-description'>Allow to accept bitcoin payments on the website?</p>
                <div className='api-atms__btn buy-steps__btns d-flex justify-content-end'>
                  <a href='/' className='api-atms__button btn-reset btn-sq btn-blue buy-steps__butt'>
                    Confirmo.net
                  </a>
                </div>
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='api-atms__mass-wrapper'>
                <h3 className='h3 api-atms__mass-title'>Bitcoin ATMs, Mass Payouts and Payroll</h3>
                <p className='api-atms__mass-description'>
                  Please note, that CoinMate is completely integrated in ATMs of Lamassu and General Bytes companies. If you need another machines to
                  be empowered, this option is possible on enquiry. You can easily switch ATM to CoinMate.io and start enjoying VIP services and
                  conditions, as well as fast operations processing. If you need mass payouts, you can simply use Bitcoin Payment API. Send fast
                  multiple payments a couple of cents each.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*END BITCOIN-API*/}
      {/*AUTOMATED*/}
      <section className='automated shadows'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='automated__wrapper text-center'>
                <div className='automated__icon-wrap d-flex align-items-center justify-content-center'>
                  <img src='/img/automated.svg' alt='icon' className='automated__icon' />
                </div>
                <h4 className='h4 automated__title'>Automated trading bots</h4>
                <p className='automated__description'>
                  Start working with one of the automated trading bots in seconds – choose Trending bot,
                  <br />
                  Liquidity making bot or even an Arbitrage bot to increase profit.
                  <br />
                  Interested in passive income? The option is coming soon, stay tuned!
                </p>
                <a className='automated__button no-decorate' href='/'>
                  Go to Apiary
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
