import React from 'react'
import { FormattedMessage } from "react-intl"
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { Rates } from 'src/components/rates'
import { RatesTrading } from 'src/components/rates/Trading'

export const RatesPage = () => {
  return (
    <BaseShadowPage>
      <section className="сryptocurrency-rates">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="h2 сryptocurrency-rates__title text-center">
                <FormattedMessage
                  id="rates_title1"/><br/>
                <span className="сryptocurrency-rates__title-name">
                <FormattedMessage
                  id="rates_title2"/>
                </span>
              </h2>
              <Rates/>
            </div>
          </div>
        </div>
      </section>
      <RatesTrading/>
    </BaseShadowPage>
  )
}
