import { Component } from 'react'
import { FormattedMessage } from "react-intl"
import { COMPANY_PHONE_EE, COMPANY_PHONE_SE, COMPANY_PHONE_UK } from 'src/constants/CommonConstants'

export class ContactsList extends Component {
  render = () => (
    <div className='contact-data'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-4 d-flex flex-column text-center'>
            <img src='/img/footer-country_1.svg' alt='icon' className='contact-data-icon img-fluid' />
            <a href={`tel:${COMPANY_PHONE_EE}`} className='contact-data__fone-number no-decorate link-green'>
              {COMPANY_PHONE_EE}
            </a>
            <p className='contact-data__day-time'>
              <FormattedMessage
                          id="footer_info_date"
                        />: <span>gtm 8am - 8pm</span>
            </p>
          </div>
          <div className='col-lg-4 d-flex flex-column text-center'>
            <img src='/img/footer-country_2.svg' alt='icon' className='contact-data-icon' />
            <a href={`tel:${COMPANY_PHONE_UK}`} className='contact-data__fone-number no-decorate link-green'>
              {COMPANY_PHONE_UK}
            </a>
            <p className='contact-data__day-time'>
              <FormattedMessage
                          id="footer_info_date"
                        />: <span>gtm 3am - 3pm</span>
            </p>
          </div>
          <div className='col-lg-4 d-flex flex-column text-center'>
            <img src='/img/footer-country_3.svg' alt='icon' className='contact-data-icon' />
            <a href={`tel:${COMPANY_PHONE_SE}`} className='contact-data__fone-number no-decorate link-green'>
              {COMPANY_PHONE_SE}
            </a>
            <p className='contact-data__day-time'>
              <FormattedMessage
                          id="footer_info_date"
                        />: <span>gtm 3am - 3pm</span>
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-4 text-center'>
            <p className='contact-data__info'><FormattedMessage
                          id="cf_7"
                        /></p>
            <a href='mailTo:business@wallbitex.com' className='contact-data__link no-decorate link-green'>
              business@wallbitex.com
            </a>
          </div>
          <div className='col-lg-4 d-flex flex-column text-center'>
            <p className='contact-data__info'><FormattedMessage
                          id="cf_8"
                        /></p>
            <a href='mailTo:support@wallbitex.com' className='contact-data__link no-decorate link-green'>
              support@wallbitex.com
            </a>
          </div> 
          <div className='col-lg-4 d-flex flex-column text-center'>
            <p className='contact-data__info'>BigDrop OÜ, Estonia, Tallinn, Kristiine linnaosa, Tulika tn 19, 10613 </p>
            
          </div>
        </div>
      </div>
    </div>
  )
}
