import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class AboutSection extends Component {
  render = () => (
    <section className='about-us'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 about-us__title text-center'> 
            <FormattedMessage
                id="about_page_title"
              /></h2>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='about-us__wrapper'>
              <div className='about-us__icon-wrap d-flex flex-column'>
                <img src='/img/about-us-image1.jpg' alt='' className='about-us__image' />
              </div>
              <p className='about-us__image-description'>
              <FormattedMessage
                id="at_1"
              />
              </p>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='about-us__wrapper'>
              <div className='about-us__icon-wrap d-flex flex-column'>
                <img src='/img/about-us-image2.jpg' alt='' className='about-us__image' />
              </div>
              <p className='about-us__image-description'>
              <FormattedMessage
                id="at_2"
              />
              </p>
              <p className='about-us__image-description'>
              <FormattedMessage
                id="at_3"
              />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
