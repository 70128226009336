import { Component } from 'react'
import { FormattedMessage } from "react-intl";

export class RefundContent extends Component {
  render = () => (
    <article className='legal-article mCustomScrollbar' data-mcs-theme='light'>
      <h3 className='h3 faq-list__title faq-article'>
      <FormattedMessage
        id="ref_page_title"
        />
      </h3>
      <p className='faq-arcticle-text'>
        <strong> <FormattedMessage
        id="ref_1"
        /> </strong>
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_2"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_3"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_4"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_5"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_6"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_7"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_8"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_9"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_10"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_11"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_12"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_13"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_14"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_15"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_16"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_17"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_18"
        />
      </p>
      <ul className='faq-arcticle__list'>
        <li><FormattedMessage
        id="ref_19"
        /></li>
        <li><FormattedMessage
        id="ref_20"
        /></li>
        <li><FormattedMessage
        id="ref_21"
        /></li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_22"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_23"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_24"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_25"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_26"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_27"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_28"
        /></strong>
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_29"
        /></p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_30"
        /></p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_31"
        /> </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_32"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_33"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_34"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_35"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_36"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_37"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_38"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_39"
        /></p>
      <ul className='faq-arcticle__list'>
        <li> <FormattedMessage
        id="ref_40"
        /></li>
        <li>
        <FormattedMessage
        id="ref_41"
        />
        </li>
      </ul>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_42"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_43"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_44"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_45"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_46"
        />
      </p>
      <p className='faq-arcticle-text'><FormattedMessage
        id="ref_47"
        /></p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_48"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_49"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_50"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_51"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_52"
        />
      </p>
      <p className='faq-arcticle-text'>
        <strong><FormattedMessage
        id="ref_53"
        /></strong>
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_54"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_55"
        />
      </p>
      <p className='faq-arcticle-text'>
      <FormattedMessage
        id="ref_56"
        />
      </p>
    </article>
  )
}
