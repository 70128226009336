import React, { FC, InputHTMLAttributes } from 'react'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  refProps?: object
  required?: boolean
  errClassName?: string
}

export const CtrlCheckbox: FC<CheckboxProps> = ({
  name,
  label,
  refProps,
  required,
  children,
  className,
  errClassName = null,
  ...rest
}) => {
  const intl = useIntl()
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: intl.formatMessage({ id: 'field_required' }) }

  return (
    <>
      <div className={`custom-control custom-checkbox${errors[name] ? ' is-invalid' : ''}`}>
        <input type="checkbox" name={name} id={name} className="custom-control-input"
               ref={register(refProps)} {...rest}/>
        {children && <label className={`custom-control-label ${className}`} htmlFor={name}>{children}</label>}
      </div>
      {errors[name] && <div className={errClassName || 'invalid-feedback'}>{errors[name].message}</div>}
    </>
  )
}
