import React, { FunctionComponent } from 'react'
import { FormattedMessage } from 'react-intl'

interface props {
  isShow: boolean
  email: string
  handleClose: () => void
}

export const EmailVerifyModal: FunctionComponent<props> = ({ isShow, email, handleClose }) => {
  const emailDomain = email.split('@')[1] ?? email

  return (
    <>
      <div
        className={`modal fade ${isShow ? 'show' : ''}`}
        style={{ display: isShow ? 'block' : 'none' }}
        id='verification-email'
        tabIndex={-1}
        role='dialog'
        aria-hidden='true'
        onClick={handleClose}
      >
        <div className='modal-dialog verification-email modal-dialog-centered' onClick={(e) => e.stopPropagation()}>
          <div className='modal-content'>
            <div className='modal-header text-center'>
              <h3 className="modal-title">
                <FormattedMessage id="pa_41"/>
              </h3>
              <button type='button' className='close modal-close' data-dismiss='modal' onClick={handleClose}>
                <svg width='20' height='20' viewBox='0 0 20 20' fill='none'>
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M10.1308 9.07106L2.52941 1.46967L1.46875 2.53033L9.07015 10.1317L1.46875 17.7331L2.52941 18.7938L10.1308 11.1924L17.7322 18.7938L18.7929 17.7331L11.1915 10.1317L18.7929 2.53033L17.7322 1.46967L10.1308 9.07106Z'
                    fill='#777E8A'
                  />
                </svg>
              </button>
            </div>

            <div className='modal-body text-center'>
              <p className='modal-text'>
                <FormattedMessage id="pa_43_1"/>{' '}
                <a href={`//${emailDomain}`}>{email}</a>{' '}
                <FormattedMessage id="pa_43_2"/>
              </p>
              <a href={`//${emailDomain}`}>
                <button className="btn-reset btn-sq btn-green"><FormattedMessage id="pa_44"/></button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade ${isShow ? 'show' : ''}`}
           style={{ display: isShow ? 'block' : 'none' }}/>
    </>
  )
}
