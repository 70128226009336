import React from 'react'
import { useExchangeCartTotals } from '@usher/pe-client-front-end-lib'
import { CartTotals } from 'src/components/common/CartTotals'

type Props = {
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
}

export const ExchangeCartTotals = ({ fiatAmount, fiatCurrency, cryptoAmount, cryptoCurrency }: Props) => {
  const { wallet, ex_transaction_id, fee, rateAmount } = useExchangeCartTotals()

  return <CartTotals
    fiatAmount={fiatAmount}
    fiatCurrency={fiatCurrency}
    cryptoAmount={cryptoAmount}
    cryptoCurrency={cryptoCurrency}
    feePercentage={fee}
    rateAmount={rateAmount}
    transactionId={ex_transaction_id}
    wallet={wallet}
  />
}
