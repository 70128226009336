import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { SectionProps } from './SectionList'
import { SeeAll } from 'src/constants/SvgConstants'

export const FaqMainBlock = ({ section, count }: { section: SectionProps, count: number }) => {
  const intl = useIntl()

  if (!section)
    return null

  const { link, intl_id, items } = section
  return (
    <div className="faq__wrap">
      <h3 className="h3 faq__list-title">{intl.formatMessage({ id: intl_id })}</h3>
      <ul className="faq___item-list list">
        {items.slice(0, count).map((item, key) =>
          <li key={key} className="faq__item-items">
            <Link to={`/faq/${link}/${(key + 1)}`}
                  className="faq__item-link">{intl.formatMessage({ id: item.intl_id })}</Link>
          </li>
        )}
      </ul>
      <Link to={`/faq/${link}`} className="faq__see-all see__all-btns"><SeeAll/><FormattedMessage
        id="faq_see_all"
      /></Link>
    </div>
  )
}
