import React from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { useHeaderAuth } from '@usher/pe-client-front-end-lib'
import { CtrlSelect } from 'src/components/ctrl/Select'
import { useLang } from 'src/hooks/Lang'

export const HeaderAuth = () => {
  const { isAuthorized, onLogout } = useHeaderAuth()
  const { locale, langOptions, langOptionsXs, onLangChange } = useLang()

  return (
    <ul className="header__auth list ml-auto">
      <li>
        <CtrlSelect options={langOptions} value={locale} onChange={onLangChange} className="d-none d-sm-block"/>
        <CtrlSelect options={langOptionsXs} value={locale} onChange={onLangChange} className="d-block d-sm-none"/>
      </li>
      {isAuthorized ? (
        <>
          <li className="header__auth-item">
            <a href={`/${locale.toLowerCase().substring(0, 2)}/client/`}
               className="header__link header__link_login no-decorate"><FormattedMessage id="pa_106"/></a>
          </li>
          <li className="header__auth-item">
            <button className="header__link header__link_signup no-decorate" onClick={onLogout}><FormattedMessage
              id="logout"/></button>
          </li>
        </>
      ) : (
        <>
          <li className="header__auth-item">
            <Link to="/login" className="header__link header__link_login no-decorate">
              <FormattedMessage id="navlink4"/>
            </Link>
          </li>
          <li className="header__auth-item">
            <Link to="/sign_up" className="header__link header__link_signup no-decorate">
              <FormattedMessage id="navlink5"/>
            </Link>
          </li>
        </>
      )}
    </ul>
  )
}
