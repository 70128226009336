import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'react-bootstrap'
import { useExchangeStep1Verify } from '@usher/pe-client-front-end-lib'

type VerifyModalProps = {
  onHide: () => void
  onVerified: () => void
}

export const ExchangeStep1VerifyModal = ({ onHide, onVerified }: VerifyModalProps) => {
  const email = useExchangeStep1Verify(onVerified)

  return (
    <Modal onHide={onHide} show={true} backdrop="static" keyboard={false}
           dialogClassName="verification-email" centered>
      <Modal.Header closeButton>
        <Modal.Title><FormattedMessage id="pa_41"/></Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        <p className="modal-text">
          <FormattedMessage id="pa_43_1"/>
          {` `}
          <span className="as-link">{email}</span>
          {` `}
          <FormattedMessage id="pa_43_2"/>
        </p>
        <button className="btn-reset btn-sq btn-green">
          <FormattedMessage id="pa_44"/>
        </button>
      </Modal.Body>
    </Modal>
  )
}
