import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { CtrlCheckbox } from 'src/components/ctrl/Checkbox'

function GetLink(to: string) {
  return <Link to={`/${to}`} target="_blank" rel="noopener noreferrer"><FormattedMessage id={`exchange.${to}`}/></Link>
  // return <a href={`/${to}`} target="_blank" rel="noreferrer"><FormattedMessage id={`exchange.${to}`}/></a>
}

type Props = {
  name?: string,
  className?: string,
  errClassName?: string
}

export const ExchangeStepCheckboxes: FC<{ children?: any }> = ({ children }) => (
  <div className="buy-steps__actions">
    <div className="buy-steps__checkboxes">
      {children}
    </div>
  </div>
)

export const AgreeTerms = ({ name = 'agree_terms', className = '', errClassName = null }: Props) => {
  return (
    <CtrlCheckbox name={name} className={className} errClassName={errClassName} required>
      <FormattedMessage id="exchange.i_accept"/> {GetLink('terms_of_use')} <FormattedMessage
      id="and"/> {GetLink('privacy_policy')}
    </CtrlCheckbox>
  )
}

export const AcceptRisk = ({ name = 'agree_risk', className = '', errClassName = null }: Props) => {
  return (
    <CtrlCheckbox name={name} className={className} errClassName={errClassName} required>
      <FormattedMessage id="exchange.i_accept"/> {GetLink('risk_disclosure')}
    </CtrlCheckbox>
  )
}

export const AcceptRefund = ({ name = 'agree_refund', className = '', errClassName = null }: Props) => {
  return (
    <CtrlCheckbox name={name} className={className} errClassName={errClassName} required>
      <FormattedMessage id="exchange.i_accept"/> {GetLink('refund_policy')}
    </CtrlCheckbox>
  )
}

export const ConfirmAge = ({ name = 'agree_age', className = '', errClassName = null }: Props) => {
  return (
    <CtrlCheckbox name={name} className={className} errClassName={errClassName} required>
      <FormattedMessage id="exchange.over_age"/>
    </CtrlCheckbox>
  )
}
