import { Component } from 'react'
import { BaseShadowOutPage } from 'src/pages/base/BaseShadowOutPage'
import { PurchasesSection } from 'src/components/cards/PurchasesSection'
import { CardsContent } from 'src/components/cards/CardsContent'
import { WhyUsSection } from 'src/components/cards/WhyUsSection'
import { WalletsFaqSection } from 'src/components/cards/WalletsFaqSection'
import { DebitCardSection } from 'src/components/cards/DebitCardSection'

export class CardsPage extends Component {
  render = () => (
    <BaseShadowOutPage headerChild={<PurchasesSection />}>
      <CardsContent />
      <WhyUsSection />
      <WalletsFaqSection />
      <DebitCardSection />
    </BaseShadowOutPage>
  )
}
