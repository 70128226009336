import React, { InputHTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'
import { VALID_EMAIL } from '@usher/pe-client-front-end-lib'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  refProps?: object
  required?: boolean
  errClassName?: string
}

export const CtrlInput = ({
  type = 'text',
  name,
  className = '',
  refProps = {},
  required = false,
  errClassName = null,
  ...rest
}: InputProps) => {
  const intl = useIntl()
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: intl.formatMessage({ id: 'field_required' }) }
  if (type === 'email') refProps = { ...refProps, ...VALID_EMAIL }

  if (errors[name]) className += ' is-invalid'

  return (
    <>
      <input type={type} name={name} className={className} ref={register(refProps)} {...rest}/>
      {errors[name] && <div className={errClassName || 'invalid-feedback'}>{errors[name].message}</div>}
    </>
  )
}
