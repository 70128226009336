import React from 'react'
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom'
import { Image } from 'src/components/common/Image'

export const ReviewsInfo = () => {
  return (
    <div className="reviews__info">
      <Link to="/contacts" className="btn-reset btn-sq btn-green reviews__open-modal no-decorate"><FormattedMessage
      id="rp_5"
      /></Link>
      <div className="reviews__rating">
        <span className="reviews__rating-text">
        <FormattedMessage
        id="rp_2"
        values={{ text: <span>4.9</span> }}
        /></span>
        <div className="reviews__rating-info">
          <Image src="stars-green.svg" alt="Stars" className="reviews__rating-pic"/>
          <span className="reviews__rating-des">
            <FormattedMessage
      id="rp_4"
      /></span>
        </div>
      </div>
    </div>
  )
}
