import { Component } from 'react'
import { FormattedMessage } from "react-intl";

export class OffersSection extends Component {
  render = () => (
    <section className='invite-offers'>
      <div className='container'>
        <div className='row text-center'>
          <div className='col-lg-4 d-flex align-items-center justify-content-center text-center'>
            <div className='invite-offers__wrap'>
              <div className='invite-offers__img-box'>
                <img src='/img/invite.svg' alt='icon' className='invite-offers__icon' />
              </div>
              <p className='invite-offers__description'>
                <FormattedMessage
                  id="af_4"
                  />
                </p>
              <span className='invite-offers__text'>01</span>
            </div>
          </div>
          <div className='col-lg-4 d-flex align-items-center justify-content-center text-center'>
            <div className='invite-offers__wrap'>
              <div className='invite-offers__img-box'>
                <img src='/img/spend.svg' alt='icon' className='invite-offers__icon' />
              </div>
              <p className='invite-offers__description'><FormattedMessage
                  id="af_5"
                  /></p>
              <span className='invite-offers__text'>02</span>
            </div>
          </div>
          <div className='col-lg-4 d-flex align-items-center justify-content-center text-center'>
            <div className='invite-offers__wrap'>
              <div className='invite-offers__img-box'>
                <img src='/img/get.svg' alt='icon' className='invite-offers__icon' />
              </div>
              <p className='invite-offers__description'><FormattedMessage
                  id="af_6"
                  /></p>
              <span className='invite-offers__text'>03</span>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-12 text-center'>
            <a className='invite-offers__link no-decorate link-green' href='#affiliat-form'>
            <FormattedMessage
                  id="af_7"
                  />
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}
