import { Component } from 'react'
import { FormattedMessage } from "react-intl";

export class ReferalSection extends Component {
  render = () => (
    <section className='referral-program'>
      <div className='referral-program__wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 text-center'>
              <h3 className='h2 referral-program__title'>
              <FormattedMessage
                id="af_22"
                /></h3>
              <p className='referral-program__description'><FormattedMessage
                id="af_23"
                /></p>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='referral-program__button d-flex'>
                <a href='#affiliat-form' className='referral-program__btn btn-nm btn-octagon no-decorate btn-reset btn-sq btn-green text-center'>
                <FormattedMessage
                id="af_24"
                />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
