import React, { FC } from 'react'
import { FormattedMessage } from 'react-intl'

export const RecoveryContent: FC<{ children?: any }> = ({ children }) => {
  return (
    <section className="user-form user-login">
      <div className="container">
        <div className="user-form__header user-form__header-login">
          <div className="row align-items-baseline">
            <div className="col-md-12">
              <h2 className="h2 user-form__header-title"><FormattedMessage id="ep_25"/></h2>
            </div>
          </div>
        </div>
        <div className="user-form__form user-form-login octagon octagon-wht">
          {children}
        </div>
      </div>
    </section>
  )
}
