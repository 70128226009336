import React from 'react'
import { FormattedMessage } from 'react-intl'
import { AskPanel } from 'src/components/common/AskPanel'

export const QuestionsSection = () => {
  return (
    <section className="asked-questions shadows">
      <div className="asked-questions__box">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <h2 className="h2 asked-questions__title">
                <FormattedMessage id="ask_title.frequently"/>{' '}
                <FormattedMessage id="ask_title.questions" tagName="span"/>
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <AskPanel title_id="af_26" isOpened i="1">
                <FormattedMessage id="af_27"/>
              </AskPanel>

              <AskPanel title_id="af_28" i="2">
                <FormattedMessage id="af_29"/>
              </AskPanel>

              <AskPanel title_id="af_30" i="3">
                <FormattedMessage id="af_31"/>
              </AskPanel>

              <AskPanel title_id="af_32" i="4">
                <FormattedMessage id="af_33"/>
              </AskPanel>

              <AskPanel title_id="af_34" i="5">
                <FormattedMessage id="af_35"/>
              </AskPanel>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
