import { Logo } from "src/components/common/Logo"
import { Stepper } from "./components/Stepper"
import { BackButton } from "./components/BackButton"
import { Suspense, lazy, useState } from "react"
import { useHistory } from "react-router-dom"

const ByCrypto = lazy(async () => await import("./steps/ByCrypto"))
const Confirmation = lazy(async () => await import("./steps/Confirmation"))
const Instruction = lazy(async () => await import("./steps/Instruction"))
const Status = lazy(async () => await import("./steps/Status"))


const Steps = {
  1: <ByCrypto />,
  2: <Confirmation />,
  3: <Instruction />,
  4: <Status />
}
export const TopUpCard = () => {
  const history = useHistory()
  const [step, setStep] = useState(1)
  return (
    <main>
      <Suspense fallback="Loading...">
        <section className={`buy-steps ${step < 4 ? "buy-steps--blue" : "'"}`}>
          <div className="buy-steps__wrapper shadows">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 text-center">
                  <Logo imgClassName="buy-steps__logo img-fluid" />
                  <h2 className="h2 buy-steps__h2">Top Up Card</h2>
                  <Stepper currentStep={1} defaultActive />
                </div>
              </div>
              {Steps[step]}
              {step < 4 && <div className="bottom-line-btn d-flex justify-content-between align-items-center">

                <BackButton onClick={() => {
                  if (step > 1) setStep((old) => old - 1)
                    ; else history.push("/")
                }} />
                <button className="btn-reset btn-sq btn-long btn-green" onClick={() => {
                  if (step < 7) setStep((old) => old + 1)
                }} >
                  Next
                </button>
              </div>}
            </div>
          </div>
        </section>

      </Suspense>
    </main>

  )
}
