import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Alert } from 'react-bootstrap'
import { ExClient } from '@usher/pe-client-front-end-lib'
import { CtrlInput } from 'src/components/ctrl/Input'
import { ExchangeStepItem } from 'src/components/exchange/StepItem'

export const ExchangeStep1Wallet = ({ hasWallet }: { hasWallet: boolean }) => {
  const intl = useIntl()
  if (!ExClient.shop_id && !hasWallet) return null

  return (
    <>
      <ExchangeStepItem label={intl.formatMessage({ id: 'pa_104' })}>
        <CtrlInput name="wallet" className="input" required/>
      </ExchangeStepItem>
      {ExClient.shop_id && (
        <Alert variant="warning" className="alert-wallet">
          <FormattedMessage id="pa_29"/>
        </Alert>
      )}
    </>
  )
}
