import React from 'react'
import { RecoveryContent } from 'src/components/recovery'
import { ConfidenceSection } from 'src/components/common/ConfidenceSection'
import { BasePage } from 'src/pages/base/BasePage'
import { RecoveryNewPassword } from 'src/components/recovery/NewPassword'

export const PasswordSetPage = () => {
  return (
    <BasePage headerType="login" headerChild={<RecoveryContent><RecoveryNewPassword/></RecoveryContent>}>
      <main>
        <ConfidenceSection/>
      </main>
    </BasePage>
  )
}
