const STEPS = [
  "Top-Up Card By Crypto",
  "Payment Confirmation",
  "Bitcoin Instructions",
  "Transaction Status",
]

interface IStepperProps {
  currentStep: number
  defaultActive?: boolean
}
const getStepClass = (currentStep: number, step: number) => {
  if (step === currentStep) return "active"
  if (step < currentStep) return "done"
  if (step + 1 === currentStep) return "next"
}

export const Stepper = ({ currentStep, defaultActive }: IStepperProps) => {
  return (
    <ul className="buy-steps__line_tu list">
      {STEPS.map((step, index) => (
        <li
          key={index}
          className={`buy-steps__line_tu-item ${getStepClass(currentStep, index + 1) ?? defaultActive
              ? "active"
              : ""
            }`}
        >
          <span>{step}</span>
        </li>
      ))}
    </ul>
  )
}
