import React from 'react'
import { useIntl } from 'react-intl'
import { useRouteMatch, useLocation } from 'react-router-dom'
import { EXCHANGE_STEPS } from 'src/constants/ExchangeConstants'

export const ExchangeStepsLine = () => {
  const intl = useIntl()
  const { path } = useRouteMatch()
  const { pathname } = useLocation()
  let done = (path !== pathname) ? 'done' : ''

  return (
    <ul className="buy-steps__line list">
      {Object.entries(EXCHANGE_STEPS).map(([key, { route, title, intl_id }]) => {
        let active = ''
        if (pathname === `${path}/${route}`) {
          done = ''
          active = 'active'
        }
        return (
          <li key={key} className={`buy-steps__line-item ${done} ${active}`}>
            <span>{intl.formatMessage({ id: intl_id, defaultMessage: title })}</span>
          </li>
        )
      })}
    </ul>
  )
}
