import React from 'react'
import { FormattedMessage } from "react-intl"
import { Image } from 'src/components/common/Image'
import { ArrowDown, ArrowUp } from 'src/constants/SvgConstants'

type Props = {
  rate: number
  change: number
  symbol: string
  currency: string
  onBuyNow: (symbol: string) => void
}

export const RateItem = ({ rate, change, symbol, currency, onBuyNow }: Props) => {
  return (
    <li className="rates__list-item d-flex align-items-sm-center">
      <div className="rates__list-crypto d-flex align-items-center">
        <Image src={`currency/${symbol.toLowerCase()}.svg`} className="rates__crypto-icon"/>
        <p className="rates__crypto-text">
          <span>1 {symbol}</span> = {currency === 'USD' ? '$' : '€'}{rate}
        </p>
      </div>
      <div className="rates__list-procent d-flex align-items-center">
        {change >= 0 ? <ArrowUp/> : <ArrowDown/>}
        <p className={`rates-procent-text${change >= 0 ? ' procent-green' : ''}`}>{change.toFixed(2)}%</p>
      </div>
      <div className="rates__list-schedule">
        <Image src="ctypro-schedule.png" className="rates__shedule-image"/>
      </div>
      <button
        type="button"
        onClick={() => onBuyNow(symbol)}
        className="rates__btn no-decorate btn-reset btn-sq btn-green"><FormattedMessage
        id="rates_btn"
      />
      </button>
    </li>
  )
}
