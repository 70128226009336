import React from 'react'
import { useIntl } from 'react-intl'

type TotalsProps = {
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
  feePercentage: number
  rateAmount: number
  wallet?: string
  transactionId?: number
}

function InfoRow({ label, value }: { label: string, value: string }) {
  return (
    <div className="buy-steps__info-row">
      <span className="buy-steps__info-rate">{label}:</span>
      <span className="buy-steps__info-val">{value}</span>
    </div>
  )
}

export const CartTotals = ({
  fiatAmount,
  fiatCurrency,
  cryptoAmount,
  cryptoCurrency,
  transactionId,
  rateAmount,
  feePercentage,
  wallet,
}: TotalsProps) => {
  const intl = useIntl()
  const fiatAmt = parseFloat(fiatAmount) || 0
  const cryptoAmt = parseFloat(cryptoAmount) || 0
  const feeAmount = fiatAmt / 100 * feePercentage

  return (
    <div className="buy-steps__info-full">
      {transactionId && (
        <InfoRow label={intl.formatMessage({ id: 'exchange.trans_id' })} value={transactionId.toString()}/>
      )}
      <InfoRow label={intl.formatMessage({ id: 'exchange.rate' })} value={`${rateAmount.toFixed(2)} ${fiatCurrency}`}/>
      <InfoRow label={intl.formatMessage({ id: 'exchange.fee' })} value={`${feeAmount.toFixed(2)} ${fiatCurrency}`}/>
      <InfoRow label={intl.formatMessage({ id: 'exchange.total_spent' })}
               value={`${(fiatAmt + feeAmount).toFixed(2)} ${fiatCurrency}`}/>
      <InfoRow label={intl.formatMessage({ id: 'exchange.total_received' })}
               value={`${cryptoAmt.toFixed(6)} ${cryptoCurrency}`}/>
      {wallet && (
        <InfoRow label={intl.formatMessage({ id: 'pa_104' }, { cryptoCurrency })} value={wallet}/>
      )}
    </div>
  )
}
