import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CtrlInput, InputProps } from 'src/components/ctrl/Input'

interface Props extends InputProps {
  label_id: string
}

export const CtrlInputWrap = ({ label_id, ...rest }: Props) => {
  return (
    <div className="user-form__item">
      <label className="label"><FormattedMessage id={label_id}/></label>
      <CtrlInput className="input input-brdr" errClassName="error" {...rest}/>
    </div>
  )
}
