import React, { TextareaHTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import { useFormContext } from 'react-hook-form'


export interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  refProps?: object
  required?: boolean
  errClassName?: string
}

export const CtrlTextArea = ({

  name,
  className = '',
  refProps = {},
  required = false,
  errClassName = null,
  ...rest
}: TextAreaProps) => {
  const intl = useIntl()
  const { register, errors } = useFormContext()

  if (required) refProps = { ...refProps, required: intl.formatMessage({ id: 'field_required' }) }


  if (errors[name]) className += ' is-invalid'

  return (
    <>
      <textarea name={name} className={className} ref={register(refProps)} {...rest}/>
      {errors[name] && <div className={errClassName || 'invalid-feedback'}>{errors[name].message}</div>}
    </>
  )
}
