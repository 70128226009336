import React from 'react'
import { Image } from 'src/components/common/Image'

type Props = {
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
}

export const CartExchange = ({ fiatAmount, fiatCurrency, cryptoAmount, cryptoCurrency }: Props) => {
  const fiatAmt = parseFloat(fiatAmount) || 0
  const cryptoAmt = parseFloat(cryptoAmount) || 0

  return (
    <div className="buy-steps__change-info">
      <span className="buy-steps__from">
        {fiatAmt.toFixed(2)}
        <span className="buy-steps__name">{fiatCurrency}</span>
      </span>
      <Image src="exchange.svg" className="buy-steps__exchange-ic"/>
      <span className="buy-steps__to">
        {cryptoAmt.toFixed(6)}
        <span className="buy-steps__name">{cryptoCurrency}</span>
      </span>
    </div>
  )
}
