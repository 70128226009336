import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { LANGUAGES } from 'src/constants/LangConstants'

const capitalize = (s: string) => s[0].toUpperCase() + s.slice(1)

export const useLang = () => {
  const { locale } = useIntl()

  const onLangChange = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    const { pathname, search } = window.location
    window.location.href = (pathname + search)
      .replace(`/${locale.toLowerCase().substring(0, 2)}`, `/${e.target.value.toLowerCase().substring(0, 2)}`)
  }, [locale])

  return {
    locale,
    onLangChange,
    langOptions: LANGUAGES.map(({ code: value, label }) => ({ label, value })),
    langOptionsXs: LANGUAGES.map(({ code: value }) => ({ label: capitalize(value.substring(0, 2)), value }))
  }
}
