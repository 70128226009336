import { Component } from 'react'

export class ApiSection extends Component {
  render = () => (
    <section className='api'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 api__title text-center'>API Services</h2>
            <p className='api__title-description text-center'>For developers and their applications</p>
            <h3 className='h2 api__title-traders text-center'>API for traders: a tool for success</h3>
            <p className='api__title-traders-description text-center'>
              We offer a powerful yet stable matching engine, allowing to process fast execution along with almost immediate withdrawals. So it is
              natural that we have preferred Bitcoin Exchange for European Bitcoin ATM operators to process the workflow and trade operations.
            </p>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='api-traders__wrapper text-center'>
              <div className='api-traders__icon-wrap d-flex align-items-center justify-content-center'>
                <img src='/img/api-integration.svg' alt='icon' className='api-traders__icon' />
              </div>
              <h4 className='h4 api-traders__title'>API Integration</h4>
              <p className='api-traders__description'>
                The API, or Application Programming Interface we offer is developed according to the customers needs, including optimization for
                ultra-low latency connections as well. One of the huge advantages you can enjoy is the code samples in the most popular and widely
                used programming languages, such as PHP, Java, Python, Ruby, Perl, Go, C#, Groovy. But it’s not all the pros. You will get a chance to
                try a mock server as well.
              </p>
              <a className='api-traders__button no-decorate' href='/'>
                Go to Apiary
              </a>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='api-traders__wrapper text-center'>
              <div className='api-traders__icon-wrap d-flex align-items-center justify-content-center'>
                <img src='/img/xchange-java-library.svg' alt='icon' className='api-traders__icon' />
              </div>
              <h4 className='h4 api-traders__title'>XChange Java library</h4>
              <p className='api-traders__description'>
                We use XChange, a proficient Java library, which is famous for streamlining API of 50+ Bitcoin and Altcoin exchanges. It has
                interface, perfect for trading and exchanges. You can check it on your own, visiting a Github page.
              </p>
              <a className='api-traders__button no-decorate' href='/'>
                Go to Github
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
