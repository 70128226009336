import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { VALID_NAME, VALID_PHONE } from '@usher/pe-client-front-end-lib'
import { ExchangeBodyCaption } from 'src/components/exchange/BodyCaption'
import { CtrlInput } from 'src/components/ctrl/Input'
import { ExchangeStepItem } from 'src/components/exchange/StepItem'
import { CtrlRadio } from 'src/components/ctrl/Radio'

export const ExchangeStep2PersonalInfo = () => {
  const intl = useIntl()

  return (
    <div className="buy-steps__personal">
      <ExchangeBodyCaption title={intl.formatMessage({ id: 'pa_52' })}/>
      <div className="row">
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_53' })}>
            <CtrlInput type="text" name="first_name" className="input" required refProps={VALID_NAME}/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_54' })}>
            <CtrlInput type="text" name="last_name" className="input" required refProps={VALID_NAME}/>
          </ExchangeStepItem>
        </div>
      </div>
      <div className="row align-items-center">
        <div className="col-lg-6">
          <ExchangeStepItem label={intl.formatMessage({ id: 'pa_55' })}>
            <CtrlInput type="text" name="phone" className="input" required refProps={VALID_PHONE} maxLength={18}/>
          </ExchangeStepItem>
        </div>
        <div className="col-lg-6">
          <div className="buy-steps__item-radiobtns">
            <CtrlRadio name="sex" value="1" required><FormattedMessage id="pa_56"/></CtrlRadio>
            <CtrlRadio name="sex" value="0" required><FormattedMessage id="pa_57"/></CtrlRadio>
          </div>
        </div>
      </div>
    </div>
  )
}
