import React, { ImgHTMLAttributes } from 'react'

interface ImageProps extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  className?: string
}

export const Image = ({ src, alt = '', className }: ImageProps) =>
  <img src={`${process.env.PUBLIC_URL}/img/${src}`} alt={alt} className={className}/>
