import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CtrlTextArea, TextAreaProps } from 'src/components/ctrl/TextArea'

interface Props extends TextAreaProps {
  label_id: string
}

export const CtrlTextAreaWrap = ({ label_id, ...rest }: Props) => {
  return (
    <div className="user-form__item user-form__item-message">
      <label className="label"><FormattedMessage id={label_id}/></label>
      <CtrlTextArea className="input input-brdr user-form__textarea" errClassName="error" {...rest}/>
    </div>
  )
}
