import React from 'react'
import { Link, NavLink, useParams } from 'react-router-dom'
import { PagerNext, PagerPrev } from 'src/constants/SvgConstants'

const pages = 9
const path = '/reviews'

export const ReviewsPager = () => {
  const page = parseInt(useParams()['page'])

  return (
    <div className="rates-paginator">
      <ul className="rates-paginator__list list d-flex flex-row justify-content-center">
        {!isNaN(page) && (
          <li className="rates-paginator__item">
            <Link to={`${path}${(page - 1) > 1 ? `/page/${page - 1}` : ''}`}
                  className="rates-paginarot-link"><PagerPrev/></Link>
          </li>
        )}
        {[...Array(pages)].map((x, i) => {
            const to = i === 0 ? path : `${path}/page/${i + 1}`
            return (
              <li className="rates-paginator__item" key={i}>
                <NavLink
                  to={to}
                  exact={true}
                  activeClassName="disabled"
                  className="rates-paginarot-link no-decorate link-green">{i + 1}</NavLink>
              </li>
            )
          }
        )}
        {(isNaN(page) || page < pages) && (
          <li className="rates-paginator__item">
            <Link to={`${path}/page/${isNaN(page) ? `2` : page + 1}`}
                  className="rates-paginarot-link no-decorate link-green"><PagerNext/></Link>
          </li>
        )}
      </ul>
    </div>
  )
}
