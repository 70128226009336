import ReactDOM from 'react-dom'
import { App } from 'src/App'
import reportWebVitals from 'src/reportWebVitals'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.info))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
