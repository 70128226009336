import React from 'react'
import { HowCalculated } from 'src/components/common/HowCalculated'
import { ExchangeCartTimer } from './Timer'
import { ExchangeCartTotals } from './Totals'
import { CartExchange } from 'src/components/common/CartExchange'

type Props = {
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
}

export const ExchangeCart = ({ fiatAmount, fiatCurrency, cryptoAmount, cryptoCurrency }: Props) => {
  return (
    <>
      <CartExchange
        fiatAmount={fiatAmount}
        fiatCurrency={fiatCurrency}
        cryptoAmount={cryptoAmount}
        cryptoCurrency={cryptoCurrency}
      />
      <ExchangeCartTimer/>
      <HowCalculated className="buy-steps__link-recalc"/>
      <ExchangeCartTotals
        fiatAmount={fiatAmount}
        fiatCurrency={fiatCurrency}
        cryptoAmount={cryptoAmount}
        cryptoCurrency={cryptoCurrency}
      />
    </>
  )
}
