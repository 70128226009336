import React from "react"
import { FormattedMessage } from "react-intl"
import { useLocation } from "react-router-dom"
import { useExchangePage } from "@usher/pe-client-front-end-lib"
import { Logo } from "src/components/common/Logo"
import { ExchangeStepsLine } from "src/components/exchange/StepsLine"
import { Exchange } from "src/components/exchange"
import { ExchangeCart } from "src/components/exchange/Cart"
import { ExchangeStep5Cart } from "src/components/exchange/Step5/Cart"
import { CtrlSelect } from "src/components/ctrl/Select"
import { useLang } from "src/hooks/Lang"

export const ExchangePage = () => {
  const { pathname } = useLocation()
  const {
    isInitialized,
    isStep5,
    fiatAmount,
    fiatCurrency,
    cryptoAmount,
    cryptoCurrency,
  } = useExchangePage({ pathname })
  const { locale, langOptions, onLangChange } = useLang()

  return (
    <main>
      <section className="buy-steps">
        <div className="buy-steps__wrapper shadows">
          <div className="container">
            <div className="text-center">
              <Logo imgClassName="buy-steps__logo img-fluid" />
              <CtrlSelect
                options={langOptions}
                value={locale}
                onChange={onLangChange}
                className="exchange-lang"
              />
              <h2 className="h2 buy-steps__h2">
                <FormattedMessage id="exchange.title" />
              </h2>
              <ExchangeStepsLine />
            </div>
            {isInitialized && (
              <div className="buy-steps__form row">
                <div className="col-lg-5">
                  <Exchange />
                </div>
                <div className="offset-lg-2 col-lg-5">
                  <div className="buy-steps__info">
                    <div className="buy-steps__info-top d-flex justify-content-between">
                      <h3 className="h3 buy-steps__caption">
                        <FormattedMessage id="exchange.cart" />
                      </h3>
                      <p className="buy-steps__ssl">
                        <FormattedMessage id="exchange.ssl_secure" />
                      </p>
                    </div>
                    {isStep5 ? (
                      <ExchangeStep5Cart />
                    ) : (
                      <ExchangeCart
                        fiatAmount={fiatAmount}
                        fiatCurrency={fiatCurrency}
                        cryptoAmount={cryptoAmount}
                        cryptoCurrency={cryptoCurrency}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </main>
  )
}
