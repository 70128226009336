import React from 'react'
import { useIntl } from 'react-intl'
import { FormattedMessage } from 'react-intl'
import { useAvailableCountry } from '@usher/pe-client-front-end-lib'

export const RegisterSection = () => {
  const intl = useIntl()
  const { code, isEnabled } = useAvailableCountry()

  const available = intl.formatMessage({ id: `${!isEnabled ? 'not_' : ''}available_in` })

  return (
    <section className='available-main'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='available-main__wrapper'>
              <h2 className='h2 available-main__title'>
                <FormattedMessage id="fp_1" values={{ text: <span>than 160 countries</span> }}/>
              </h2>
              <p className='available-main__info'>
                {code !== '' ? `Wallbitex ${available} ${code}` : ''}
              </p>
              <form>
                <div className='available-main__form d-flex align-items-center'>
                  <input type="email" className='input input-brdr available-main__input'
                         placeholder={intl.formatMessage({ id: 'fp_3' })}/>
                  <button type='submit' className='btn-reset btn-sq btn-green available-main__butt'>
                    <FormattedMessage id="quick_btn"/>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
