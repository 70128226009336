import { FunctionComponent } from 'react'
import { FormattedMessage } from "react-intl"
import { ExchangeRatesFiat } from 'src/components/about/ExchangeRatesFiat'
import { ExchangeRatesCrypto } from 'src/components/about/ExchangeRatesCrypto'

export const AssetsSection: FunctionComponent = () => (
  <section className='suported-assets shadows'>
    <div className='container'>
      <div className='row'>
        <div className='col-lg-12 text-center'>
          <h2 className='h2 suported-assets__title'><FormattedMessage
                id="at_12"
              /></h2>
          <p className='suported-assets__description'><FormattedMessage
                id="at_13"
              /></p>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-6 d-flex'>
          <div className='suported-assets__wrapper suported-assets__wrapper_fiat octagon octagon-wht w-100'>
            <ExchangeRatesFiat
              additionalRowComponent={
                <li className='suported-assets__item d-flex'>
                  <div className='suported-assets__cripto-left d-flex'>
                    <img src='/img/curr/bitcoin-cash.svg' alt='' className='suported-assets__cripto-icon-duable' />
                    <img src='/img/curr/xrp.svg' alt='' className='suported-assets__cripto-icon' />
                  </div>
                  <img src='/img/to-arrow.svg' alt='arrow' className='suported-assets__cripto-img suported-crypto' />
                  <div className='suported-assets__cripto-right d-flex'>
                    <div className='suported-assets__cripto-cent coming-soon'>
                      <span className='suported-assets__cripto-name d-block'>Coming soon</span>
                    </div>
                  </div>
                </li>
              }
            />
          </div>
        </div>
        <div className='col-lg-6 d-flex'>
          <div className='suported-assets__wrapper octagon octagon-wht w-100'>
            <ExchangeRatesCrypto />
          </div>
        </div>
      </div>
    </div>
  </section>
)
