import { Component } from 'react'
import { BasePage } from 'src/pages/base/BasePage'
import { ConfidenceSection } from 'src/components/common/ConfidenceSection'
import SignupForm from 'src/components/signup/SignupForm'

export class SignupPage extends Component {
  render = () => (
    <BasePage headerType={'login'} headerChild={<SignupForm />}>
      <main>
        <ConfidenceSection />
      </main>
    </BasePage>
  )
}
