import React from 'react'
import { BaseShadowPage } from 'src/pages/base/BaseShadowPage'
import { ContactsForm } from 'src/components/contacts/ContactsForm'
import { ContactsList } from 'src/components/contacts/ContactsList'

export const ContactsPage = () => {
  return (
    <BaseShadowPage>
      <ContactsForm/>
      <ContactsList/>
    </BaseShadowPage>
  )
}
