import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ExchangeStepSubmit = () => {
  return (
    <div className="buy-steps__btns d-flex justify-content-end">
      <a href="/" className="btn-reset btn-sq btn-sq-br buy-steps__butt-cancel">
        <FormattedMessage id="pa_70"/>
      </a>
      <button type="submit" className="btn-reset btn-sq btn-green buy-steps__butt">
        <FormattedMessage id="pa_71"/>
      </button>
    </div>
  )
}
