import React from 'react'

export const ReviewsPage4 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            I have worked with several exchangers, tried this and that, and the company managed to surprise me.
          </p>
          <span className='reviews__row-autor'>Jerad J.</span>
          <span className='reviews__row-data'>13 Jan, 2021 at 08:35 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Spain</span>
          <img src='/img/countries/europe/spain.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good!</p>
          <span className='reviews__row-autor'>Lion M.</span>
          <span className='reviews__row-data'>13 Jan, 2021 at 09:21 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Georgia</span>
          <img src='/img/countries/europe/georgia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Artur L.</span>
          <span className='reviews__row-data'>13 Jan, 2021 at 15:44 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>UK</span>
          <img src='/img/countries/europe/united-kingdom.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Konor G.</span>
          <span className='reviews__row-data'>13 Jan, 2021 at 18:48 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>UK</span>
          <img src='/img/countries/europe/united-kingdom.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Sara D.</span>
          <span className='reviews__row-data'>13 Jan, 2021 at 19:59 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>UK</span>
          <img src='/img/countries/europe/united-kingdom.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>THX GUYS</p>
          <span className='reviews__row-autor'>Alice F.</span>
          <span className='reviews__row-data'>14 Jan, 2021 at 06:33 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>France</span>
          <img src='/img/countries/europe/france.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good and reliable exchange!</p>
          <span className='reviews__row-autor'>Piter K.</span>
          <span className='reviews__row-data'>14 Jan, 2021 at 10:52 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Greece</span>
          <img src='/img/countries/europe/greece.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
