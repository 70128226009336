import React, { FunctionComponent } from 'react'
import { useIntl, IntlShape } from 'react-intl'

export interface ExchangeRateProps {
  from_cur: string
  from_cur_title: string
  from_rate?: number
  rate: number
  to_cur: string
  toCyrImgIsShow?: boolean
  hide_from_cur?: boolean
}

function getTitle(intl: IntlShape, title: string): string {
  switch (title) {
    case 'United States Dollar':
      return intl.formatMessage({ id: 'at_16' })
    case 'Tether USD on the Tron network':
      return intl.formatMessage({ id: 'at_17' })
    default:
      return title
  }
}

export const ExchangeRate: FunctionComponent<ExchangeRateProps> = (props) => {
  const intl = useIntl()
  const { from_cur, from_cur_title, from_rate = 1, rate, to_cur, hide_from_cur, toCyrImgIsShow } = props

  return (
    <li className='suported-assets__item d-flex'>
      <div className='suported-assets__cripto-left d-flex'>
        <img src={'/img/currency/' + from_cur.toLowerCase() + '.svg'} alt={from_cur}
             className='suported-assets__cripto-icon'/>
        <div className='suported-assets__cripto-cent'>
            <span className='suported-assets__cripto-name d-block'>
              {from_rate} {!hide_from_cur && from_cur.toUpperCase()}
            </span>
          <span className='suported-assets__cripto-description d-block'>{getTitle(intl, from_cur_title)}</span>
        </div>
      </div>
      <img src='/img/to-arrow.svg' alt='arrow' className='suported-assets__cripto-img suported-crypto'/>
      <div className='suported-assets__cripto-right d-flex'>
        {toCyrImgIsShow && <img src={'/img/currency/' + to_cur.toLowerCase() + '.svg'} alt={to_cur}
                                className='suported-assets__cripto-icon'/>}
        <div className='suported-assets__cripto-cent'>
          <span className='suported-assets__cripto-name d-block'>{rate}</span>
          <span className='suported-assets__cripto-description d-block'>{to_cur.toUpperCase()}</span>
        </div>
      </div>
    </li>
  )
}
