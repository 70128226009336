import React from 'react'

export const ReviewsPage7 = () => {
  return (
    <>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>
            I have worked with several exchangers, tried this and that, and the company managed to surprise me.
          </p>
          <span className='reviews__row-autor'>Martin Y.</span>
          <span className='reviews__row-data'>17 Jan, 2021 at 11:11 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Finland</span>
          <img src='/img/countries/europe/finland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/ethereum-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>The service is always fast and works good!</p>
          <span className='reviews__row-autor'>Beatris K.</span>
          <span className='reviews__row-data'>17 Jan, 2021 at 13:38 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Bhutan</span>
          <img src='/img/countries/asia/bhutan.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/litecoin-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Cool service. +++++++</p>
          <span className='reviews__row-autor'>Isaak C.</span>
          <span className='reviews__row-data'>18 Jan, 2021 at 14:47 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Bulgaria</span>
          <img src='/img/countries/europe/bulgaria.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/dollar-gr.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Good service. Have no idea what to add more.</p>
          <span className='reviews__row-autor'>Selia V.</span>
          <span className='reviews__row-data'>19 Jan, 2021 at 15:32 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Georgia</span>
          <img src='/img/countries/europe/georgia.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/sepa.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Ok, ok, you are awesome. What about discount?</p>
          <span className='reviews__row-autor'>Paul J.</span>
          <span className='reviews__row-data'>19 Jan, 2021 at 16:18 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Ireland</span>
          <img src='/img/countries/europe/ireland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/tether-big.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>THX GUYS</p>
          <span className='reviews__row-autor'>Andre X.</span>
          <span className='reviews__row-data'>19 Jan, 2021 at 18:18 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Finland</span>
          <img src='/img/countries/europe/finland.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
      <div className='reviews__item'>
        <div className='reviews__row-main'>
          <div className='reviews__row-rating'>
            <img src='/img/stars.svg' alt='' className='reviews__rating-ic'/>
            <div className='reviews__exchange'>
              <img src='/img/curr/bitcoin-cash.svg' alt='' className='reviews__exchange-ic'/>
              <img src='/img/arrow-curr.svg' alt='' className='reviews__exchange-arr'/>
              <img src='/img/bitcoin_l.svg' alt='' className='reviews__exchange-ic'/>
            </div>
          </div>
          <p className='reviews__row-text'>Very good and reliable exchange!</p>
          <span className='reviews__row-autor'>Alex V.</span>
          <span className='reviews__row-data'>20 Jan, 2021 at 20:18 PM</span>
        </div>
        <div className='reviews__row-country'>
          <span className='reviews__country-name'>Greece</span>
          <img src='/img/countries/europe/greece.svg' alt='' className='reviews__country-ic'/>
        </div>
      </div>
    </>
  )
}
