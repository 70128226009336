import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { FormProvider } from 'react-hook-form'
import { useAffiliateForm, VALID_NAME, VALID_PHONE } from '@usher/pe-client-front-end-lib'
import { CtrlInput } from 'src/components/ctrl/Input'
import { CtrlSelectCountry } from 'src/components/ctrl/SelectCountry'
import { AffiliateFormSelectStyles } from 'src/components/affiliate/AffiliateFormSelectStyles'
import { CtrlCaptcha } from 'src/components/ctrl/Captcha'

const inputClass = 'affiliate-form__input text-center'

export const AffiliateForm = () => {
  const intl = useIntl()
  const { formMethods, onSubmit, captchaRef, onCaptchaChange } = useAffiliateForm()

  return (
    <section className="affiliate-form shadows" id="affiliate-form">
      <div className="container">
        <h2 className="h2 affiliate-form__title text-center">
          <FormattedMessage id="af_36_1"/>{' '}
          <FormattedMessage id="af_36_2" tagName="span"/>
        </h2>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)} id="affiliat-form">
            <div className="affiliate-form__wrapper">
              <div className="affiliate-form__wrap">
                <h3 className="h3 affiliate-form__wrapper-title text-center">
                  <FormattedMessage id="af_37"/>
                </h3>
                <div className="row">
                  <div className="col-lg-6">
                    <CtrlInput
                      name="first_name" className={inputClass} placeholder={intl.formatMessage({ id: 'af_38' })} required
                      refProps={VALID_NAME}/>
                  </div>
                  <div className="col-lg-6">
                    <CtrlInput
                      name="last_name" className={inputClass} placeholder={intl.formatMessage({ id: 'af_39' })} required
                      refProps={VALID_NAME}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <CtrlInput
                      name="email" type="email" className={inputClass} required
                      placeholder={intl.formatMessage({ id: 'af_40' })}/>
                  </div>
                  <div className="col-lg-6">
                    <CtrlInput name="skype" className={inputClass} placeholder={intl.formatMessage({ id: 'af_41' })}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <CtrlSelectCountry customStyles={AffiliateFormSelectStyles} required/>
                  </div>
                  <div className="col-lg-6">
                    <CtrlInput name="phone" className={inputClass}
                               placeholder={intl.formatMessage({ id: 'af_42' })} required refProps={VALID_PHONE}
                               maxLength={18}/>
                  </div>
                </div>
              </div>
              <div className="affiliate-form__wrap">
                <h3 className="h3 affiliate-form__wrapper-title text-center">
                  <FormattedMessage id="af_43"/>
                </h3>
                <div className="row">
                  <div className="col-lg-6">
                    <CtrlInput name="website" className={inputClass}
                               placeholder={intl.formatMessage({ id: 'af_44' })} required/>
                  </div>
                  <div className="col-lg-6">
                    <CtrlInput name="visitors_count" className={inputClass}
                               placeholder={intl.formatMessage({ id: 'af_45' })} required/>
                  </div>
                </div>
              </div>
              <div className="affiliate-form__wrap">
                <div className="row">
                  <div className="col-lg-6"/>
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="affiliate-form__captcha">
                          <CtrlCaptcha onChange={onCaptchaChange} refObj={captchaRef}/>
                        </div>
                      </div>
                      <div className="col-lg-5 text-lg-right">
                        <button className="affiliate-form__btn btn-nm btn-octagon btn-reset btn-green">
                          <FormattedMessage id="af_46"/>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </section>
  )
}
