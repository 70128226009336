import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class AvoidFraudContent extends Component {
  render = () => (
    <section className='faq-list'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='faq-list-box octagon octagon-wht'>
              <div className='row'>
                <div className='col-lg-12'>
                  <h3 className='h3 faq-list__title'>
                  <FormattedMessage
                    id="how_page_title"
                    />
                  </h3>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_1"
                    /> </strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_2"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_3"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_4"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_5"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_6"
                    /></li>
                    <li><FormattedMessage
                    id="hp_7"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_8"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_9"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_10"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_11"
                    /> </strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_12"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_13"
                    /></li>
                    <li><FormattedMessage
                    id="hp_14"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_15"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_16"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_17"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_18"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_19"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_20"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_21"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_22"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_23"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_24"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_25"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_26"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_27"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_28"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_29"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_30"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_31"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_32"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_33"
                    /></li>
                    <li><FormattedMessage
                    id="hp_34"
                    /> </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_35"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_36"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_37"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_38"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_39"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_40"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_41"
                    /> </strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_42"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_43"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_44"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_45"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_46"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_47"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_48"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_49"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_50"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_51"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_52"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_53"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_54"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_55"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_56"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_57"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_58"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_59"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_60"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_61"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_62"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_63"
                    /></li>
                    <li><FormattedMessage
                    id="hp_64"
                    /></li>
                    <li><FormattedMessage
                    id="hp_65"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_66"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                    <FormattedMessage
                    id="hp_67"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_68"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_69"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_70"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_71"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_72"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_73"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_74"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_75"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_76"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_77"
                    />
                  </p>
                  <hr />
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_78"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_79"
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_80"
                    values={{ text: <strong>Spot impersonators.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_81"
                    values={{ text: <strong>Online search is the king.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_82"
                    values={{ text: <strong>Caller ID means little.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_83"
                    values={{ text: <strong>Short way to be scammed is to pay beforehand.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_84"
                    values={{ text: <strong>Be attentive to the payment method you use.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_85"
                    values={{ text: <strong>Talking helps.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_86"
                    values={{ text: <strong>Robocall shall not pass.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_87"
                    values={{ text: <strong>Not as free as they sound.</strong> }}
                    />
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_88"
                    values={{ text: <strong>Avoid check depositing as well as wiring money back.</strong> }}
                    />
                  </p>
                  <hr />
                  <p className='faq-arcticle-text'>
                    <strong> <FormattedMessage
                    id="hp_89"
                    /></strong>
                  </p>
                  <p className='faq-arcticle-text'>
                  <FormattedMessage
                    id="hp_90"
                    />
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_91"
                    /></li>
                    <li><FormattedMessage
                    id="hp_92"
                    /></li>
                    <li><FormattedMessage
                    id="hp_93"
                    /></li>
                    <li><FormattedMessage
                    id="hp_94"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'><FormattedMessage
                    id="hp_95"
                    /></p>
                  <ul className='faq-arcticle__list'>
                    <li>
                    <FormattedMessage
                    id="hp_96"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_97"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_98"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_99"
                    /></li>
                    <li><FormattedMessage
                    id="hp_100"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_101"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_102"
                    />
                    </li>
                  </ul>
                  <p className='faq-arcticle-text'><FormattedMessage
                    id="hp_103"
                    /></p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_104"
                    /></li>
                    <li><FormattedMessage
                    id="hp_105"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_106"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_107"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_108"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_109"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_110"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_111"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_112"
                    /></li>
                  </ul>
                  <p className='faq-arcticle-text'>
                    <strong><FormattedMessage
                    id="hp_113"
                    /></strong>
                  </p>
                  <ul className='faq-arcticle__list'>
                    <li><FormattedMessage
                    id="hp_114"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_115"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_116"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_117"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_118"
                    />
                    </li>
                    <li><FormattedMessage
                    id="hp_119"
                    /></li>
                    <li>
                    <FormattedMessage
                    id="hp_120"
                    />
                    </li>
                    <li>
                    <FormattedMessage
                    id="hp_121"
                    />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
