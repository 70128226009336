import React from 'react'
import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

type Props = {
  showSkip: boolean
  setShowSkip: (isShow: boolean) => void
  onConfirm: () => void
}

export const ExchangeStep3Skip = ({ showSkip, setShowSkip, onConfirm }: Props) => {
  const onCancel = () => setShowSkip(false)

  return (
    <>
      <button type="button" className="btn-reset buy-steps__skip" onClick={() => setShowSkip(true)}>
        <FormattedMessage id="pa_82"/>
      </button>
      <Modal onHide={onCancel} show={showSkip} backdrop="static" keyboard={false}
             dialogClassName="verification-notice" centered>
        <Modal.Header closeButton>
          <Modal.Title><FormattedMessage id="pa_83"/></Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p className="modal-text"><FormattedMessage id="pa_85"/></p>
          <div className="modal-btns d-md-flex align-items-center justify-content-center">
            <button className="btn-reset btn-sq btn-sq-br modal-btns__butt" onClick={onCancel}>
              <FormattedMessage id="pa_86"/>
            </button>
            <button className="btn-reset btn-sq btn-blue modal-btns__butt" onClick={onConfirm}>
              <FormattedMessage id="pa_87"/>
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
