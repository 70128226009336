import React, { FunctionComponent, ReactNode } from 'react'
import { NavigationLinks } from 'src/components/common/NavigationLinks'

interface props {
  headerChild?: ReactNode
  children?: any
}

export const BaseShadowOutPage: FunctionComponent<props> = ({ headerChild, children }) => (
  <>
    <div className='page-main shadows'>
      <header className='header header-secondary'>
        <NavigationLinks />
      </header>

      {headerChild}
    </div>

    {children}
  </>
)
