import React, { FC, SelectHTMLAttributes } from 'react'

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  options: any[]
}

const getOptions = (options: any[]) => {
  if (options.length === 0) return
  if (Array.isArray(options)) {
    if (typeof options[0] === 'string') {
      return options.map((opt: string, idx) =>
        <option key={idx} value={opt}>{opt}</option>)
    }
    return options.map((opt: { value: string | number, label: string | number }, idx) =>
      <option key={idx} value={opt.value}>{opt.label}</option>)
  }
  return Object.keys(options).map((key, idx) =>
    <option key={idx} value={key}>{options[key]}</option>)
}

export const CtrlSelect: FC<SelectProps> = ({ options, ...rest }) => {
  return (
    <select {...rest}>
      {getOptions(options)}
    </select>
  )
}
