import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FormProvider } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLoginForm } from '@usher/pe-client-front-end-lib'
import { CtrlInputWrap } from 'src/components/ctrl/InputWrap'

export const LoginForm = () => {
  const intl = useIntl()
  const { formMethods, onSubmit } = useLoginForm({
    history: useHistory(),
    msgConfirmIP: intl.formatMessage({ id: 'ep_24' }),
    msgVerifySent: intl.formatMessage({ id: 'pa_41' }),
    msgUnusualActivity: intl.formatMessage({ id: 'ep_17' })
  })

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <div className='user-form__form user-form-login octagon octagon-wht'>
          <div className='row'>
            <div className='col-lg-6'>
              <CtrlInputWrap name="email" label_id="lf_1" required type="email"/>
            </div>
            <div className='col-lg-6'>
              <CtrlInputWrap name="password" label_id="lf_2" required type="password"/>
            </div>
          </div>
          <div className='row'>
            <div className='offset-lg-6 col-lg-6 d-flex align-items-center justify-content-end'>
              <div className='user-login__form-actions'>
                <Link to='recovery' className='user-login__form-link link-blue'>
                  <FormattedMessage id="lf_5"/>
                </Link>
              </div>
              <div className='buy-steps__btns d-flex justify-content-end user-login__form-btn'>
                <button type='submit' className='btn-reset btn-sq btn-blue buy-steps__butt'>
                  <FormattedMessage id="lf_6"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  )
}
