import { Component } from 'react'
import { InviteSection } from 'src/components/affiliate/InviteSection'
import { OffersSection } from 'src/components/affiliate/OffersSection'

export class AffiliateHeaderBlock extends Component {
  render = () => (
    <>
      <InviteSection />
      <OffersSection />
    </>
  )
}
