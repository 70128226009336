import React, { Component, ReactNode } from 'react'
import { Header } from 'src/components/common/Header'

interface IBasePageProps {
  headerType: string
  headerChild?: ReactNode
  children?: any
}

export class BasePage extends Component<IBasePageProps, {}> {
  render = () => (
    <>
      <Header classType={this.props.headerType}>{this.props.headerChild}</Header>
      {this.props.children}
    </>
  )
}
