import { Component } from 'react'
import { BaseShadowOutPage } from 'src/pages/base/BaseShadowOutPage'
import { ApiSection } from 'src/components/api/ApiSection'
import { ApiContent } from 'src/components/api/ApiContent'

export class ApiPage extends Component {
  render = () => (
    <BaseShadowOutPage headerChild={<ApiSection />}>
      <ApiContent />
    </BaseShadowOutPage>
  )
}
