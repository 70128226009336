import { Component } from 'react'
import { FormattedMessage } from "react-intl"

export class TodaySection extends Component {
  render = () => (
    <section className='today shadows'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <h2 className='h2 today__title text-center'><FormattedMessage
                id="at_4"
              /></h2>
            <p className='today__description text-center'>
            <FormattedMessage
                id="at_5"
              />
            </p>
          </div>
        </div>
        <div className='row flex-wrap'>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon1.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_6"
              /></h4>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon2.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_7"
              /></h4>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon3.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_8"
              /></h4>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon4.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_9"
              /></h4>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon5.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_10"
              /></h4>
            </div>
          </div>
          <div className='col-xl-4 col-lg-6 text-center'>
            <div className='today__card-wrap'>
              <div className='today__icon-wrap'>
                <img src='/img/today-icon6.svg' alt='icon' className='today__card-icon' />
              </div>
              <h4 className='h4 today__card-title'><FormattedMessage
                id="at_11"
              /></h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
